const mutations = {
    UPDATE_VERSION_BLOCKTHROUGH(state, blockthroughOptions) {
        const { key, value } = blockthroughOptions;
        state.version.config.blockthrough[key] = value;
    },
};

const actions = {
    updateVersionBlockthrough({ commit }, blockthroughOptions) {
        commit('UPDATE_VERSION_BLOCKTHROUGH', blockthroughOptions);
    },
};

const getters = {
    blockthrough: ({ version }) => version.config.blockthrough || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_BOUNCEX(state, bounceXOptions) {
        const { key, value } = bounceXOptions;
        state.version.config.bounceX[key] = value;
    },
};

const actions = {
    updateVersionBounceX({ commit }, bounceXOptions) {
        commit('UPDATE_VERSION_BOUNCEX', bounceXOptions);
    },
};

const getters = {
    bounceX: ({ version }) => version.config.bounceX || {},
};

export default {
    mutations,
    actions,
    getters,
};

export default {
    lineItemJobs: ({ lineItemJobs }) => lineItemJobs,
    targetingHistoryJobs: ({ targetingHistoryJobs }) => targetingHistoryJobs,
    jobSites: ({ jobSites }) => jobSites,
    selectedSiteId: ({ selectedSiteId }) => selectedSiteId,
    bidders: ({ bidders }) => bidders,
    arePriceBucketsValid: ({ arePriceBucketsValid }) => arePriceBucketsValid,
    granularities: ({ granularities }) => granularities,
    prebidCreatives: ({ prebidCreatives }) => prebidCreatives,
    config: ({ config }) => config,
    currencies: ({ currencies }) => currencies,
    selectedJob: ({ selectedJob }) => selectedJob,
    version: ({ version }) => version,
    mediaTypes: ({ mediaTypes }) => mediaTypes,
};

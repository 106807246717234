import { fetchNetworks } from '@api/networks';
import { fetchNetworksFilters } from '@api/filters';
import biddersUtil from '@util/bidders';

const state = {
    networks: [],
    sites: [],
    accounts: [],
    customers: [],
};

const mutations = {
    SET_NETWORKS(state, networks) {
        state.networks = networks;
    },
    SET_SITES(state, sites) {
        state.sites = sites;
    },
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
};

const actions = {
    async getNetworks({ commit, rootGetters }, params) {
        try {
            const { queryParams } = rootGetters;
            const { data, metadata } = await fetchNetworks({
                ...queryParams,
                ...params,
            });
            commit('SET_NETWORKS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },
    async getAllNetworks(_, params) {
        const { data } = await fetchNetworks({ ...params });
        return data;
    },
    async getNetworksFilters({ commit }, params) {
        const allFilters = await fetchNetworksFilters({ ...params });
        let {
            domains,
            accounts,
            customers,
        } = allFilters;
        const sites = domains.map(site => ({
            value: site.site_id,
            text: site.domain,
            selected: false,
        }));
        commit('SET_SITES', sites);

        accounts = accounts.map(account => ({
            value: account.account_id,
            text: account.name,
            selected: false,
        }));
        commit('SET_ACCOUNTS', accounts);

        customers = customers.map(customer => ({
            value: customer.customer_id,
            text: customer.name,
            selected: false,
        }));
        commit('SET_CUSTOMERS', customers);
    },
    setSites({ commit }, sites) {
        commit('SET_SITES', sites);
    },
};

const getters = {
    networks: ({ networks }) => networks,
    allBidders: (state, getters, rootState, rootGetters) =>
        [
            ...new Set([
                ...biddersUtil.priorityBidders,
                ...rootGetters.bidders.map(b => b.key),
            ]),
        ].map(bidder => bidder.toLowerCase()),
    sites: ({ sites }) => sites,
    accounts: ({ accounts }) => accounts,
    customers: ({ customers }) => customers,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

/* eslint-disable */

const mutations = {
    UPDATE_VERSION_PAIR_ID(state, options) {
        const { key, value } = options;
        state.version.config.pairId[key] = value;
    },
    UPDATE_VERSION_PAIR_ID_LIVERAMP(state, options) {
        const { key, value } = options;
        state.version.config.pairId.liveRamp[key] = value;
    },
};

const actions = {
    updateVersionPairId({ commit }, options) {
        commit('UPDATE_VERSION_PAIR_ID', options);
    },
    updateVersionPairIdLiveRamp({ commit }, options) {
        commit('UPDATE_VERSION_PAIR_ID_LIVERAMP', options);
    },
};

const getters = {
    pairId: ({ version }) => version.config.pairId || {},
};

export default {
    mutations,
    actions,
    getters,
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { authGuard, notFoundGuard } from './guards';

Vue.use(VueRouter);

const router = new VueRouter(routes);

router.beforeEach(authGuard);
router.beforeEach(notFoundGuard);

/* ---------------------------------------------------- */
/*
  There are cases where we need to navigate to the same route but with updated query params. By default
  the VueRouter throws an error message in the console if we navigate to the same route (oldRoute === newRoute).
  The function below hides the error message on a global scope, or as seen below, returns the same route.
  The other way to avoid these error messages are to catch them in a .catch((err) => {}) block after pushing
  to the same route, where an empty object is returned.

  ex: router.push('your-path').catch(err => {}).
*/
function patchRouterMethod(router, methodName) {
    router['old' + methodName] = router[methodName];
    router[methodName] = async function (location) {
        return router['old' + methodName](location).catch(error => {
            if (error && error.name === 'NavigationDuplicated') {
                return this.currentRoute;
            }
            throw error;
        });
    };
}
patchRouterMethod(router, 'push');
patchRouterMethod(router, 'replace');

/* ---------------------------------------------------- */

export default router;

import { authorizedAxios } from '@config/axios';

export const getCustomers = params =>
    authorizedAxios.get('/api/v1/customers', { params });
export const generateCustomerId = () =>
    authorizedAxios.get('/api/v1/customers/generate-id');
export const getCustomerById = customerId =>
    authorizedAxios.get(`/api/v1/customers/${customerId}`);
export const createCustomer = body =>
    authorizedAxios.post('/api/v1/customers', body);
export const updateCustomer = (customerId, body) =>
    authorizedAxios.put(`/api/v1/customers/${customerId}`, body);
export const deactivateCustomer = customerId =>
    authorizedAxios.delete(`/api/v1/customers/${customerId}`);
export const reactivateCustomer = customerId =>
    authorizedAxios.put(`/api/v1/customers/${customerId}/reactivate`);

import { getUserById, updateUser } from '@api/users';

const state = {
    user: {},
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
};

const actions = {
    async getUserById({ commit }, userId) {
        try {
            let { user } = await getUserById(userId);
            user.currentPassword = '';
            user.password = '';
            user.passwordRepeat = '';
            commit('SET_USER', user);
        } catch (e) {
            console.log('error ', e);
        }
    },
    async updateUser({ dispatch }, user) {
        try {
            await updateUser(user.user_id, user);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
};

const getters = {
    user: ({ user }) => user,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

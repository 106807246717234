export default {
    /* OLD CODE - WILL BE DELETED WHEN ALL RELATED COMPONENTS ARE REFACTORED */
    currentUserRole: 'unknown',
    userId: null,
    userName: '',
    userAccounts: [],
    selectedAccount: null,
    notFound: false,
    error: '',
    /* END OLD CODE */
    isRefreshingToken: false,
    failedReqQueue: [],
    searchValue: '',
    searchExpanded: false,
    pagination: {
        currentPage: 1,
        pageSize: 25,
        pages: 0,
        totalItems: 0,
    },
    sort: {
        order: '',
        orderBy: '',
    },
    bidders: [],
    showLoadingSpinner: false,
    filter: {},
};

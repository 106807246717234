import regex from '@util/regex';
import {
    isValidJavascript,
} from '@util/validation/utils';

/**
 * Checks for consent management errors.
 *
 * @param {Object} consentManagement - The consent management object to validate.
 * @returns {boolean} - Returns true if the consent management has warnings, false otherwise.
 */
export function hasConsentManagementConfigurationWarnings(consentManagement) {
    const invalidGoogleFundingChoicesConfiguration =
        consentManagement.type === 'googleFundingChoices' &&
        (consentManagement.settings.publisherId === '' ||
            consentManagement.settings.domainId === '' ||
            consentManagement.options.popupCode === '' ||
            consentManagement.options.buttonCode === '');
    const invalidCustomConfiguration =
        consentManagement.type === 'custom' &&
        (consentManagement.options.name === '' ||
            consentManagement.options.popupCode === '' ||
            consentManagement.options.buttonCode === '');
    const invalidExternalConfiguration =
        consentManagement.type === 'external' &&
        consentManagement.options.name === '';

    return (
        !consentManagement.type ||
        invalidGoogleFundingChoicesConfiguration ||
        invalidCustomConfiguration ||
        invalidExternalConfiguration
    );
}

/**
 * Checks if the Consent Manager configuration is valid.
 *
 * @param {Object} consentManagement - The Consent Manager configuration to validate.
 * @param {string} consentManagement.type - The Consent Manager type.
 * @param {Object} consentManagement.settings - The Consent Manager settings.
 * @param {Object} consentManagement.options - The Consent Manager options.
 * @returns {boolean} Returns true if the configuration is valid, false otherwise.
 */
export function isConsentManagerValid(consentManagement) {
    if (consentManagement.type === 'googleFundingChoices') {
        const { publisherId, domainId } = consentManagement.settings;
        const buttonCodeValid = isValidJavascript(consentManagement.options.buttonCode);
        const invalidGoogleFunding = !regex.alpha_num.test(publisherId) ||
            !regex.alpha_num.test(domainId) ||
            !buttonCodeValid;
        return !invalidGoogleFunding;
    } else if (consentManagement.type === 'custom') {
        const popupCodeValid = isValidJavascript(consentManagement.options.popupCode);
        const buttonCodeValid = isValidJavascript(consentManagement.options.buttonCode);
        const invalidCustom = !popupCodeValid || !buttonCodeValid;
        return !invalidCustom;
    }
    return true;
}

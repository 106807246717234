import regex from '@util/regex';
import store from '../store/index';
import options from '@config/siteVersion/setupOptions.js';
import semver from 'semver';
import {
    hasPrebidGroupsWarnings,
    hasPrebidConfigWarnings,
    hasPrebidCurrencyWarnings,
    validatePrebidBidAdjustments,
    hasPrebidFirstLookWarnings,
    checkIdentityVersions,
    hasPrebidConfigurationWarnings,
    hasBucketTotalLineItemsWarnings,
    isPrebidBidAdjustmentValid,
    isPrebidValid,
    isPrebidServerValid,
    isPrebidCurrencyValid,
    isPrebidFloorsValid,
    isPrebidFirstLookValid,
    isTrafficShapingValid,
    isBidderThrottlingValid,
    isUserSyncValid
} from './validation/prebid';
import {
    hasDevicesViewportsWarnings,
    hasClassesAndTemplatesWarnings,
    hasSlotWarnings,
    hasSlotNativeStyleWarnings,
    hasNativeStyleWarnings,
    hasNativeStylesWarnings,
    hasLayoutRefreshCounterValueWarnings,
    isSlotInvalid,
    isClassesAndTemplatesValid,
    isDevicesAndViewportsValid,
    isNativeStylesInvalid,
    isLayoutRefreshCounterValueInvalid,
    isInvalidLayout,
    hasInvalidSizeMappings,
} from './validation/adConfiguration';
import {
    hasSchainWarnings,
    hasSchainsWarnings,
    validateAccountOwnership,
    areAuthorizedDomainsValid,
    isSchainsInvalid,
    isSchainValid,
    isSchainNameDuplicate
} from './validation/accountInformation';
import {
    validateAdLoadingOverride,
    hasAdLoadingOverrideWarnings,
    isCustomJsValid,
    isCustomCssValid,
    isAdLoadingOverrideValid,
    arePassbacksValid,
} from './validation/techConfig';
import {
    checkSetupOptionRequired,
    isDomainValid
} from './validation/utils';
import {
} from '@util/validation/monitoring';
import {
} from '@util/validation/identity';
import {
    hasConsentManagementConfigurationWarnings,
    isConsentManagerValid
} from '@util/validation/consentManagement';
import {
    isAmazonValid
} from '@util/validation/amazon';
import {
    isAbTestingValid
} from '@util/validation/abTesting';
import {
    integrationModules
} from '@components/sites/vendors/moduleRegistry';

let validators = [];

export function addVersionValidator(func) {
    if (typeof validators === 'undefined') {
        validators = [];
    }
    validators.push(func);
}

export function validateSlots(version) {
    const warnings = [];
    for (let i = 0; i < version.config.layouts.layouts[0].slots.length; i++) {
        const layoutRefreshCounterValueWarnings = hasLayoutRefreshCounterValueWarnings(version.config.layouts.layouts[0])
        const hasWarnings = hasSlotWarnings(
            version.config.layouts.layouts[0].slots[i],
            version.config.layouts.layouts[0],
            version.config
        );
        const classesAndTemplatesWarnings = hasClassesAndTemplatesWarnings(
            [version.config.layouts.layouts[0]],
            version.config
        );
        const slotNativeStyleWarnings = hasSlotNativeStyleWarnings(
            version.config.layouts.layouts[0].slots[i],
            version.config
        );
        const {
            hasInvalidSizeMappings: invalidSizeMappings,
            hasWarningSizeMappings: warningSizeMappings
        } = hasInvalidSizeMappings(
            version.config.layouts.layouts[0].slots[i],
            version.config.options.imperativeApi
        );

        warnings.push(hasWarnings);
        warnings.push(slotNativeStyleWarnings);
        warnings.push(layoutRefreshCounterValueWarnings);
        warnings.push(classesAndTemplatesWarnings);
        warnings.push(invalidSizeMappings);
        warnings.push(warningSizeMappings);
    }

    return warnings.includes(true);
}

export function validateLayout(config) {
    const layouts = config.layouts.layouts;

    let layoutInvalid = false;

    for (const layout of layouts) {
        const invalidLayout = isInvalidLayout(layout, false);

        if (invalidLayout) {
            layoutInvalid = true;
        }
    }

    return layoutInvalid;
}

/**
 * Checks if a version's configuration is valid.
 *
 * @param {Object} version - The version object to validate.
 * @param {Object} version.config - The version's configuration object.
 */
export function validateVersionWarnings(version) {
    const showClientSettingsWarnings =
        version.config.dfpNetworkId === '' ||
        version.config.htlbidGlobal === '' ||
        version.config.devClientBranch === '';
    if (showClientSettingsWarnings) {
        store.dispatch('sites/addWarningSubtab', 'client-settings');
    }

    const hasPassbacks = version.config.options.hasPassbacks;
    const hasInvalidPassbacks =
        version.config.passbacks.length > 0 &&
        version.config.passbacks.filter(
            passback => passback.key === '' || passback.callback === ''
        ).length > 0;
    if (hasPassbacks && hasInvalidPassbacks) {
        store.dispatch('sites/addWarningSubtab', 'passbacks');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'passbacks');
    }

    const isABTesting =
        version.config.options.isTestMode && version.is_abtest_parent;
    const hasInvalidTestConfigurations =
        version.config.abTesting.testVersions.filter(
            testVersion =>
                !testVersion.versionId ||
                testVersion.versionId === '' ||
                testVersion.allocation === ''
        ).length > 0;
    const hasLessThanTwo = version.config.abTesting.testVersions.length < 2;
    if (isABTesting && (hasInvalidTestConfigurations || hasLessThanTwo)) {
        store.dispatch('sites/addWarningSubtab', 'ab-testing');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'ab-testing');
    }

    const hasConsentManagement = version.config.options.hasConsentManagerVersion2;
    const hasInvalidConsentManagementConfiguration =
        hasConsentManagementConfigurationWarnings(
            version.config.consentManagerVersion2
        );
    if (hasConsentManagement && hasInvalidConsentManagementConfiguration) {
        store.dispatch('sites/addWarningSubtab', 'consent-management');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'consent-management');
    }

    // validate all slots
    if (version.config.options.hasLayouts) {
        const invalidSlots = validateSlots(version);
        const invalidLayout = validateLayout(version.config);

        if (invalidSlots || invalidLayout) {
            store.dispatch('sites/addWarningSubtab', 'classes-and-templates');
        } else {
            store.dispatch('sites/removeWarningSubtab', 'classes-and-templates');
            store.dispatch('sites/removeErrorSubtab', 'classes-and-templates');
        }
    }

    const hasClassesAndTemplates = version.config.options.hasLayouts;
    if (hasClassesAndTemplates) {
        const hasDevicesWarnings = hasDevicesViewportsWarnings(
            version.config.devices
        );
        if (hasDevicesWarnings) {
            store.dispatch('sites/addWarningSubtab', 'devices-and-viewports');
        } else {
            store.dispatch('sites/removeWarningSubtab', 'devices-and-viewports');
        }
    } else {
        store.dispatch('sites/removeWarningSubtab', 'devices-and-viewports');
    }

    const hasNativeStyles = version.config.options.hasNativeStyles;
    const hasNativeWarnings = hasNativeStylesWarnings(version.config);
    if (hasNativeStyles && hasNativeWarnings) {
        store.dispatch('sites/addWarningSubtab', 'native-styles');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'native-styles');
    }

    const hasAmazonSchain = version.config.options.hasAmazonSchain;
    const hasPrebidSchain = version.config.options.hasPrebidSchain
    const hasAmazonWarnings = hasSchainsWarnings(version.config);
    if ((hasAmazonSchain || hasPrebidSchain) && hasAmazonWarnings) {
        store.dispatch('sites/addWarningSubtab', 's-chain');
    } else {
        store.dispatch('sites/removeWarningSubtab', 's-chain');
    }

    if (version.config.options.hasAdLoadingOverride) {
        const hasAdLoadingWarnings = hasAdLoadingOverrideWarnings(
            version.config.adLoadingOverride
        );
        if (hasAdLoadingWarnings) {
            store.dispatch('sites/addWarningSubtab', 'ad-loading-override');
        }
    } else {
        store.dispatch('sites/removeWarningSubtab', 'ad-loading-override');
    }

    const hasDevSiteURLOverride = version.config.options.hasDevSiteURLOverride;
    const empptyDevSiteUrlOverride = !version.config.productionDomain.length || !version.config.domainToOverride.length;
    const invalidDevSiteUrlOverride = !isDomainValid(version.config.productionDomain) || !isDomainValid(version.config.domainToOverride);
    if (hasDevSiteURLOverride && (invalidDevSiteUrlOverride || empptyDevSiteUrlOverride)) {
        store.dispatch('sites/addWarningSubtab', 'dev-site-url-override');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'dev-site-url-override');
    }

    // fledge for gpt
    const hasFledgeForGpt = version.config.options.hasFledgeForGpt;
    const invalidFledgeForGptConfiguration = !version.config.fledgeForGpt.bidders || version.config.fledgeForGpt.bidders?.length === 0;
    if (hasFledgeForGpt && invalidFledgeForGptConfiguration) {
        store.dispatch('sites/addWarningSubtab', 'fledgeForGpt');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'fledgeForGpt');
        store.dispatch('sites/removeErrorSubtab', 'fledgeForGpt');
    }

    for (const { key, required, warningTabKey } of Object.values(options).flat()) {
        if (required && version.config.options[key] && !checkSetupOptionRequired(version.config, required)) {
            store.dispatch('sites/addWarningSubtab', warningTabKey);
        }
    }

    // Prebid Configuration - required fields
    const hasPrebid = version.config.options.hasPrebid;
    const prebidWarnings = hasPrebidConfigurationWarnings(version.config);
    const hasPrebidWarnings = Object.values(prebidWarnings).some(warning => warning);

    if (hasPrebid && hasPrebidWarnings) {
        store.dispatch('sites/addWarningSubtab', 'prebid-configuration');
        if (prebidWarnings.hasCurrencyWarnings || prebidWarnings.hasInvalidConfiguration) {
            store.dispatch('sites/addPrebidConfigTabWithWarnings', 'PrebidConfig');
        }
        if (prebidWarnings.hasInvalidGroups) {
            store.dispatch('sites/addPrebidConfigTabWithWarnings', 'PrebidIDGroups');
        }
        if (prebidWarnings.hasFirstLookWarnings) {
            store.dispatch('sites/addPrebidConfigTabWithWarnings', 'PrebidFirstLook');
        }
    } else {
        store.dispatch('sites/removeWarningSubtab', 'prebid-configuration');
        store.dispatch('sites/removePrebidConfigTabWithWarnings', 'PrebidConfig');
        store.dispatch('sites/removePrebidConfigTabWithWarnings', 'PrebidIDGroups');
        store.dispatch('sites/removePrebidConfigTabWithWarnings', 'PrebidFirstLook');
    }

    const moduleData = [];
    for (const module of integrationModules) {
        moduleData.push(...module.submodules);
    }

    for (const { key, required } of Object.values(moduleData).flat()) {
        const enabled = version.config[key].enabled;
        if (!enabled) {
            continue;
        }
        if (required && !checkSetupOptionRequired(version.config, required)) {
            store.dispatch('sites/addWarningSubtab', 'my-integrations');
            store.dispatch('sites/addWarningSubtab', 'all-integrations');
            store.dispatch('sites/addErrorVendor', key);
        }
    }
}

/**
 * Checks if there are any warnings for the version, sets the warnings in the store if any.
 *
 * @param {Object} version - The version object to validate.
 * @param {Object} version.config - The version's configuration object.
 */
export function validateVersionErrors(version, type = 'error') {
    // run validator for each vendor module
    validators.forEach(func => func(version, type));

    isMinClientVersionMet(version.config, type);

    //OPTIONS
    //client-settings
    const invalidClientSettings =
        (version.config.dfpNetworkId &&
            !regex.two_numbers_separated_by_comma.test(
                version.config.dfpNetworkId
            )) ||
        (version.config.gamUserEmail &&
            !regex.email.test(version.config.gamUserEmail)) ||
        (version.config.htlbidGlobal &&
            !regex.lower_case_only.test(version.config.htlbidGlobal)) ||
        (version.config.devClientBranch &&
            version.config.devClientBranch !== 'master' && !version.config.devClientBranch.toLowerCase().startsWith('zd') &&
            !semver.valid(semver.coerce(version.config.devClientBranch)));

    if (invalidClientSettings) {
        store.dispatch('sites/addErrorSubtab', 'client-settings');
    }

    //custom-js
    if (!isCustomJsValid(version.config.customJs)) {
        store.dispatch('sites/addErrorSubtab', 'custom-js');
    }

    //custom-css
    if (!isCustomCssValid(version.config.css)) {
        store.dispatch('sites/addErrorSubtab', 'custom-css');
    }

    if (
        !isAdLoadingOverrideValid(
            version.config.adLoadingOverride
        )
    ) {
        store.dispatch('sites/addErrorSubtab', 'ad-loading-override');
    }
    //passbacks
    const hasPassbacks = version.config.passbacks.length > 0;
    const hasInvalidPassbackConfiguration = !arePassbacksValid(version.config.passbacks);
    if (hasPassbacks && hasInvalidPassbackConfiguration) {
        store.dispatch('sites/addErrorSubtab', 'passbacks');
    }

    //ACCOUNT INFORMATION
    if (isSchainsInvalid(version.config)) {
        store.dispatch('sites/addErrorSubtab', 's-chain');
    }
    if (
        !areAuthorizedDomainsValid(
            version.config.authorizedDomains
        )
    ) {
        store.dispatch('sites/addErrorSubtab', 'authorized-domains');
    }

    if (version.config.options.hasDevSiteURLOverride && !isDomainValid(version.config.productionDomain)) {
        store.dispatch('sites/addErrorSubtab', 'dev-site-url-override');
    } else {
        store.dispatch('sites/removeErrorSubtab', 'dev-site-url-override');
    }

    if (version.config.options.hasDevSiteURLOverride && !isDomainValid(version.config.domainToOverride)) {
        store.dispatch('sites/addErrorSubtab', 'dev-site-url-override');
    } else {
        store.dispatch('sites/removeErrorSubtab', 'dev-site-url-override');
    }

    //HEADER BIDDING
    //amazon-configuration
    const invalidAmazonConfiguration = !isAmazonValid(
        version.config.amazon
    );
    if (invalidAmazonConfiguration) {
        store.dispatch('sites/addErrorSubtab', 'amazon-configuration');
    }

    //prebid-configuration
    let hasInvalidPrebidConfiguration =
        !isPrebidValid(version.config) ||
        !isPrebidCurrencyValid(version.config) ||
        !isPrebidBidAdjustmentValid(version.config) ||
        !isPrebidFloorsValid(version.config.prebidFloors) ||
        !isPrebidFirstLookValid(version.config);
    if (hasInvalidPrebidConfiguration) {
        store.dispatch('sites/addErrorSubtab', 'prebid-configuration');
    }

    let hasInvalidPrebidServer = !isPrebidServerValid(
        version.config
    );
    if (hasInvalidPrebidServer) {
        store.dispatch('sites/addErrorSubtab', 'prebid-server');
    }

    const invalidBidderThrottling = !isBidderThrottlingValid(version.config.bidderThrottling);
    if (invalidBidderThrottling) {
        store.dispatch('sites/addErrorSubtab', 'bidder-throttling');
    }

    const invalidTrafficShaping = !isTrafficShapingValid(version.config);
    if (invalidTrafficShaping) {
        store.dispatch('sites/addErrorSubtab', 'traffic-shaping');
    }

    //CONSENT MGMT
    const hasConsentManagementErrors =
        !isConsentManagerValid(
            version.config.consentManagerVersion2
        );
    if (hasConsentManagementErrors) {
        store.dispatch('sites/addErrorSubtab', 'consent-management');
    }

    const warningSubtabs = store.getters['sites/warningSubtabs'];
    if (warningSubtabs.includes('classes-and-templates') && type === 'error') {
        store.dispatch('sites/addErrorSubtab', 'classes-and-templates');
    }
    //devices and viewports
    const hasDevicesErrors = !isDevicesAndViewportsValid(
        version.config.devices
    );
    if (hasDevicesErrors) {
        store.dispatch('sites/addErrorSubtab', 'devices-and-viewports');
    }

    // native styles errors
    const hasNativeStylesErrors = isNativeStylesInvalid(
        version.config
    );

    if (hasNativeStylesErrors) {
        store.dispatch('sites/addErrorSubtab', 'native-styles');
    }

    // AB TESTING
    const isABTesting =
        version.config.options.isTestMode && version.is_abtest_parent;
    const hasInvalidTestConfigurations =
        !isAbTestingValid(version.config.abTesting);

    if (isABTesting && hasInvalidTestConfigurations) {
        store.dispatch('sites/addErrorSubtab', 'ab-testing');
    } else {
        store.dispatch('sites/removeErrorSubtab', 'ab-testing');
    }

    // user sync
    if (version.config.options.hasPrebid && !isUserSyncValid(version.config.prebidUserSync)) {
        store.dispatch('sites/addErrorSubtab', 'prebid-configuration');
        store.dispatch('sites/addPrebidConfigTabWithErrors', 'PrebidUserSync');
    } else {
        if (!hasInvalidPrebidConfiguration) {
            store.dispatch('sites/removeErrorSubtab', 'prebid-configuration');
        }
        store.dispatch('sites/removePrebidConfigTabWithErrors', 'PrebidUserSync');
    }
}

/*
    * Parses the version string and returns the parsed version
    * or the highest matching version from the git tags if non-exact version is used
    * @param {string} version - The version string to parse.
    * @returns {string} - The highest matching version from the git tags.
*/
export function parseVersion(version) {
    if (version.includes('.')) {
        version = semver.coerce(version)?.version;
        return version;
    }
    const semverGitTags = store.getters['sites/gitTags'].map(tag => semver.coerce(tag));
    return semver.maxSatisfying(semverGitTags, `>=${version} <${parseInt(version) + 1}`)?.version;
}

/*
    * Checks if the client version is greater than or equal to the minimum client version required by each vendor.
    * @param {Object} config - The version object to validate.
    * @param {string} type - The type of alert to show.
*/
function isMinClientVersionMet(config, type) {
    let clientBranch = config.devClientBranch;
    if (clientBranch === 'master' || clientBranch.toLowerCase().startsWith('zd')) {
        return;
    }
    const clientVersion = parseVersion(clientBranch);
    if (!clientVersion) {
        return;
    }
    const moduleData = [];
    for (const module of integrationModules) {
        moduleData.push(...module.submodules);
    }
    for (const { key, minClientVersion } of Object.values(moduleData).flat()) {
        if (!config[key].enabled) {
            continue;
        }
        if (minClientVersion && !semver.gte(clientVersion, minClientVersion)) {
            if (type === 'warning') {
                store.dispatch('sites/addWarningSubtab', 'my-integrations');
                store.dispatch('sites/addWarningSubtab', 'all-integrations');
            } else {
                store.dispatch('sites/addErrorSubtab', 'my-integrations');
                store.dispatch('sites/addErrorSubtab', 'all-integrations');
            }
            store.dispatch('sites/addErrorVendor', key);
        }
    }
}

/**
 * Checks if there are any warnings or errors before component unmount
 *
 * @param {string} subtab - Name of the subtab to validate
 * @param {Ref<ValidationObserver>} observer - The validation observer
 * @param {boolean} hasComponentWarnings - Whether or not the subtab currently has component warnings, defaults to false
 */
export function validateBeforeDestroy(subtab, observer, hasComponentWarnings = false) {
    const noErrors = checkForErrors(observer);
    observer.validate().then(isValid => {
        if (!isValid && noErrors) {
            store.dispatch('sites/addWarningSubtab', subtab);
        } else if (!isValid && !noErrors) {
            store.dispatch('sites/addErrorSubtab', subtab);
        } else {
            store.dispatch('sites/removeErrorSubtab', subtab);
            if (!hasComponentWarnings) {
                store.dispatch('sites/removeWarningSubtab', subtab);
            }
        }
    });
}

export function validateVendorBeforeDestroy(vendorKey, observer) {
    const noErrors = checkForErrors(observer);
    observer.validate().then(isValid => {
        if (!isValid && noErrors) {
            store.dispatch('sites/addErrorVendor', vendorKey);
        } else {
            store.dispatch('sites/removeErrorVendor', vendorKey);
        }
    });
}

/**
 * Checks for error messages in the validation observer.
 *
 * @param {Ref<ValidationObserver>} observer - The validation observer
 * @returns {boolean} - Returns true if there are no errors, false otherwise.
 */
export function checkForErrors(observer) {
    // This will ignore the _vee_n keys and will flat their values (validation messages) into a final array.
    const notifications = Object.values(observer.errors).flat();

    const noErrors =
        notifications.length > 0 &&
        notifications.every(v => v === 'This field is required.');

    return noErrors;
}

/**
 * Calls the validate method on the validation observer and sets warnings and errors in the store.
 *
 * @param {string} subTab - Name of the subtab to validate
 * @param {Ref<ValidationObserver>} observer - The validation observer
 */
export function validate(subTab, observer) {
    return observer.validateWithInfo().then(validation => {
        const hasErrors =
            Object.values(validation.fields).filter(
                field => field.failed && !field.failedRules.required
            ).length > 0;
        const hasWarnings =
            Object.values(validation.fields).filter(
                field => field.failed && Object.keys(field.failedRules).length > 0
            ).length > 0;
        const { isValid } = validation;

        if (hasWarnings) {
            store.dispatch('sites/addWarningSubtab', subTab);
        } else {
            store.dispatch('sites/removeWarningSubtab', subTab);
        }

        if (isValid) {
            store.dispatch('sites/removeErrorSubtab', subTab);
            store.dispatch('sites/removeWarningSubtab', subTab);
            store.dispatch('sites/clearValidationAlertMessage');
        }

        if (!hasErrors) {
            store.dispatch('sites/removeErrorSubtab', subTab);
            store.dispatch('sites/clearValidationAlertMessage');
        }

        return { isValid, hasWarnings, hasErrors };
    });
}

/**
 * Checks if there are any warnings or errors with integration vendor
 *
 * @param {string} vendorKey - Unique key for the vendor to validate
 * @param {Ref<ValidationObserver>} observer - The validation observer
 */
export function validateVendor(vendorKey, observer) {
    observer.validateWithInfo().then(validation => {
        const hasErrors =
            Object.values(validation.fields).filter(
                field => field.failed && !field.failedRules.required
            ).length > 0;
        const hasWarnings =
            Object.values(validation.fields).filter(
                field => field.failed && Object.keys(field.failedRules).length > 0
            ).length > 0;
        const { isValid } = validation;

        let rules = [];
        Object.values(validation.fields).forEach(
            field => {
                rules = [...rules, ...Object.values(field.failedRules)];
            }
        );
        const cannotBeDisabled = rules.some(rule => rule !== 'This field is required.') && !isValid;

        if (cannotBeDisabled) {
            store.dispatch('sites/addDisVendor', vendorKey);
        } else {
            store.dispatch('sites/removeDisVendor', vendorKey);
        }

        if (hasWarnings || hasErrors) {
            store.dispatch('sites/addErrorVendor', vendorKey);
        } else {
            store.dispatch('sites/removeErrorVendor', vendorKey);
        }

        if (isValid) {
            store.dispatch('sites/removeErrorVendor', vendorKey);
        }

        const errorVendors = store.getters['sites/errorVendors'];
        if (errorVendors.length === 0) {
            store.dispatch('sites/clearValidationAlertMessage');
        }
    });
}

/**
 * Add/remove error subtab from store based on validation result
 *
 * @param {string} subTab - Name of the subtab to validate
 * @param {boolean} hasErrors - Whether or not the subtab currently has errors
 */
export function handleSubtabErrors(subTab, valid) {
    if (valid) {
        store.dispatch('sites/removeErrorSubtab', subTab);
    } else {
        store.dispatch('sites/addErrorSubtab', subTab);
    }
}
/**
 * Check if the vendor owner is one of the valid options.
 *
 * @param {string} owner - The owner to validate.
 */
export function isOwnerValid(owner) {
    return ['publisher', 'marketplace'].includes(owner);
}

export default {
    checkSetupOptionRequired,
    handleSubtabErrors,
    validate,
    validateVendor,
    validateVendorBeforeDestroy,
    validateVersionWarnings,
    validateVersionErrors,
    validateBeforeDestroy,
    hasAdLoadingOverrideWarnings,
    validateAdLoadingOverride,
    hasConsentManagementConfigurationWarnings,
    hasClassesAndTemplatesWarnings,
    hasDevicesViewportsWarnings,
    hasPrebidGroupsWarnings,
    hasPrebidConfigWarnings,
    hasPrebidCurrencyWarnings,
    validatePrebidBidAdjustments,
    hasPrebidFirstLookWarnings,
    checkIdentityVersions,
    hasSlotWarnings,
    validateAccountOwnership,
    hasSlotNativeStyleWarnings,
    hasNativeStyleWarnings,
    hasNativeStylesWarnings,
    hasSchainWarnings,
    hasSchainsWarnings,
    hasLayoutRefreshCounterValueWarnings,
    hasBucketTotalLineItemsWarnings,
    isSlotInvalid,
    areAuthorizedDomainsValid,
    isLayoutRefreshCounterValueInvalid,
    isClassesAndTemplatesValid,
    isDevicesAndViewportsValid,
    isSchainValid,
    isSchainsInvalid,
    isSchainNameDuplicate,
    isPrebidBidAdjustmentValid,
    isPrebidValid,
    isPrebidServerValid,
    isPrebidCurrencyValid,
    isPrebidFloorsValid,
    isPrebidFirstLookValid,
    isOwnerValid,
    validateSlots,
    validateLayout,
    hasInvalidSizeMappings,
};

import { authorizedAxios } from '@config/axios';

export const login = body => authorizedAxios.post('/api/v1/login', body);

export const logout = () =>
    authorizedAxios.post('/api/v1/login/reject-refresh-token', {
        refreshToken: localStorage.getItem('refresh-token'),
    });

export const refreshToken = () =>
    authorizedAxios.post('/api/v1/login/refresh-token', {
        refreshToken: localStorage.getItem('refresh-token'),
    });

export const getUserInformation = () =>
    authorizedAxios.get('/api/v1/login/user-information');

export const requestResetPassword = body =>
    authorizedAxios.post('/api/v1/reset-password', body);

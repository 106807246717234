const mutations = {
    UPDATE_VERSION_MOAT(state, moatOptions) {
        const { key, value } = moatOptions;
        state.version.config.moat[key] = value;
    },
};

const actions = {
    updateVersionMoat({ commit }, moatOptions) {
        commit('UPDATE_VERSION_MOAT', moatOptions);
    },
};

const getters = {
    moat: ({ version }) => version.config.moat || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_IDENTITY_HUB(state, identityHubOptions) {
        const { key, value } = identityHubOptions;
        state.version.config.identityHub[key] = value;
    },
};

const actions = {
    updateVersionIdentityHub({ commit }, identityHubOptions) {
        commit('UPDATE_VERSION_IDENTITY_HUB', identityHubOptions);
    },
};

const getters = {
    identityHub: ({ version }) => version.config.identityHub || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    getVersion,
    updateVersion,
    updateVersionNote,
    getVersions,
    getVersionsCount,
    updatePriority,
} from '@api/versions';

const state = {
    builds: [],
    build: {},
    buildsCount: {
        running: 0,
        success: 0,
        failed: 0,
        pending: 0,
        reviewing: 0,
        bad: 0,
        highPriority: 0,
    },
};

const mutations = {
    SET_BUILDS(state, builds) {
        state.builds = builds;
    },
    SET_BUILD(state, build) {
        state.build = build;
    },

    SET_BUILD_PRIORITY(state, { versionId, priority }) {
        state.builds = state.builds.map((build) => {
            if (build.version_id === versionId) {
                return {
                    ...build,
                    priority,
                };
            }
            return build;
        });
    },
    SET_BUILDS_COUNT(state, count) {
        state.buildsCount = count;
    },
};

const actions = {
    async getBuildByVersionId({ commit }, { versionId }) {
        try {
            const { site, version, meta } = await getVersion(versionId);
            commit('SET_BUILD', { site, version, meta });
        } catch (e) {
            console.log('e', e);
            throw e;
        }
    },

    async updateVersion(_, { versionId, body }) {
        try {
            await updateVersion(versionId, body);
        } catch (e) {
            console.log('e', e);
        }
    },

    async updateVersionNote(_, { versionId, note, status }) {
        try {
            await updateVersionNote(versionId, note, status);
        } catch (e) {
            console.log('e', e);
        }
    },

    async getBuilds({ commit, rootGetters }, params) {
        try {
            const { data, metadata } = await getVersions({
                ...rootGetters.queryParams,
                ...params,
            });
            commit('SET_BUILDS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async getUnpaginatedBuilds(_, params) {
        try {
            const { data } = await getVersions(params);
            return data;
        } catch (e) {
            console.log(e);
        }
    },

    async updateBuildPriority(_, { versionId, priority }) {
        try {
            await updatePriority(versionId, { priority });
        } catch (e) {
            console.log('e', e);
        }
    },

    async getBuildsCount({ commit }, params) {
        try {
            const data = await getVersionsCount(params);
            commit('SET_BUILDS_COUNT', data);
        } catch (e) {
            console.log(e);
        }
    },
};

const getters = {
    builds: ({ builds }) => builds,
    build: ({ build }) => build,
    buildsCount: ({ buildsCount }) => buildsCount,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

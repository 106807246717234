import { fetchServices, fetchServiceLabelMapping } from '@api/adTechServices';
import { fetchNetworksFilters } from '@api/filters';

const state = {
    services: [],
    sites: [],
    accounts: [],
    customers: [],
    allServices: [],
    serviceLabelMapping: {},
};

const mutations = {
    SET_SERVICES(state, services) {
        state.services = services;
    },
    SET_SITES(state, sites) {
        state.sites = sites;
    },
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
    },
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    SET_ALL_SERVICES(state, allServices) {
        state.allServices = allServices.sort((a, b) => a.localeCompare(b));
    },
    SET_SERVICE_LABEL_MAPPING(state, serviceLabelMapping) {
        state.serviceLabelMapping = serviceLabelMapping;
    },
};

const actions = {
    async getServices({ commit, rootGetters }, params) {
        try {
            const { queryParams } = rootGetters;
            const { data, metadata } = await fetchServices({
                ...queryParams,
                ...params,
            });
            commit('SET_SERVICES', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },
    async setAllServices({ commit }, services) {
        commit('SET_ALL_SERVICES', services);
    },
    async getAllServices(_, params) {
        const { data } = await fetchServices({ ...params });
        return data;
    },
    async getServiceLabelMapping({ commit }) {
        const labelMapping = await fetchServiceLabelMapping();
        commit('SET_SERVICE_LABEL_MAPPING', labelMapping);
    },
    async getServiceFilters({ commit }, params) {
        const allFilters = await fetchNetworksFilters({ ...params });
        let {
            domains,
            accounts,
            customers,
        } = allFilters;
        const sites = domains.map(site => ({
            value: site.site_id,
            text: site.domain,
            selected: false,
        }));
        commit('SET_SITES', sites);

        accounts = accounts.map(account => ({
            value: account.account_id,
            text: account.name,
            selected: false,
        }));
        commit('SET_ACCOUNTS', accounts);

        customers = customers.map(customer => ({
            value: customer.customer_id,
            text: customer.name,
            selected: false,
        }));
        commit('SET_CUSTOMERS',customers);
    },
    setSites({ commit }, sites) {
        commit('SET_SITES', sites);
    },
};

const getters = {
    services: ({ services }) => services,
    sites: ({ sites }) => sites,
    accounts: ({ accounts }) => accounts,
    customers: ({ customers }) => customers,
    allServices: ({ allServices }) => allServices.sort((a, b) => a.localeCompare(b)),
    serviceLabelMapping: ({ serviceLabelMapping }) => serviceLabelMapping,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

import {
    getCustomers,
    generateCustomerId,
    getCustomerById,
    createCustomer,
    updateCustomer,
    deactivateCustomer,
    reactivateCustomer,
} from '@api/customers';
import {
    deleteAccountById,
    reactivateAccountById,
    moveAccountById,
} from '@api/accounts';

import helpers from '@util/helpers';

const state = {
    customers: [],
    customer: {},
};

const mutations = {
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    SET_CUSTOMER(state, customer) {
        state.customer = customer;
    },
};

const actions = {
    async getCustomers({ commit, rootGetters }, params) {
        try {
            let { data, metadata } = await getCustomers({
                ...rootGetters.queryParams,
                ...params,
            });
            data = data.map(customer => {
                customer.active =
          customer.deleted_at === null || customer.deleted_at === '';
                return customer;
            });
            commit('SET_CUSTOMERS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },
    async generateCustomerId() {
        try {
            const customerBillingId = await generateCustomerId();
            return customerBillingId;
        } catch (e) {
            console.log(e);
        }
    },
    async getCustomerById({ commit }, customerId) {
        try {
            const { customer } = await getCustomerById(customerId);
            let copyAddress = helpers.deepCopy(customer.address);

            copyAddress = copyAddress
                ? {
                    address: copyAddress.address || '',
                    unit: copyAddress.unit || '',
                    city: copyAddress.city || '',
                    state: copyAddress.state || '',
                    country: copyAddress.country || '',
                    zip_code: copyAddress.zip_code || '',
                }
                : {
                    address: '',
                    unit: '',
                    city: '',
                    state: '',
                    country: '',
                    zip_code: '',
                };

            customer.address = copyAddress;

            if (customer.accounts) {
                customer.accounts = customer.accounts.map(account => {
                    account.active =
            account.deleted_at === null || account.deleted_at === '';
                    return account;
                });
            }

            commit('SET_CUSTOMER', customer);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async createCustomer({ commit }, customer) {
        const params = {
            name: customer.name,
            customer_billing_id: customer.customer_billing_id,
        };

        try {
            const customer = await createCustomer(params);
            commit('SET_CUSTOMER', customer);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async updateCustomer({ dispatch }, customer) {
        try {
            await updateCustomer(customer.customer_id, customer);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async deactivateCustomer({ dispatch }, customerId) {
        try {
            await deactivateCustomer(customerId);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: 'Customer deactivated.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async reactivateCustomer({ dispatch }, customerId) {
        try {
            await reactivateCustomer(customerId);
            dispatch(
                'setAlertMessage',
                { message: 'Customer reactivated.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async getAllActiveCustomers({ commit }) {
        try {
            let { data } = await getCustomers({ activeOnly: true });
            commit('SET_CUSTOMERS', data);
        } catch (e) {
            console.log(e);
        }
    },
    async moveCustomerAccount({ dispatch }, accountOptions) {
        try {
            const { accountId, customerId } = accountOptions;
            await moveAccountById(accountId, customerId);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async deactivateCustomerAccount({ dispatch }, accountOptions) {
        try {
            const { accountId } = accountOptions;
            await deleteAccountById(accountId);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async reactivateCustomerAccount({ dispatch }, accountOptions) {
        try {
            const { accountId } = accountOptions;
            await reactivateAccountById(accountId);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
};

const getters = {
    customers: ({ customers }) => customers,
    customer: ({ customer }) => customer,
    customerNameById: ({ customers }) => {
        return customerId => {
            const customer = customers.find(customer => customer.customer_id === customerId);
            return customer ? customer.name : '-';
        };
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

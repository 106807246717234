import blueConic from './BlueConic/store';
import fledgeForGpt from './FledgeForGpt/store';
import arcSpan from './ArcSpan/store';
import cookieDeprecationMeasurement from './CookieDeprecationMeasurement/store';
import growthCode from './GrowthCode/store';
import bigRichMedia from './BigRichMedia/store';
import britepool from './Britepool/store';
import adLightning from './AdLightning/store';
import confiant from './Confiant/store';
import browsi from './Browsi/store';
import connectId from './ConnectId/store';
import doubleVerify from './DoubleVerify/store';
import fabrickId from './FabrickID/store';
import digitalGarage from './DigitalGarage/store';
import blockthrough from './Blockthrough/store';
import assertiveYield from './AssertiveYield/store';
import facebookPixel from './FacebookPixel/store';
import geoedge from './Geoedge/store';
import grapeshot from './Grapeshot/store';
import bounceX from './BounceX/store';
import proximic from './Proximic/store';
import bonBon from './BonBon/store';
import hadron from './Hadron/store';
import id5 from './ID5/store';
import intentIq from './IntentIQ/store';
import liveRamp from './LiveRamp/store';
import mediaFilter from './MediaFilter/store';
import moat from './Moat/store';
import outbrain from './Outbrain/store';
import miletech from './MileTech/store';
import pairId from './PairId/store';
import parrable from './Parrable/store';
import permutive from './Permutive/store';
import pubxaiAnalytics from './PubxaiAnalytics/store';
import PubX from './PubX/store';
import nobid from './Nobid/store';
import ias from './Ias/store';
import identityHub from './IdentityHub/store';
import liveIntent from './LiveIntent/store';
import lotamePanoramaId from './LotamePanoramaId/store';
import merkle from './Merkle/store';
import optimera from './Optimera/store';
import publinkId from './PublinkId/store';
import pubProvidedId from './PubProvidedId/store';
import quantcast from './Quantcast/store';
import relevad from './Relevad/store';
import roxot from './Roxot/store';
import sharedId from './SharedId/store';
import sovrnAnalytics from './SovrnAnalytics/store';
import staqAnalytics from './StaqAnalytics/store';
import theTradeDesk from './TheTradeDesk/store';
import unifiedId2 from './UID2/store';
import ttAcrossId from './TtAcrossId/store';
import asteriobid from './Asteriobid/store';
import criteo from './Criteo/store';
import adagioAnalytics from './AdagioAnalytics/store';
import idx from './IDx/store';
import zeotapIdPlus from './ZeotapIdPlus/store';
import fantix from './Fantix/store';
import oneCount from './OneCount/store';
import prebidManagerAnalytics from './PrebidManagerAnalytics/store';
import optima from './Optima/store';
import qortex from './Qortex/store';

const mutations = {
    SET_MODULE_OWNER(state, payload) {
        const { key, value } = payload;
        state.version.config[key].owner = value;
    },
    ...blueConic.mutations,
    ...fledgeForGpt.mutations,
    ...growthCode.mutations,
    ...arcSpan.mutations,
    ...cookieDeprecationMeasurement.mutations,
    ...bigRichMedia.mutations,
    ...bounceX.mutations,
    ...britepool.mutations,
    ...adLightning.mutations,
    ...confiant.mutations,
    ...browsi.mutations,
    ...connectId.mutations,
    ...doubleVerify.mutations,
    ...fabrickId.mutations,
    ...digitalGarage.mutations,
    ...blockthrough.mutations,
    ...assertiveYield.mutations,
    ...facebookPixel.mutations,
    ...geoedge.mutations,
    ...grapeshot.mutations,
    ...proximic.mutations,
    ...bonBon.mutations,
    ...hadron.mutations,
    ...id5.mutations,
    ...intentIq.mutations,
    ...liveRamp.mutations,
    ...mediaFilter.mutations,
    ...moat.mutations,
    ...outbrain.mutations,
    ...miletech.mutations,
    ...pairId.mutations,
    ...parrable.mutations,
    ...permutive.mutations,
    ...pubxaiAnalytics.mutations,
    ...PubX.mutations,
    ...nobid.mutations,
    ...ias.mutations,
    ...identityHub.mutations,
    ...liveIntent.mutations,
    ...lotamePanoramaId.mutations,
    ...merkle.mutations,
    ...optimera.mutations,
    ...publinkId.mutations,
    ...pubProvidedId.mutations,
    ...quantcast.mutations,
    ...relevad.mutations,
    ...roxot.mutations,
    ...sharedId.mutations,
    ...sovrnAnalytics.mutations,
    ...staqAnalytics.mutations,
    ...theTradeDesk.mutations,
    ...unifiedId2.mutations,
    ...ttAcrossId.mutations,
    ...asteriobid.mutations,
    ...criteo.mutations,
    ...adagioAnalytics.mutations,
    ...idx.mutations,
    ...zeotapIdPlus.mutations,
    ...fantix.mutations,
    ...prebidManagerAnalytics.mutations,
    ...oneCount.mutations,
    ...optima.mutations,
    ...qortex.mutations,
};

const actions = {
    setModuleOwner({ commit }, payload) {
        commit('SET_MODULE_OWNER', payload);
    },
    ...blueConic.actions,
    ...fledgeForGpt.actions,
    ...growthCode.actions,
    ...arcSpan.actions,
    ...cookieDeprecationMeasurement.actions,
    ...bigRichMedia.actions,
    ...bounceX.actions,
    ...britepool.actions,
    ...adLightning.actions,
    ...confiant.actions,
    ...browsi.actions,
    ...connectId.actions,
    ...doubleVerify.actions,
    ...fabrickId.actions,
    ...digitalGarage.actions,
    ...blockthrough.actions,
    ...assertiveYield.actions,
    ...facebookPixel.actions,
    ...geoedge.actions,
    ...grapeshot.actions,
    ...proximic.actions,
    ...bonBon.actions,
    ...hadron.actions,
    ...id5.actions,
    ...intentIq.actions,
    ...liveRamp.actions,
    ...mediaFilter.actions,
    ...moat.actions,
    ...outbrain.actions,
    ...miletech.actions,
    ...pairId.actions,
    ...parrable.actions,
    ...permutive.actions,
    ...pubxaiAnalytics.actions,
    ...PubX.actions,
    ...nobid.actions,
    ...ias.actions,
    ...identityHub.actions,
    ...liveIntent.actions,
    ...lotamePanoramaId.actions,
    ...merkle.actions,
    ...optimera.actions,
    ...publinkId.actions,
    ...pubProvidedId.actions,
    ...quantcast.actions,
    ...relevad.actions,
    ...roxot.actions,
    ...sharedId.actions,
    ...sovrnAnalytics.actions,
    ...staqAnalytics.actions,
    ...theTradeDesk.actions,
    ...unifiedId2.actions,
    ...ttAcrossId.actions,
    ...asteriobid.actions,
    ...criteo.actions,
    ...adagioAnalytics.actions,
    ...idx.actions,
    ...zeotapIdPlus.actions,
    ...fantix.actions,
    ...prebidManagerAnalytics.actions,
    ...oneCount.actions,
    ...optima.actions,
    ...qortex.actions,
};

const getters = {
    ...blueConic.getters,
    ...fledgeForGpt.getters,
    ...growthCode.getters,
    ...arcSpan.getters,
    ...cookieDeprecationMeasurement.getters,
    ...bigRichMedia.getters,
    ...bounceX.getters,
    ...britepool.getters,
    ...adLightning.getters,
    ...confiant.getters,
    ...browsi.getters,
    ...connectId.getters,
    ...doubleVerify.getters,
    ...fabrickId.getters,
    ...digitalGarage.getters,
    ...blockthrough.getters,
    ...assertiveYield.getters,
    ...facebookPixel.getters,
    ...geoedge.getters,
    ...grapeshot.getters,
    ...proximic.getters,
    ...bonBon.getters,
    ...hadron.getters,
    ...id5.getters,
    ...intentIq.getters,
    ...liveRamp.getters,
    ...mediaFilter.getters,
    ...moat.getters,
    ...outbrain.getters,
    ...miletech.getters,
    ...pairId.getters,
    ...parrable.getters,
    ...permutive.getters,
    ...pubxaiAnalytics.getters,
    ...PubX.getters,
    ...nobid.getters,
    ...ias.getters,
    ...identityHub.getters,
    ...liveIntent.getters,
    ...lotamePanoramaId.getters,
    ...merkle.getters,
    ...optimera.getters,
    ...publinkId.getters,
    ...pubProvidedId.getters,
    ...quantcast.getters,
    ...relevad.getters,
    ...roxot.getters,
    ...sharedId.getters,
    ...sovrnAnalytics.getters,
    ...staqAnalytics.getters,
    ...theTradeDesk.getters,
    ...unifiedId2.getters,
    ...ttAcrossId.getters,
    ...asteriobid.getters,
    ...criteo.getters,
    ...adagioAnalytics.getters,
    ...idx.getters,
    ...zeotapIdPlus.getters,
    ...fantix.getters,
    ...prebidManagerAnalytics.getters,
    ...oneCount.getters,
    ...optima.getters,
    ...qortex.getters,
};

export default {
    mutations,
    actions,
    getters,
    namespaced: true,
};

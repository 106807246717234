import helpers from '@util/helpers';
import uuidv4 from 'uuid/v4';

const defaultGranularity = {
    buckets: [
        { min: 0, max: 3, increment: 0.01 },
        { min: 3, max: 8, increment: 0.05 },
        { min: 8, max: 10, increment: 0.5 },
        { min: 10, max: 50, increment: 1.0 },
    ],
};

const defaultViewportName = 'All_Viewports';

const granularityValues = {
    default: '1:300:1,300:800:5,800:1000:50,1000:5100:100',
    low: '0:550:50',
    medium: '0:2010:10',
    high: '0:2000:1',
    auto: '0:500:5,500:1000:10,1000:2050:50',
    dense: '0:300:1,300:800:5,800:2050:50',
};

const granularityTypes = [
    { value: 'default', text: 'AMS Default' },
    { value: 'low', text: 'Prebid "low" granularity' },
    { value: 'medium', text: 'Prebid "medium" granularity' },
    { value: 'high', text: 'Prebid "high" granularity' },
    { value: 'dense', text: 'Prebid "dense" granularity' },
    { value: 'custom', text: 'Custom' },
];

const supplyChainEligible = [
    '33across',
    'aardvark',
    'adagio',
    'admixer',
    'adtelligent',
    'amx',
    'appnexus',
    'beachfront',
    'colossusssp',
    'conversant',
    'criteo',
    'districtmdmx',
    'emx_digital',
    'grid',
    'gumgum',
    'improvedigital',
    'ix',
    'justpremium',
    'lockerdome',
    'mediafusenxs',
    'medianet',
    'nobid',
    'oftmedia',
    'openx',
    'pubmatic',
    'pulsepoint',
    'rubicon',
    'rubi_mm',
    'rubi_ttd',
    'rhythmone',
    'sharethrough',
    'smaato',
    'smartadserver',
    'sonobi',
    'sovrn',
    'teads',
    'triplelift',
    'undertone',
    'yahoossp',
    'yieldmo',
];

const isSupplyChainEligible = (config, network) => {
    if (!config.options.hasPrebidSchain) {
        return false;
    }

    if (!network.owner || !['Managed', 'HTL'].includes(network.owner)) {
        return false;
    }

    // Todo: get supplyChainEligible networks from bidders.json;
    // it is used in loadVersionNetworks so maybe it first needs to have a
    // getSupplyChainEligibleNetworks.
    // Todo2: Add versionHelpers.spec.js
    return supplyChainEligible.includes(network.network.toLowerCase());
}

const allPublisherPurposes = {
    1: 'Information Storage',
    2: 'Personalization',
    3: 'Ad Selection',
    4: 'Content Selection',
    5: 'Measurement',
};

const defaultSize = {
    size: '300x250',
};

const defaultSizeMapping = {
    viewport: '',
    sizes: [],
    groups: [],
    reservedSizeIndex: null,
    verticalAlign: null,
    unit: null,
    deviceSettingsOverride: false,
    options: {
        eager: false,
        lazyPixels: 350,
        refresh: false,
        refreshSecs: 60,
        maxRefreshes: 500,
        refreshType: 'viewable',
        lockRefreshSize: false,
    }
};

const defaultNativeStyle = {
    name: null,
    titleMaxLength: null,
    descriptionMaxLength: null,
    imgMaxWidth: null,
    imgMaxHeight: null,
    css: null,
    html: null,
};

const defaultSlot = {
    unit: null,
    name: null,
    uuid: uuidv4(),
    sizeMappings: [helpers.deepCopy(defaultSizeMapping)],
    targeting: [],
    note: '',
    where: 'appendChild',
    type: 'selector',
    maxPer: null,
    maxTotal: null,
    density: null,
    selector: '',
    callback: null,
    options: {
        eager: false,
        lazyPixels: '350',
        sticky: false,
        stickyCanClose: null,
        stickyMinutes: '0',
        expandable: false,
        interstitial: false,
        outOfPage: false,
        refresh: false,
        refreshType: 'viewable',
        refreshSecs: '60',
        maxRefreshes: '500',
        lockRefreshSize: false,
        stickyFreqCap: false,
        stickyFreqMinutes: '60',
        companion: false,
    },
};

const defaultSlotTargeting = {
    key: '',
    value: '',
    uuid: null,
    type: 'gam',
};

const defaultLayout = {
    key: null,
    extends: null,
    rules: [],
    slots: [helpers.deepCopy(defaultSlot)],
    poll: {
        type: null,
        interval: 2000,
        selector: null,
        callback: null,
    },
};

const defaultUniversalLayout = {
    key: 'universal',
    extends: null,
    rules: [],
    slots: [helpers.deepCopy(defaultSlot)],
    uuid: uuidv4(),
    poll: {
        type: null,
        interval: 2000,
        selector: null,
        callback: null,
    },
};

// Todo: Get this from the env file?
const defaultPrebidVersion = '7.16.0';

const device = {
    name: '',
    minViewport: '0x0',
};

const defaultDevices = [
    {
        name: 'Small',
        minViewport: '0x0',
    },
    {
        name: 'Large',
        minViewport: '1024x0',
    },
];

const getMappedLayouts = layouts => {
    layouts.forEach(mapLayout => {
        if (!mapLayout.uuid) {
            mapLayout.uuid = uuidv4();
        }
        mapLayout.slots.forEach(slot => {
            if (!slot.componentUuid) {
                slot.componentUuid = uuidv4();
            }
            if (!slot.uuid) {
                slot.uuid = uuidv4();
            }
            slot.sizeMappings.map(sizeMapping => {
                if (sizeMapping.group) {
                    sizeMapping.groups = [];
                    sizeMapping.groups.push(sizeMapping.group);
                    delete sizeMapping.group;
                }
                if (slot.options.outOfPage) {
                    slot.sizeMappings.map(sizeMapping => {
                        sizeMapping.sizes = [];
                    });
                }
                return slot;
            });
        });
    });
    const universalBoolean = layouts.map(l => l.key).includes('universal');
    if (!universalBoolean) {
        layouts.splice(0, 0, helpers.deepCopy(defaultUniversalLayout));
    }
    return layouts;
};

export const mapPrebidGroups = groups => {
    const copiedGroups = helpers.deepCopy(groups);
    const mappedGroups = copiedGroups
        .sort(helpers.dynamicSort('name'))
        .map((group) => ({
            value: group.name,
            text: group.name,
            uuid: group.uuid,
            selected: false,
        }));
    return mappedGroups;
};

const identityHubConflictModules = {
    hasBritepool: 'Britepool',
    criteo: 'Criteo',
    fabrickId: 'Fabrick ID',
    hasFirstParty: 'First Party',
    hasHaloId: 'Halo',
    id5: 'ID5',
    liveRamp: 'Live Ramp',
    liveIntent: 'LiveIntent',
    lotameId: 'Lotame',
    hasNetId: 'Net ID',
    hasNextRoll: 'Next Roll',
    parrable: 'Parrable',
    quantcast: 'Quantcast',
    hasSharedId: 'Shared ID',
    zeotapIdPlus: 'ID+',
};

export const loadVersionNetworks = config => {
    const { networks } = config;

    const bidderArray = [
        {
            network: 'adX',
            owner: 'Publisher',
            schain: false,
        },
    ];

    config.prebid.groups.map(group =>
        group.bidders.map(b => {
      //eslint-disable-line
            bidderArray.push({
                network: b.bidder,
                owner: '',
                schain: supplyChainEligible.includes(b.bidder.toLowerCase()),
            });
        })
    );

    if (config.optimera.enabled) {
        bidderArray.push({ network: 'optimera', owner: '', schain: false });
    }
    if (config.options.hasAmazon) {
        bidderArray.push({ network: 'amazon', owner: '', schain: false });
    }
    if (config.confiant.enabled) {
        bidderArray.push({ network: 'confiant', owner: '', schain: false });
    }
    if (config.roxot.enabled) {
        bidderArray.push({ network: 'roxot', owner: '', schain: false });
    }
    if (config.bounceX.enabled) {
        bidderArray.push({ network: 'bounceX', owner: '', schain: false });
    }

    // if networks already include bidder - remove it from array
    let trimmedArray = bidderArray.reduce(
        (unique, item) =>
            unique.map(u => u.network).includes(item.network)
                ? unique
                : [...unique, item],
        []
    );

    trimmedArray = trimmedArray.filter(e => {
        if (!networks.map(n => n.network).includes(e.network)) {
            return e;
        }
        return null;
    });

    return [...networks, ...trimmedArray].sort(helpers.dynamicSort('network'));
};

export const syncVersionNetworks = config => {
    const { networks } = config;

    let bidderArray = ['adX'];

    config.prebid.groups.map(e =>
        e.bidders.map(j => {
            bidderArray.push(j.bidder);
        })
    );

    bidderArray = bidderArray.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
    );
    let trimmedArray = networks.filter(e => {
        if (
            bidderArray.includes(e.network) ||
      e.network === 'adX' ||
      e.network === 'amazon'

        ) {
            return e;
        }
        return null;
    });

    if (!config.options.hasAmazon) {
        trimmedArray = trimmedArray.filter(e => e.network !== 'amazon');
    }

    return trimmedArray.sort(helpers.dynamicSort('network'));
};

export const loadVersionDevices = config => {
    const { layouts } = config.layouts;

    // every version should have an universal device: 'All_Viewports'
    const universalDevice = helpers.deepCopy(device);
    universalDevice.uuid = uuidv4();
    universalDevice.name = defaultViewportName;
    const versionDevices = [universalDevice];

    // if there are existing layouts we should go through all layout slots -> and create a device for every viewport
    if (layouts && layouts.length > 0) {
        let allViewports = [];
        layouts.forEach(layout => {
            layout.slots.forEach(slot => {
                if (slot.sizeMappings.length > 1) {
                    // if only one sizeMapping is present -> skip it since its the default one 'All_Viewports'
                    slot.sizeMappings.forEach(sizeMapping => {
                        // check for duplicates before adding them to the array
                        if (
                            sizeMapping.viewport &&
              !allViewports.includes(sizeMapping.viewport)
                        )
                            allViewports.push(sizeMapping.viewport);
                    });
                }
            });
        });

        // sort allViewports in asc order
        if (allViewports.length > 0) {
            allViewports = allViewports.map(vp => {
                const width = /(.+)x/.exec(vp)[0];
                return { value: vp, width: parseInt(width, 10) };
            });

            const sortedViewports = allViewports.sort((a, b) => a.width - b.width);
            sortedViewports.forEach(v => {
                // create a device for each viewport
                const device = {
                    uuid: uuidv4(),
                    name: v.value,
                    minViewport: v.value,
                };

                versionDevices.push(device);
            });
        }
    }
    return versionDevices;
};

export const mapVersionDevicesToViewports = config => {
    const { layouts } = config.layouts;

    if (layouts && layouts.length > 0) {
        const versionDevices = config.devices;
        layouts.forEach(layout => {
            layout.slots.forEach(slot => {
                if (slot.sizeMappings.length === 1) {
                    slot.sizeMappings[0].device = versionDevices[0].uuid; // 1 Viewport = 1 Device → All_Viewports (system default Device)
                } else {
                    slot.sizeMappings.forEach(sizeMapping => {
                        const deviceToMap = versionDevices
                            .slice(1)
                            .find(d => d.minViewport === sizeMapping.viewport); // find matching device | exclude default 'All_Viewports'
                        if (deviceToMap) sizeMapping.device = deviceToMap.uuid;
                    });
                }
            });
        });
    }
};

export default {
    defaultGranularity,
    defaultViewportName,
    granularityValues,
    granularityTypes,
    isSupplyChainEligible,
    allPublisherPurposes,
    defaultSize,
    defaultSizeMapping,
    defaultSlot,
    defaultSlotTargeting,
    defaultLayout,
    defaultUniversalLayout,
    getMappedLayouts,
    mapPrebidGroups,
    identityHubConflictModules,
    defaultPrebidVersion,
    loadVersionNetworks,
    syncVersionNetworks,
    device,
    defaultDevices,
    loadVersionDevices,
    mapVersionDevicesToViewports,
    defaultNativeStyle,
};

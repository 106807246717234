import { authorizedAxios } from '@config/axios';

export const fetchUsers = params =>
    authorizedAxios.get('/api/v1/users', { params });
export const getUserById = userId =>
    authorizedAxios.get(`/api/v1/users/${userId}`);
export const createUser = params =>
    authorizedAxios.post('/api/v1/users', params);
export const updateUser = (userId, body) =>
    authorizedAxios.put(`/api/v1/users/${userId}`, body);
export const deleteUser = userId =>
    authorizedAxios.delete(`/api/v1/users/${userId}`);
export const reactivateUser = userId =>
    authorizedAxios.put(`/api/v1/users/reactivate-user/${userId}`);
export const inviteUser = params =>
    authorizedAxios.post('/api/v1/invite-user', params);
export const resendInvite = userId =>
    authorizedAxios.post(`/api/v1/invite-user/${userId}/resend-invite`);
export const activateUser = (userId, password) =>
    authorizedAxios.post(`/api/v1/users/${userId}/activate`, { password });
export const cancelInvite = userId =>
    authorizedAxios.post(`/api/v1/invite-user/${userId}/cancel-invite`);
export const updateUserRole = (userId, role) =>
    authorizedAxios.patch(`/api/v1/users/${userId}/role`, { role });

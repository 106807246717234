const mutations = {
    UPDATE_VERSION_DOUBLE_VERIFY(state, doubleVerifyOptions) {
        const { key, value } = doubleVerifyOptions;
        state.version.config.doubleVerify[key] = value;
    },
};

const actions = {
    updateVersionDoubleVerify({ commit }, doubleVerifyOptions) {
        commit('UPDATE_VERSION_DOUBLE_VERIFY', doubleVerifyOptions);
    },
};

const getters = {
    doubleVerify: ({ version }) => version.config.doubleVerify || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_LIVE_RAMP(state, liveRampOptions) {
        const { key, value } = liveRampOptions;
        state.version.config.liveRamp[key] = value;
    },
};

const actions = {
    updateVersionLiveRamp({ commit }, liveRampOptions) {
        commit('UPDATE_VERSION_LIVE_RAMP', liveRampOptions);
    },
};

const getters = {
    liveRamp: ({ version }) => version.config.liveRamp || {},
};

export default {
    mutations,
    actions,
    getters,
};

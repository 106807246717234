const mutations = {
    UPDATE_VERSION_CRITEO(state, criteoOptions) {
        const { key, value } = criteoOptions;
        state.version.config.criteo[key] = value;
    },
};

const actions = {
    updateVersionCriteo({ commit }, criteoOptions) {
        commit('UPDATE_VERSION_CRITEO', criteoOptions);
    },
};

const getters = {
    criteo: ({ version }) => version.config.criteo || {},
};

export default {
    mutations,
    actions,
    getters,
};

export default {
    SET_SEARCH_VALUE(state, searchValue) {
        state.searchValue = searchValue;
    },
    NOT_FOUND(state, notFound) {
        state.notFound = notFound;
    },
    POST_ERROR: (state, payload) => {
        state.error = payload;
    },
    SET_IS_REFRESHING_TOKEN: (state, payload) => {
        state.isRefreshingToken = payload;
    },
    ADD_FAILED_REQUEST: (state, payload) => {
        state.failedReqQueue.push(payload);
    },
    RESET_FAILED_REQ_QUEUE: state => {
        state.failedReqQueue = [];
    },
    SET_PAGINATION(state, pagination) {
        state.pagination = pagination;
    },
    SET_PAGINATION_CURRENT_PAGE(state, page) {
        state.pagination.currentPage = page;
    },
    SET_PAGINATION_PAGE_SIZE(state, pageSize) {
        state.pagination.pageSize = pageSize;
    },
    SET_SORT_PARAMS(state, sort) {
        state.sort = sort;
    },
    SHOW_LOADING_SPINNER(state, bool) {
        state.showLoadingSpinner = bool;
    },
    SET_FILTER(state, filter) {
        state.filter = filter;
    },
    SET_BIDDERS(state, bidders) {
        state.bidders = bidders;
    },
    TOGGLE_SEARCH_EXPANDED(state) {
        state.searchExpanded = !state.searchExpanded;
    },
    CLOSE_SEARCH_EXPANDED(state) {
        state.searchExpanded = false;
    }
};

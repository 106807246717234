const mutations = {
    UPDATE_VERSION_FACEBOOK_PIXEL(state, facebookPixelOptions) {
        const { key, value } = facebookPixelOptions;
        state.version.config.facebookPixel[key] = value;
    },
};

const actions = {
    updateVersionFacebookPixel({ commit }, facebookPixelOptions) {
        commit('UPDATE_VERSION_FACEBOOK_PIXEL', facebookPixelOptions);
    },
};

const getters = {
    facebookPixel: ({ version }) => version.config.facebookPixel || {},
};

export default {
    mutations,
    actions,
    getters,
};

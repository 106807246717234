const mutations = {
    UPDATE_VERSION_PUBXAI_RTD(state, pubxaiRtdOptions) {
        const { key, value } = pubxaiRtdOptions;
        state.version.config.pubxaiRTD[key] = value;
    },
};

const actions = {
    updateVersionPubxaiRTD({ commit }, pubxaiRTDOptions) {
        commit('UPDATE_VERSION_PUBXAI_RTD', pubxaiRTDOptions);
    },
};

const getters = {
    pubxaiRTD: ({ version }) => version.config.pubxaiRTD || {},
};

export default {
    mutations,
    actions,
    getters,
};

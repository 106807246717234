import { authorizedAxios } from '@config/axios';

/* GET */
export const getVersion = versionId =>
    authorizedAxios.get(`/api/v1/versions/${versionId}`);
export const getVersions = params =>
    authorizedAxios.get('/api/v1/versions', { params });
export const getVersionsCount = params =>
    authorizedAxios.get('/api/v1/versions/build-count', { params });

/* POST */
export const copyVersion = versionId =>
    authorizedAxios.post(`/api/v1/versions/${versionId}/copy`);
export const createVersionDeployment = (versionId, body) =>
    authorizedAxios.post(`/api/v1/versions/${versionId}/deployments`, body);

export const updatePriority = (versionId, body) =>
    authorizedAxios.post(`/api/v1/versions/${versionId}/set-priority`,  body);

/* PATCH */
export const updateVersion = (versionId, body) =>
    authorizedAxios.patch(`/api/v1/versions/${versionId}`, body);

export const updateVersionNote = (versionId, note, status) =>
    authorizedAxios.patch(`/api/v1/versions/${versionId}/note`, { note, status });

const mutations = {
    UPDATE_VERSION_PUBXAI_ANALYTICS(state, pubxaiAnalyticsOptions) {
        const { key, value } = pubxaiAnalyticsOptions;
        state.version.config.pubxaiAnalytics[key] = value;
    },
};

const actions = {
    updateVersionPubxaiAnalytics({ commit }, pubxaiAnalyticsOptions) {
        commit('UPDATE_VERSION_PUBXAI_ANALYTICS', pubxaiAnalyticsOptions);
    },
};

const getters = {
    pubxaiAnalytics: ({ version }) => version.config.pubxaiAnalytics || {},
};

export default {
    mutations,
    actions,
    getters,
};

export default {
    prebidBidders: (state, getters, rootState, rootGetters) =>
        rootGetters.bidders || [],
    prebidConfiguration: ({ version }) => version.config.prebid || {},
    prebidUserSync: ({ version }) => version.config.prebidUserSync || {},
    prebidCurrency: ({ version }) => version.config.prebidCurrency || {},
    prebidIDGroups: ({ version }) => version.config.prebid.groups || [],
    prebidServer: ({ version }) => version.config.prebidServer || {},
    prebidBidAdjustments: ({ version }) =>
        version.config.prebidBidAdjustments || {},
    prebidFirstLook: ({ version }) => version.config.prebidFirstLook || {},
    bidderThrottling: ({ version }) => version.config.bidderThrottling || [],
    trafficShaping: ({ version }) => version.config.trafficShaping || [],
    prebidFloorsString: ({ version }) => {
        const { prebidFloors, prebidFloorsString } = version.config;
        if (prebidFloorsString != null) {
            return prebidFloorsString;
        }

        return prebidFloors ? JSON.stringify(prebidFloors.custom) : '';
    },
    prebidFloors: ({ version }) => {
        return version.config.prebidFloors || {};
    },
};

import regex from '@util/regex';
import helpers from '@util/helpers';
import store from '../../store/index';
import vh from '@util/versionHelpers';
import options from '@config/siteVersion/setupOptions.js';
import { integrationModules } from '@components/sites/vendors/moduleRegistry';

export function isPrebidUserSyncUserIdReportingValid({ enabled, key }) {
    return !enabled || key && key.length < 20 && regex.alpha_num_underscore.test(key);
}

/**
 * Check if the provided bid throttling parameters are valid.
 *
 * @param {Object} options - The options for bid throttling.
 * @param {string} options.failsToPause - The number of failed bids required to pause.
 * @param {string} options.unpauseSeconds - The time in seconds to unpause after pausing.
 * @returns {boolean} True if the parameters are valid, false otherwise.
 */
export function isBidderThrottlingValid( bidderThrottling ) {
    return isBidderThrottlingReportingValid({bidderThrottling})
    && isBidderThrottlingValuesValid({bidderThrottling});
}

/**
 * Check if the provided bid throttling parameters are valid.
 *
 * @param {Object} options - The options for bid throttling.
 * @returns {boolean} True if the parameters are valid, false otherwise.
 */
export function isBidderThrottlingReportingValid({bidderThrottling}) {
    const {gamReportingEnabled, gamTargetingKeyPrefix} = bidderThrottling;
    // if reporting is disabled, skip validation
    if (! gamReportingEnabled) {
        return true;
    }
    // check valid GAM key names
    const keyCharsValid = regex.alpha_num_underscore.test(gamTargetingKeyPrefix);

    // check key do not exceed 20 chars
    const keyLengthValid = gamTargetingKeyPrefix.length <= 20;

    // check all GAM key name criteria met
    const keyValid = keyCharsValid && keyLengthValid;

    return keyValid;
}

/**
 * Check if the provided bid throttling parameters are valid.
 *
 * @param {Object} options - The options for bid throttling.
 * @returns {boolean} True if the parameters are valid, false otherwise.
 */
export function isBidderThrottlingValuesValid({bidderThrottling}) {
    const { failsToPause, unpauseSeconds} = bidderThrottling;
    // consider empty values to be valid, so that we can disable the checkbox
    // backend will ensure no code is included when empty
    if (!failsToPause && !unpauseSeconds) {
        return true;
    }
    const failsOk = regex.numeric.test(failsToPause) && parseInt(failsToPause, 10) > 0;
    const unpauseOk = regex.numeric.test(unpauseSeconds) && parseInt(unpauseSeconds, 10) > 0;
    return failsOk && unpauseOk;
}

/**
 * Check if the provided Prebid bid adjustments are valid.
 *
 * @param {Object} options - The options for Prebid bid adjustments.
 * @param {Array} options.prebidBidAdjustments - An array of bid adjustments to validate.
 * @param {string} options.prebidBidAdjustments[].adjustment - The bid adjustment value.
 * @returns {boolean} True if all bid adjustments are valid, false otherwise.
 */
export function isPrebidBidAdjustmentValid({ prebidBidAdjustments }) {
    return prebidBidAdjustments.every(
        ({ adjustment }) => adjustment && regex.numeric.test(adjustment) && Number(adjustment) > 0
    );
}

/**
 * Check whether a Prebid Floors object is valid
 *
 * @param {object} config
 * @returns {boolean}
 * @see https://docs.prebid.org/dev-docs/modules/currency.html
 */
export function isPrebidCurrencyValid(config) {
    const granularityMultiplierValid = !(
        config.prebidCurrency.granularityMultiplier !== '' &&
    !regex.numeric.test(config.prebidCurrency.granularityMultiplier)
    );
    return granularityMultiplierValid;
}

/**
 * Check whether a Prebid First Look config is valid
 *
 * @param {object} config
 * @returns {boolean}
 */
export function isPrebidFirstLookValid(config) {
    return !(
        config.prebidFirstLook.globalFloor &&
        config.prebidFirstLook.globalFloor !== '' &&
        (!regex.numeric_or_double.test(config.prebidFirstLook.globalFloor) ||
        parseFloat(config.prebidFirstLook.globalFloor) < 0)
    );
}

/**
 * Check whether a Prebid Floors object is valid
 *
 * @param {object} prebidFloors
 * @returns {boolean}
 * @see https://docs.prebid.org/dev-docs/modules/floors.html
 */
export function isPrebidFloorsValid(prebidFloors) {
    if (!prebidFloors) {
        return true;
    }

    const {rules, skipRate, floorReporting} = prebidFloors;

    if (skipRate?.enabled) {
        const {value, keyName} = skipRate;
        const hasValidSkipRate = regex.numeric.test(value) && value >= 0 && value <= 100;
        const hasValidkeyName = keyName && regex.single_value.test(keyName);

        if (!hasValidSkipRate || !hasValidkeyName) {
            return false;
        }
    }

    if (floorReporting?.enabled) {
        const {keyName} = floorReporting;
        const hasValidkeyName = keyName && regex.single_value.test(keyName);

        if (!hasValidkeyName) {
            return false;
        }
    }

    // Now, check the rules
    // Allow enabling the module, even with zero rules
    // This allows bundling the prebid module into the build, even
    // if the floors are set by an external vendor at runtime
    if (!rules || rules.length === 0) {
        return true;
    }

    const hasDuplicateRows = hasPrebidFloorsDuplicateRows(prebidFloors);
    const hasDuplicateCols = hasPrebidFloorsDuplicateCols(prebidFloors);
    const noMissingValues = prebidFloorsNoMissingValues(prebidFloors);

    return noMissingValues && !hasDuplicateRows && !hasDuplicateCols;
}

/**
 * Check whether a Prebid Server config is valid
 *
 * @param {object} config
 * @returns {boolean}
 */
export function isPrebidServerValid(config) {
    const { timeout, endpoint, syncEndpoint, defaultVendor } = config.prebidServer;

    const isTimeoutInvalid = timeout !== '' && (!regex.numeric.test(timeout) || timeout < 0 || timeout > 10000);
    const isEndpointInvalid = endpoint !== '' && !regex.url.test(endpoint);
    const isSyncEndpointInvalid = syncEndpoint !== '' && !regex.url.test(syncEndpoint);
    const isDefaultVendorInvalid = defaultVendor?.enabled && !defaultVendor?.value;

    return !(isTimeoutInvalid || isEndpointInvalid || isSyncEndpointInvalid || isDefaultVendorInvalid);
}

export function isValidBidderGroup(group) {
    let valid = true;
    group.bidders.forEach(bidder => {
        if (!bidder.active) {
            valid = true;
            return;
        }
        let hasInvalidParams = false;
        let hasEmptyParams = false;
        bidder.params.forEach(bidderParam => {
            if (bidderParam.rules) {
                bidderParam.rules.forEach(paramRule => {
                    if (paramRule.split(':').length === 2) {
                        // special rules with input parameters - oneOf:option1,option2 | required_if:condition | double_with_decimal:decimal_places | max:max_length
                        let [rule, input] = paramRule.split(':');
                        if (input.includes('@')) {
                            input = input.replace('@', '');
                        }

                        if (rule === 'oneOf' && bidderParam.value !== '' && !input.split(',').includes(bidderParam.value.toString())) {
                            valid = false;
                            hasInvalidParams = true;
                        }
                        if (rule === 'double_with_decimal' && bidderParam.value !== '' && !regex.two_decimal.test(bidderParam.value)) {
                            valid = false;
                            hasInvalidParams = true;
                        }
                        if (rule === 'max' && bidderParam.value.length > parseInt(input)) {
                            valid = false;
                            hasInvalidParams = true;
                        }

                        if (rule === 'prohibited_if' &&
                            ((bidderParam.value !== '' &&
                            bidder.params.find(p => p.key === input).value !== '') ||
                            (bidderParam.value === '' && bidder.params.find(p => p.key === input).value === ''))
                        ) {
                            valid = false;
                            hasEmptyParams = true;
                        }

                        if (rule === 'required_unless' &&
                            bidderParam.value === '' &&
                            bidder.params.find(p => p.key === input).value === ''
                        ) {
                            valid = false;
                            hasEmptyParams = true;
                        }

                        if (rule === 'required_if_set' &&
                            bidderParam.value === '' &&
                            bidder.params.find(p => p.key === input).value !== ''
                        ) {
                            valid = false;
                            hasEmptyParams = true;
                        }
                    } else {
                        // simple rule - required, numeric, numeric_dot, sizes
                        if (paramRule !== 'required' && ![undefined, null, ''].includes(bidderParam.value) && !regex[paramRule].test(bidderParam.value)) {
                            valid = false;
                            hasInvalidParams = true;
                        }

                        if (paramRule === 'required' && [undefined, null, ''].includes(bidderParam.value)) {
                            valid = false;
                            hasEmptyParams = true;
                        }
                    }
                });
            }
        });
        if (hasInvalidParams) {
            store.dispatch('sites/addPrebidBidderWithErrors', {
                groupUuid: group.uuid,
                bidderUuid: bidder.uuid,
            });
            store.dispatch('sites/setPrebidGroupsWithInvalidBidderParams', group.uuid);
            store.dispatch('sites/addErrorSubtab', 'prebid-configuration');
        }
        if (hasEmptyParams) {
            store.dispatch('sites/addPrebidBidderWithWarnings', {
                groupUuid: group.uuid,
                bidderUuid: bidder.uuid,
            });
            store.dispatch('sites/setPrebidGroupsWithEmptyBidderParams', group.uuid);
            store.dispatch('sites/addWarningSubtab', 'prebid-configuration');
        }
    });
    return valid;
}

/**
 * Check whether a Prebid version is valid
 *
 * @param {string} semverVersion A valid semverversion
 * @returns {boolean}
 */
export function isPrebidVersionValid(semverVersion) {
    const parts = String(semverVersion).split('.');
    const maxVersion = Number(parts[0]);
    const minVersion = Number(parts[1]);

    if (isNaN(maxVersion) || isNaN(minVersion)) {
        return false;
    }

    // There's an issue with webpack terser and prebid
    // affected prebid versions 8.38.0 - 8.44.0
    // https://github.com/prebid/Prebid.js/pull/11292
    const isBlackListed = (maxVersion === 8) && (minVersion >= 38 && minVersion <=44);

    return !isBlackListed;
}

/**
 * Check whether a Prebid config is valid
 *
 * @param {object} config
 * @returns {boolean}
 */
export function isPrebidValid(config) {
    const prebidConfiguration = config.prebid;
    const prebidUserSync = config.prebidUserSync;
    const prebidCurrency = config.prebidCurrency;

    const invalidVersion = prebidConfiguration.versionType === 'exact' &&
        prebidConfiguration.version &&
        (!regex.version_number.test(prebidConfiguration.version) ||
            !isPrebidVersionValid(prebidConfiguration.version));

    const invalidTimeout = prebidConfiguration.timeout !== '' &&
      (!regex.numeric.test(prebidConfiguration.timeout) ||
        prebidConfiguration.timeout < 0 ||
        prebidConfiguration.timeout > 10000);

    const invalidPbjsGlobal = prebidConfiguration.pbjsGlobal !== '' &&
        !regex.alpha_num_dash.test(prebidConfiguration.pbjsGlobal);

    const invalidPageUrl = prebidConfiguration.pageUrl &&
        !regex.http_url.test(prebidConfiguration.pageUrl);

    const invalidBlocklist = prebidConfiguration.hasAdvertiserBlockList &&
        prebidConfiguration.advertiserBlockList.length > 0 &&
        (prebidConfiguration.advertiserBlockList.find(
            domain => !regex.domain.test(domain) && !regex.url.test(domain)
        ) !== undefined ||
        prebidConfiguration.advertiserBlockList.filter((domain, index) =>
            prebidConfiguration.advertiserBlockList.indexOf(domain) !== index
        ).length > 0);

    const invalidSyncDelay = prebidUserSync.syncDelay !== '' &&
        (!regex.numeric.test(prebidUserSync.syncDelay) ||
        prebidUserSync.syncDelay < 0 ||
        prebidUserSync.syncDelay > 10000);

    const invalidSyncsPerBidder = prebidUserSync.syncsPerBidder !== '' &&
        (!regex.numeric.test(prebidUserSync.syncsPerBidder) ||
        prebidUserSync.syncsPerBidder < 1);

    const invalidAuctionDelay = prebidUserSync.auctionDelay !== '' &&
        (!regex.numeric.test(prebidUserSync.auctionDelay) ||
        prebidUserSync.auctionDelay > 2000);

    const invalidUserSyncUserIdReporting = !isPrebidUserSyncUserIdReportingValid(prebidUserSync.userIdReporting);

    const invalidGranularity = prebidCurrency.granularityMultiplier !== '' &&
        !regex.numeric.test(prebidCurrency.granularityMultiplier);

    const invalidSecondaryBidders = prebidConfiguration.hasSecondaryBidders && prebidConfiguration.secondaryBidders.length === 0;

    const hasInvalidPrebidConfiguration = invalidVersion || invalidTimeout || invalidPbjsGlobal || invalidPageUrl ||
        invalidBlocklist || invalidSyncDelay || invalidSyncsPerBidder || invalidAuctionDelay || invalidUserSyncUserIdReporting ||
        invalidGranularity || invalidSecondaryBidders;

    // TODO price buckets validation

    let hasInvalidGroupNames = false;
    let hasInvalidGroupBidders = false;

    if (config.prebid && config.prebid.groups) {
        // the name of the group must be valid
        const prebidGroupNames = config.prebid.groups.map(group => group.name);
        const hasDuplicateNames = new Set(prebidGroupNames).size !== prebidGroupNames.length;
        const hasIllegalCharacters = prebidGroupNames
            .filter(name => name !== '' && !regex.alpha_num_dash_slash.test(name)).length > 0;
        hasInvalidGroupNames = hasDuplicateNames ||hasIllegalCharacters;

        // each field must be valid
        config.prebid.groups.forEach(group => {
            if (!isValidBidderGroup(group)) {
                hasInvalidGroupBidders = true;
            }
        });
    }

    const isValid = !hasInvalidPrebidConfiguration && !hasInvalidGroupNames && !hasInvalidGroupBidders;

    return isValid;
}

/**
 * Check if the provided traffic shaping data is valid.
 *
 * @param {Object} options - The options for traffic shaping.
 * @param {Array} options.trafficShaping - An array of traffic shaping data.
 * @param {Object} options.prebid - Prebid configuration data.
 * @param {Array} options.prebid.groups - An array of bidder groups.
 * @returns {boolean} True if all traffic shaping data is valid, false otherwise.
 */
export function isTrafficShapingValid({trafficShaping, prebid, options = {}}) {
    if (!options.hasTrafficShaping) {
        return true;
    }

    if (options.hasTrafficShaping && !options.hasPrebid) {
        return false;
    }

    return isTrafficShapingReportingValid({trafficShaping})
        && isTrafficShapingBiddersValid({trafficShaping, prebid, amazon: options.hasAmazon})
        && isTrafficShapingConditionsValid({trafficShaping});
}

/**
 * Check if the provided traffic shaping reporting config is valid
 *
 * @param {Object} options - The options for traffic shaping.
 * @returns {boolean} True if traffic shaping reporting config is valid, false otherwise.
 */
export function isTrafficShapingReportingValid({trafficShaping}) {
    const {gamReportingEnabled, gamTargetingKeyPrefix} = trafficShaping;
    // if reporting is disabled, skip validation
    if (!gamReportingEnabled) {
        return true;
    }
    // check valid GAM key names
    const keyCharsValid = regex.alpha_num_underscore.test(gamTargetingKeyPrefix);

    // check they do not exceed 20 chars
    const keyLengthValid = gamTargetingKeyPrefix.length <= 20 && gamTargetingKeyPrefix.length > 0;

    // check all GAM key name criteria met
    const keyValid = keyCharsValid && keyLengthValid;

    return keyValid;
}

/**
 * Check if the provided traffic shaping bidders are valid.
 *
 * @param {Object} options - The options for traffic shaping bidders.
 * @param {Array} options.trafficShaping - An array of traffic shaping data.
 * @param {Object} options.prebid - Prebid configuration data.
 * @param {Array} options.prebid.groups - An array of bidder groups.
 * @returns {boolean} True if all traffic shaping bidders are valid, false otherwise.
 */
export function isTrafficShapingBiddersValid({trafficShaping, prebid, amazon}) {
    const activeBidders = new Set(prebid.groups
        .flatMap(({ bidders }) => bidders)
        .filter(({ active }) => active)
        .map(({ bidder }) => bidder));
    if (amazon) {
        activeBidders.add('amazon');
    }

    // bidder, operator and conditions are all required
    const invalidRows = trafficShaping.rules.map(({bidder, operator}) => {
        return bidder && activeBidders.has(bidder) && operator;
    }).filter(v => !v);
    if (invalidRows.length) {
        return false;
    }

    // disallow same bidder twice
    const bidderCount = trafficShaping.rules.reduce((obj, rule) => {
        if (!(rule.bidder in obj)) {
            obj[rule.bidder] = 0;
        }
        obj[rule.bidder] += 1;
        return obj;
    }, {});

    const anyDuplicateBidders = Object.entries(bidderCount).filter(([_, count]) => count > 1); // eslint-disable-line

    const valid = anyDuplicateBidders.length === 0;
    return valid;
}

/**
 * Check if the provided traffic shaping conditions are valid.
 *
 * @param {Object} options - The options for traffic shaping conditions.
 * @param {Array} options.trafficShaping - An array of traffic shaping data.
 * @param {Object} options.trafficShaping[].conditions - An object representing traffic shaping conditions.
 * @returns {boolean} True if all traffic shaping conditions are valid, false otherwise.
 */
export function isTrafficShapingConditionsValid({trafficShaping}) {
    const invalidRows = trafficShaping.rules.map(({conditions}) => {
        const conditionKeysOk = Object.keys(conditions)?.length;
        const conditionsOk = Object.values(conditions)?.filter(v => v.length === 0).length === 0;
        return conditionKeysOk && conditionsOk;
    }).filter(v => !v);
    if (invalidRows.length) {
        return false;
    }
    return true;
}

/**
 * Check whether any Prebid Floors Rules are duplicated
 *
 * @param {object} prebidFloors
 * @returns {boolean} - true if duplicates exist
 * @see https://docs.prebid.org/dev-docs/modules/floors.html
 */
export function hasPrebidFloorsDuplicateRows(prebidFloors) {
    const {fields, rules} = prebidFloors;

    // get unique value for each row
    const ruleStrings = rules.map(rule => fields.map(field => `${field}=${rule.values[field]}`).join('|'));

    // true, if duplicates exist
    return helpers.containsDuplicates(ruleStrings);
}

/**
 * Check whether any Prebid Floors Rules contain duplicate columns
 *
 * @param {object} prebidFloors
 * @returns {boolean} - true if duplicates exist
 * @see https://docs.prebid.org/dev-docs/modules/floors.html
 */
export function hasPrebidFloorsDuplicateCols(prebidFloors) {
    const {fields} = prebidFloors;

    // true, if duplicates exist
    return helpers.containsDuplicates(fields);
}

/**
 * Check whether all Prebid Floors Rules have values for every field
 *
 * @param {object} prebidFloors
 * @returns {boolean}
 * @see https://docs.prebid.org/dev-docs/modules/floors.html
 */
export function prebidFloorsNoMissingValues(prebidFloors) {
    const {fields, rules} = prebidFloors;

    // ensure that every rule has set a value for every field
    const incompleteRules = rules.filter(rule => {
        const values = fields.map(field => rule.values[field]);
        const anyMissing = values.filter(v => !v);
        return anyMissing.length;
    });

    return incompleteRules.length === 0;
}

/**
 * Check if user synchronization settings are valid.
 *
 * @param {Object} userSync - User synchronization settings.
 * @returns {boolean} True if the user synchronization settings are valid; otherwise, false.
 */
export function isUserSyncValid(userSync) {
    if (userSync.syncType !== 'disabled') {
        if (Array.isArray(userSync.filterSettings?.iframe?.bidders)) {
            if (!userSync.filterSettings.iframe.bidders.length) {
                return false;
            }
        }

        if (Array.isArray(userSync.filterSettings?.image?.bidders)) {
            if (!userSync.filterSettings.image.bidders.length) {
                return false;
            }
        }
    }

    if (!isPrebidUserSyncUserIdReportingValid(userSync.userIdReporting)) {
        return false;
    }

    return true;
}

/**
 * Check if Prebid has warnings and add them to the store.
 * Cheks for config, groups, currency and first look warnings.
 *
 * @param {Object} config - The main configuration object.
 * @returns {boolean} Returns true if there are prebid configuration warnings, false otherwise.
 */
export function hasPrebidConfigurationWarnings(config) {
    const hasInvalidConfiguration = hasPrebidConfigWarnings(
        config.options.hasPrebid,
        config.prebid
    );
    const hasInvalidGroups = hasPrebidGroupsWarnings(
        config.options.hasPrebid,
        config.prebid.groups
    );
    const hasCurrencyWarnings = hasPrebidCurrencyWarnings(
        config.options.hasPrebidCurrency,
        config.prebidCurrency
    );
    const hasFirstLookWarnings = hasPrebidFirstLookWarnings(
        config.options.hasPrebidFirstLook,
        config.prebidFirstLook
    );

    return {
        hasInvalidConfiguration,
        hasInvalidGroups,
        hasCurrencyWarnings,
        hasFirstLookWarnings,
    }
}

/**
 * Checks if there are prebid configuration warnings.
 *
 * @param {boolean} hasPrebid - Whether or not the site has prebid enabled
 * @param {Object} prebidConfiguration - The prebid configuration object
 * @returns {boolean} - Returns true if there are prebid configuration warnings, false otherwise.
 */
export function hasPrebidConfigWarnings(hasPrebid, prebidConfiguration) {
    const hasExactVersionWarnings =
        prebidConfiguration.versionType === 'exact' &&
        (!prebidConfiguration.version || prebidConfiguration.version === '');
    const hasPageUrlWarnings =
        prebidConfiguration.hasCustomPageUrl &&
        (!prebidConfiguration.pageUrl || prebidConfiguration.pageUrl === '');
    const hasAdvertiserBlockListWarnings =
        prebidConfiguration.hasAdvertiserBlockList &&
        (!prebidConfiguration.advertiserBlockList ||
            prebidConfiguration.advertiserBlockList.length === 0);
    return (
        hasPrebid &&
        (hasExactVersionWarnings ||
            hasPageUrlWarnings ||
            hasAdvertiserBlockListWarnings)
    );
}

/**
 * Checks if there are warnings for any of the prebid groups.
 *
 * @param {boolean} hasPrebid - Whether or not the version has prebid enabled
 * @param {Object} prebidGroups - The prebid groups object
 * @returns {boolean} - Returns true if there are prebid groups warnings, false otherwise.
 */
export function hasPrebidGroupsWarnings(hasPrebid, prebidGroups) {
    if (!hasPrebid) {
        return false;
    }
    let hasPrebidGroupsWarnings = false;
    prebidGroups.forEach(group => {
        if (!isValidBidderGroup(group)) {
            hasPrebidGroupsWarnings = true;
        }
    });

    return hasPrebidGroupsWarnings;
}

/**
 * Checks if there are prebid currency warnings.
 *
 * @param {boolean} hasPrebidCurrency - Whether or not the version has prebid currency enabled
 * @param {Object} prebidCurrency - The prebid currency object
 * @returns {boolean} - Returns true if there are prebid currency warnings, false otherwise.
 */
export function hasPrebidCurrencyWarnings(hasPrebidCurrency, prebidCurrency) {
    return (
        hasPrebidCurrency &&
        (prebidCurrency.adServerCurrency === '' ||
            prebidCurrency.granularityMultiplier === '')
    );
}

/**
 * Checks if there are prebid bid adjustment warnings.
 *
 * @param {Object} config - The main configuration object.
 */
export function validatePrebidBidAdjustments(config) {
    const hasErrors = !isPrebidBidAdjustmentValid(config);

    if (hasErrors) {
        store.dispatch('sites/addPrebidConfigTabWithErrors', 'PrebidBidAdjustment');
    } else {
        store.dispatch(
            'sites/removePrebidConfigTabWithErrors',
            'PrebidBidAdjustment'
        );
        if (store.getters['sites/prebidConfigTabsWithErrors'].length === 0) {
            store.dispatch('sites/removeErrorSubtab', 'prebid-configuration');
            store.dispatch('sites/clearValidationAlertMessage');
        }
    }
}

/**
 * Checks if there are prebid first look warnings.
 *
 * @param {boolean} hasPrebidFirstLook - Whether or not the version has prebid first look enabled
 * @param {Object} prebidFirstLook - The prebid first look object
 * @returns {boolean} - Returns true if there are prebid first look warnings, false otherwise.
 */
export function hasPrebidFirstLookWarnings(hasPrebidFirstLook, prebidFirstLook) {
    return (
        hasPrebidFirstLook &&
        (!prebidFirstLook.globalFloor ||
            prebidFirstLook.globalFloor === '' ||
            parseFloat(prebidFirstLook.globalFloor) === 0)
    );
}

/**
 * Check for indetitiy module compatibility with prebid version
 *
 * @param {Object} config - The main configuration object.
 */
export function checkIdentityVersions(config) {
    let prebidVersion = 'latest';
    if (config.prebid.versionType === 'default') {
        prebidVersion = vh.defaultPrebidVersion;
    } else if (config.prebid.versionType === 'exact') {
        prebidVersion = config.prebid.version;
    }

    if (prebidVersion === 'latest') {
        store.dispatch('sites/removeAllIncompatibleIdentityModules');
        return;
    }

    validatePrebidVersionCompatibility(config, prebidVersion);
}

function validatePrebidVersionCompatibility(config, prebidVersion) {
    options.identity.forEach(option => {
        if (config.options[option.key] && option.minPrebidVersion) {
            const comparison = helpers.compareVersion(
                prebidVersion,
                option.minPrebidVersion
            );
            if (comparison === -1 || comparison === false) {
                // invalid -> prebidVersion is smaller then identity version
                // supported when Prebid version is the same or higher than what the module needs)
                store.dispatch(
                    'sites/addIncompatibleIdentityModule',
                    option.warningTabKey
                );
            } else {
                store.dispatch(
                    'sites/removeIncompatibleIdentityModule',
                    option.warningTabKey
                );
            }
        }
    });
    integrationModules
        .reduce((acc, curr) => acc.concat(curr.submodules), [])
        .filter(module => module.minPrebidVersion).forEach(module => {
            if (!config[module.key].enabled) {
                return;
            }
            const comparison = helpers.compareVersion(
                prebidVersion,
                module.minPrebidVersion
            );
            if (comparison === -1 || comparison === false) {
                store.dispatch('sites/addIncompatibleIdentityModule', module.key);
            } else {
                store.dispatch('sites/removeIncompatibleIdentityModule', module.key);
            }
        });
}

/**
 * Checks if the total line items across buckets is valid.
 *
 * @param {Array} buckets - The array of buckets to validate.
 * @returns {boolean} Returns true if the total line items are less than 5001, false otherwise.
 */
export function bucketTotalLineItemsValid(buckets) {
    const totalLineItems = buckets.reduce((total, bucket) => {
        return total + (bucket.max - bucket.min) / bucket.increment;
    }, 0);

    return totalLineItems < 5001;
}

/**
 * Checks if all buckets have non-negative values.
 *
 * @param {Array} buckets - The array of buckets to validate.
 * @returns {boolean} Returns true if all buckets have non-negative values, false otherwise.
 */
export function bucketHasNegativeValuesValid(buckets) {
    return buckets.every(b => parseFloat(b.min) >= 0 && parseFloat(b.max) >= 0);
}

/**
 * Check if a list of buckets is valid, ensuring that the minimum values are less than the maximum values.
 *
 * @param {Array} buckets - An array of bucket objects.
 * @returns {boolean} True if all buckets are valid; otherwise, false.
 */
export function bucketIsMaxValid(buckets) {
    return buckets.every(bucket => parseFloat(bucket.min) < parseFloat(bucket.max));
}

/**
 * Check if a list of buckets is valid, ensuring that there is no overlap between adjacent buckets.
 *
 * @param {Array} buckets - An array of buckets, where each bucket has a `max` value.
 * @returns {boolean} True if there is no overlap between adjacent buckets; otherwise, false.
 */
export function bucketOverlapValid(buckets) {
    for (let i = 0; i < buckets.length - 1; i++) {
        if (parseFloat(buckets[i].max) > parseFloat(buckets[i + 1].min)) {
            return false;
        }
    }

    return true;
}

/**
 * Check if a list of buckets is valid, ensuring that there are no gaps between adjacent buckets.
 *
 * @param {Array} buckets - An array of buckets, where each bucket has a `min` value.
 * @returns {boolean} True if there are no gaps between adjacent buckets; otherwise, false.
 */
export function bucketGapValid(buckets) {
    let valid = true;

    for (let i = 0; i < buckets.length - 1; i++) {
        const currentBucket = buckets[i];
        const nextBucket = buckets[i + 1];

        if (parseFloat(nextBucket.min) > parseFloat(currentBucket.max)) {
            valid = false;
        }
    }

    return valid;
}

/**
 * Check if a list of buckets is valid, ensuring that the increment values are in increasing order.
 *
 * @param {Array} buckets - An array of buckets, where each bucket has an `increment` value.
 * @returns {boolean} True if the increment values are in increasing order; otherwise, false.
 */
export function bucketHighIncrementValid(buckets) {
    let valid = true;

    for (let i = 0; i < buckets.length - 1; i++) {
        const currentBucket = buckets[i];
        const nextBucket = buckets[i + 1];

        if (parseFloat(currentBucket.increment) > parseFloat(nextBucket.increment)) {
            valid = false;
        }
    }

    return valid;
}

/**
 * Check if all bucket validation criteria are met for a given configuration.
 *
 * @param {Object} config - The configuration object.
 * @returns {boolean} True if all bucket validation criteria are met; otherwise, false.
 */
export function isBucketsAllValid(config) {
    if (config.prebid.customGranularity) {
        const buckets = config?.prebid?.customGranularity?.buckets;
        return (
            bucketTotalLineItemsValid(buckets) &&
            bucketHasNegativeValuesValid(buckets) &&
            bucketIsMaxValid(buckets) &&
            bucketOverlapValid(buckets) &&
            bucketGapValid(buckets) &&
            bucketHighIncrementValid(buckets)
        );
    }
    return true;
}

/**
 * Checks if there are price buckets warnings.
 *
 * @param {Object} buckets - The price buckets to validate.
 * @returns {boolean} Returns true if there are price buckets warnings, false otherwise.
 */
export function hasBucketTotalLineItemsWarnings(buckets) {
    let totalLineItems = 0;
    buckets.forEach(b => {
        const localLineItems = (b.max - b.min) / b.increment;
        totalLineItems += localLineItems;
    });
    return totalLineItems >= 450 ? true : false;
}

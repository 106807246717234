export default {
    SET_UPLOADED_PREBID_IDS(state, uploadedPrebidIds) {
        state.uploadedPrebidIds = uploadedPrebidIds;
    },
    SET_SELECTED_PREBID_ID_UPLOAD(state, selectedIdUpload) {
        state.selectedIdUpload = selectedIdUpload;
    },
    SET_UPLOADED_FILE(state, uploadedFile) {
        state.uploadedFile = uploadedFile;
    },
    SET_CURRENT_STEP(state, currentStep) {
        state.currentStep = currentStep;
    },
    SET_REMOVE_UPLOAD(state, removeUpload) {
        state.removeUpload = removeUpload;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

import debounce from '@util/debounce';

/*
  ALERT MESSAGE TYPES:
  - success
  - info
  - alert
  - error

  REQUIRED PARAMETERS:
  - message

  OPTIONAL PARAMETERS:
  - type => default = 'success'
  - description => default = ''
*/

const state = {
    message: '',
    type: '',
    description: '',
    closable: true,
    limitTo: null,
};

const mutations = {
    SET_ALERT_MESSAGE(state, messageOptions) {
        const {
            message,
            type = 'success',
            description = '',
            closable = true,
            limitTo = null,
        } = messageOptions;
        state.message = message;
        state.type = type;
        state.description = description;
        state.closable = closable;
        state.limitTo = limitTo;
    },
    CLEAR_ALERT_MESSAGE(state) {
        state.message = '';
        state.type = '';
        state.description = '';
        state.closable = true;
        state.limitTo = null;
    },
};

const actions = {
    setAlertMessage({ commit, dispatch }, messageOptions) {
        const { keepMessage } = messageOptions;
        commit('SET_ALERT_MESSAGE', messageOptions);
        if (!keepMessage) dispatch('clearAlertMessage');
    },
    clearAlertMessage: debounce(({ commit }) => {
        commit('CLEAR_ALERT_MESSAGE');
    }, 5000),
};

const getters = {
    alertMessage: ({ message, type, description, closable, limitTo }) => ({
        message,
        type,
        description,
        closable,
        limitTo,
    }),
};

export default {
    state,
    mutations,
    actions,
    getters,
};

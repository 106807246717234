import helpers from '@util/helpers';

const defaultOptions = {
    eager: true,
    sticky: false,
    stickyCanClose: null,
    stickyMinutes: '0',
    expandable: false,
    interstitial: false,
    outOfPage: false,
    refresh: true,
    refreshType: 'viewable',
    refreshSecs: '45',
    lockRefreshSize: false,
    stickyFreqCap: null,
    stickyFreqMinutes: null,
    lazyPixels: null,
    optimeraSmartRefresh: false,
    optimeraMinTimeInView: 1.1,
    optimeraMinTimeOnPage: 30,
};

const stickyOptions = helpers.deepCopy(defaultOptions);
stickyOptions.sticky = true;

const defaultSlots = [
    {
        unit: 'desktop_billboard',
        name: 'desktop_billboard',
        where: 'appendChildEach',
        selector: '.htlad-desktop_billboard',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_billboard',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: null,
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '970x250',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'desktop_billboard_flex',
        name: 'desktop_billboard_flex',
        where: 'appendChildEach',
        selector: '.htlad-desktop_billboard_flex',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_billboard_flex',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: null,
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '970x250',
                    },
                    {
                        size: '970x90',
                    },
                    {
                        size: '728x90',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'desktop_leaderboard',
        name: 'desktop_leaderboard',
        where: 'appendChildEach',
        selector: '.htlad-desktop_leaderboard',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_leaderboard',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: null,
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '728x90',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'desktop_leaderboard_sticky',
        name: 'desktop_leaderboard_sticky',
        where: 'appendChildEach',
        selector: '.htlad-desktop_leaderboard_sticky',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_leaderboard_sticky',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: null,
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '728x90',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(stickyOptions),
    },
    {
        unit: 'desktop_halfpage',
        name: 'desktop_halfpage',
        where: 'appendChildEach',
        selector: '.htlad-desktop_halfpage',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_halfpage',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: null,
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '300x600',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'desktop_halfpage_flex',
        name: 'desktop_halfpage_flex',
        where: 'appendChildEach',
        selector: '.htlad-desktop_halfpage_flex',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_halfpage_flex',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: null,
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '300x250',
                    },
                    {
                        size: '300x600',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'desktop_medrec',
        name: 'desktop_medrec',
        where: 'appendChildEach',
        selector: '.htlad-desktop_medrec',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-desktop_medrec',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [],
                groups: [],
            },
            {
                viewport: '768x0',
                sizes: [
                    {
                        size: '300x250',
                    },
                ],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'mobile_leaderboard',
        name: 'mobile_leaderboard',
        where: 'appendChildEach',
        selector: '.htlad-mobile_leaderboard',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-mobile_leaderboard',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [
                    {
                        size: '320x50',
                    },
                ],
                groups: [],
            },
            {
                viewport: '768x0',
                sizes: [],
                groups: null,
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'mobile_leaderboard_sticky',
        name: 'mobile_leaderboard_sticky',
        where: 'appendChildEach',
        selector: '.htlad-mobile_leaderboard_sticky',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-mobile_leaderboard_sticky',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [
                    {
                        size: '320x50',
                    },
                ],
                groups: [],
            },
            {
                viewport: '768x0',
                sizes: [],
                groups: null,
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(stickyOptions),
    },
    {
        unit: 'mobile_medrec',
        name: 'mobile_medrec',
        where: 'appendChildEach',
        selector: '.htlad-mobile_medrec',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-mobile_medrec',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [
                    {
                        size: '300x250',
                    },
                ],
                groups: [],
            },
            {
                viewport: '768x0',
                sizes: [],
                groups: null,
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
    {
        unit: 'mobile_medrec_flex',
        name: 'mobile_medrec_flex',
        where: 'appendChildEach',
        selector: '.htlad-mobile_medrec_flex',
        type: 'selector',
        maxPer: null,
        density: null,
        pollType: 'selector',
        pollSelector: '.htlad-mobile_medrec_flex',
        pollCallback: null,
        uuid: null,
        sizeMappings: [
            {
                viewport: '0x0',
                sizes: [
                    {
                        size: '300x250',
                    },
                    {
                        size: '320x50',
                    },
                ],
                groups: [],
            },
            {
                viewport: '768x0',
                sizes: [],
                groups: [],
            },
        ],
        targeting: [],
        note: '',
        options: helpers.deepCopy(defaultOptions),
    },
];

export default {
    defaultSlots,
};

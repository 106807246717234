const mutations = {
    UPDATE_VERSION_BLUECONIC(state, options) {
        const { key, value } = options;
        state.version.config.blueConic[key] = value;
    },
    UPDATE_VERSION_BLUECONIC_ANALYTICS(state, options) {
        const { key, value } = options;
        state.version.config.blueConicAnalytics[key] = value;
    }
};

const actions = {
    updateVersionBlueConic({ commit }, options) {
        commit('UPDATE_VERSION_BLUECONIC', options);
    },
    updateVersionBlueConicAnalytics({ commit }, options) {
        commit('UPDATE_VERSION_BLUECONIC_ANALYTICS', options);
    }
};

const getters = {
    blueConic: ({ version }) => version.config.blueConic || {},
    blueConicAnalytics: ({ version }) => version.config.blueConicAnalytics || {}
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_CONFIANT(state, confiantOptions) {
        const { key, value } = confiantOptions;
        state.version.config.confiant[key] = value;
    },
};

const actions = {
    updateVersionConfiant({ commit }, confiantOptions) {
        commit('UPDATE_VERSION_CONFIANT', confiantOptions);
    },
};

const getters = {
    confiant: ({ version }) => version.config.confiant || {},
};

export default {
    mutations,
    actions,
    getters,
};

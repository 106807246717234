import Vue from 'vue';
import Vuex from 'vuex';
import general from './general';
import sites from './sites';
import builds from './builds';
import gamServices from './gamServices';
import users from './users';
import alertMessage from './alertMessage';
import authentication from './authentication';
import networks from './networks';
import accounts from './accounts';
import settings from './settings';
import customers from './customers';
import idUploads from './idUploads';
import adTechServices from './adTechServices';
import alerts from './alerts';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        general,
        builds,
        sites,
        gamServices,
        users,
        alertMessage,
        authentication,
        networks,
        accounts,
        settings,
        customers,
        idUploads,
        adTechServices,
        alerts,
    },
});

const mutations = {
    UPDATE_VERSION_PERMUTIVE(state, permutiveOptions) {
        const { key, value } = permutiveOptions;
        state.version.config.permutive[key] = value;
    },
};

const actions = {
    updateVersionPermutive({ commit }, permutiveOptions) {
        commit('UPDATE_VERSION_PERMUTIVE', permutiveOptions);
    },
};

const getters = {
    permutive: ({ version }) => version.config.permutive || {},
};

export default {
    mutations,
    actions,
    getters,
};

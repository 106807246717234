const mutations = {
    UPDATE_VERSION_QORTEX(state, options) {
        const { key, value } = options;
        state.version.config.qortex[key] = value;
    },
};

const actions = {
    updateVersionQortex({ commit }, options) {
        commit('UPDATE_VERSION_QORTEX', options);
    },
};

const getters = {
    qortex: ({ version }) => version.config.qortex || {},
};

export default {
    mutations,
    actions,
    getters,
};

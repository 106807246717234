import { authorizedAxios } from '@config/axios';

export const fetchLineItemJobs = params =>
    authorizedAxios.get('/api/v1/line-items', { params });

export const fetchTargetingJobs = params =>
    authorizedAxios.get('/api/v1/targeting', { params });

export const createJob = body =>
    authorizedAxios.post('/api/v1/line-items', body);

export const cancelJob = lineItemJobId =>
    authorizedAxios.patch(`/api/v1/line-items/${lineItemJobId}`);

export const getJobById = jobId =>
    authorizedAxios.get(`/api/v1/line-items/${jobId}`);

const mutations = {
    UPDATE_VERSION_PUBLINK_ID(state, publinkIdOptions) {
        const { key, value } = publinkIdOptions;
        state.version.config.publinkId[key] = value;
    },
};

const actions = {
    updateVersionPublinkId({ commit }, publinkIdOptions) {
        commit('UPDATE_VERSION_PUBLINK_ID', publinkIdOptions);
    },
};

const getters = {
    publinkId: ({ version }) => version.config.publinkId || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    fetchAdsTxt,
    saveAdsTxt,
    fetchAdsTxtHistory,
    fetchAdsTxtEntry,
    activateAdsTxtEntry
} from '../../../../api/sites';

const mutations = {
    SET_ADS_TXT(state, payload) {
        state.adsTxt = payload;
    },
    SET_ADS_TXT_HISTORY(state, payload) {
        state.adsTxtHistory = payload;
    },
    SET_ADS_TXT_ENTRY(state, payload) {
        state.adsTxtEntry = payload;
    }
}

const actions = {
    async getAdsTxt({ commit }, payload) {
        try {
            const { siteId } = payload;
            const response = await fetchAdsTxt(siteId);
            if (!response.adTxt) {
                commit('SET_ADS_TXT', '');
            } else {
                commit('SET_ADS_TXT', response.adTxt);
                if (response.adTxt?.status === 'PENDING') {
                    commit(
                        'SET_ALERT_MESSAGE',
                        {
                            message: 'Updates processing, please wait a few minutes for the updates to go live.',
                            type: 'info',
                            keepMessage: true,
                        },
                        { root: true }
                    );
                } else {
                    commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
                }
            }
        } catch (e) {
            console.log(e);
        }
    },

    async setAdsTxt({ commit }, payload) {
        try {
            const body = {
                content: payload.adsTxt,
            }
            const response = await saveAdsTxt(payload.siteId, body);
            commit('SET_ADS_TXT', response.adTxt);
            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: 'Your changes have been saved.',
                    type: 'success',
                },
                { root: true }
            );
            setTimeout(() => {
                commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
                commit(
                    'SET_ALERT_MESSAGE',
                    {
                        message: 'Updates processing, please wait a few minutes for the updates to go live.',
                        type: 'info',
                        keepMessage: true,
                        limitTo: 'ads-txt-ids',
                    },
                    { root: true }
                );
            }, 2000);
        } catch (e) {
            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: e.errors?.length ? e.errors[0].message : 'Something went wrong.',
                    type: 'error',
                },
                { root: true }
            );
            setTimeout(() => {
                commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
            }, 5000);
        }
    },

    async getAdsTxtHistory({ commit, rootGetters }, siteId) {
        try {
            const { data, metadata } = await fetchAdsTxtHistory(siteId, rootGetters.queryParams);
            commit('SET_ADS_TXT_HISTORY', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch(e) {
            console.log(e);
            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: e.errors?.length ? e.errors[0].message : "Couldn't fetch ads.txt history.",
                    type: 'error',
                },
                { root: true }
            );
        }
    },

    async getAdsTtxEntry({ commit }, payload) {
        const adsTxtId = payload.adsTxtId;
        const siteId = payload.siteId;

        const response = await fetchAdsTxtEntry(siteId, adsTxtId);

        commit('SET_ADS_TXT_ENTRY', response.adsTxt);
    },

    async activateAdsTxtEntry({ commit }, payload) {
        try {
            const adsTxtId = payload.adsTxtId;
            const siteId = payload.siteId;

            const activatedRes = await activateAdsTxtEntry(siteId, adsTxtId);
            commit('SET_ADS_TXT_ENTRY', activatedRes.adsTxt);

            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: 'Ads.txt entry is being re-activated. This may take a few minutes.',
                    type: 'success',
                },
                { root: true }
            );
            setTimeout(() => {
                commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
            }, 5000);
        } catch(e) {
            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: e.errors?.length ? e.errors[0].message : 'Something went wrong.',
                    type: 'error',
                },
                { root: true }
            );
            setTimeout(() => {
                commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
            }, 5000);
        }
    }
}

const getters = {
    adsTxt: ({ adsTxt }) => adsTxt,
    adsTxtHistory: ({adsTxtHistory}) => adsTxtHistory,
    adsTxtEntry: ({adsTxtEntry}) => adsTxtEntry
}

export default { mutations, actions, getters };

export function addIntegrationModule(module) {
    integrationModules.push(module);
}

export function addOutputFunction(output) {
    moduleOutputs[output.key] = output.func;
}

export function addModuleDocs(doc) {
    moduleDocs[doc.key] = doc.docs;
}

export const integrationModules = [];
export const moduleOutputs = {};
export const moduleDocs = {};

const mutations = {
    SET_CONSENT_MANAGEMENT(state, consentManagementOptions) {
        const { category, key, value } = consentManagementOptions;

        if (!category) {
            state.version.config.consentManagerVersion2[key] = value;
        } else {
            state.version.config.consentManagerVersion2[category][key] = value;
        }

        // MSPA requires GPP, if GPP is off, force MSPA to be disabled as well
        if (key === 'gppSignal' && value === 'never') {
            state.version.config.consentManagerVersion2.settings.mspaSignal = 'never';
        }
    },
};

const actions = {

    // CONSENT MANAGEMENT
    setConsentManagement({ commit }, consentManagementOptions) {
        commit('SET_CONSENT_MANAGEMENT', consentManagementOptions);
    },
};

const getters = {
    consentManagement: ({ version }) =>
        version.config.consentManagerVersion2 || {},
};

export default {
    mutations,
    actions,
    getters,
};

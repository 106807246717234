const mutations = {
};

const actions = {
};

const getters = {
};

export default {
    mutations,
    actions,
    getters,
};

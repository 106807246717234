import helpers from '@util/helpers';
import uuidv4 from 'uuid/v4';
import biddersUtil from '@util/bidders';
import versionValidationHelpers from '@util/versionValidationHelpers';

export default {
    setPrebidConfigurationField({ commit }, prebidOptions) {
        commit('SET_PREBID_CONFIGURATION_FIELD', prebidOptions);
    },
    setPrebidUserSyncField({ commit }, userSyncOptions) {
        commit('SET_PREBID_USER_SYNC_FIELD', userSyncOptions);
    },

    updateUserSyncUserIdReporting({ commit }, options) {
        commit('UPDATE_USER_SYNC_USER_ID_REPORTING', options);
    },

    updateUserSyncFilter({ commit }, options) {
        commit('UPDATE_USER_SYNC_FILTER', options);
    },

    updateUserSyncTargeting({ commit }, options) {
        commit('UPDATE_USER_SYNC_TARGETING', options);
    },

    setPrebidCurrencyField({ commit }, prebidCurrencyOptions) {
        commit('SET_PREBID_CURRENCY_FIELD', prebidCurrencyOptions);
    },

    // PREBID ID GROUPS
    addNewPrebidIDGroup({ commit, state }) {
        const appNexus = helpers.deepCopy(biddersUtil.defaultAppnexusBidder);
        const bidder = {
            active: true,
            bidder: appNexus.key,
            params: [...appNexus.params],
            deprecated: appNexus.deprecated,
            uuid: uuidv4(),
        };

        const groupObject = {
            name: '',
            uuid: uuidv4(),
            bidders: [bidder],
        };

        commit('ADD_NEW_PREBID_ID_GROUP', groupObject);
        commit('SYNC_VERSION_NETWORKS');
        commit('SYNC_PREBID_BID_ADJUSTMENTS');
        commit('SYNC_PREBID_FIRST_LOOK_BIDDERS');
        commit('SYNC_TRAFFIC_SHAPING');

        versionValidationHelpers.validateVersionErrors(state.version, 'warning');
    },
    updatePrebidIDGroupName({ commit }, groupOptions) {
        commit('UPDATE_SLOT_SIZE_MAPPING_PREBID_GROUP_NAME', groupOptions);
        commit('UPDATE_PREBID_ID_GROUP_NAME', groupOptions);
    },
    removePrebidIDGroup({ commit, state }, groupUuid) {
        commit('DELETE_PREBID_GROUP_FROM_SLOT_SIZE_MAPPINGS', groupUuid);
        commit('REMOVE_PREBID_ID_GROUP', groupUuid);
        commit('SYNC_VERSION_NETWORKS');
        commit('SYNC_PREBID_BID_ADJUSTMENTS');
        commit('SYNC_PREBID_FIRST_LOOK_BIDDERS');
        commit('SYNC_TRAFFIC_SHAPING');
        commit('SYNC_SECONDARY_BIDDERS');
        commit('SYNC_FLEDGE_FOR_GPT_BIDDERS');

        versionValidationHelpers.validateVersionErrors(state.version, 'warning');
    },
    copyPrebidIDGroup({ commit, state }, groupUuid) {
        const selectedGroup = helpers.deepCopy(
            state.version.config.prebid.groups.find(g => g.uuid === groupUuid)
        );
        selectedGroup.uuid = uuidv4();
        selectedGroup.bidders.forEach(b => {
            b.uuid = uuidv4();
            b.copied = true;
        });
        selectedGroup.name = `copy_of_${selectedGroup.name}`;
        selectedGroup.copied = true;

        commit('COPY_PREBID_ID_GROUP', selectedGroup);
    },
    setPrebidIDGroupBidder({ commit, state, rootState }, bidderOptions) {
        const config = state.version.config;
        const bidders = rootState.general.bidders;
        const account = rootState.accounts.account;
        // hotFix to send the siteId over since sometimes the siteId is not available in the config.
        account.siteId = rootState.sites.site.site_id;
        const normalizedBidders = biddersUtil.getNormalizedBidders(
            config,
            bidders,
            account,
            bidderOptions
        );
        const selectedBidder = helpers.deepCopy(
            normalizedBidders.find(b => b.key === bidderOptions.value)
        );
        const bidder = {
            active: true,
            bidder: selectedBidder.key,
            params: [...selectedBidder.params],
            deprecated: selectedBidder.deprecated,
        };

        commit('SET_PREBID_ID_GROUP_BIDDER', { bidderOptions, bidder });
        commit('SYNC_VERSION_NETWORKS');
        commit('SYNC_PREBID_BID_ADJUSTMENTS');
        commit('SYNC_PREBID_FIRST_LOOK_BIDDERS');
        commit('SYNC_TRAFFIC_SHAPING');
    },
    addPrebidIDGroupBidder({ commit, state }, groupUuid) {
        const appNexus = helpers.deepCopy(biddersUtil.defaultAppnexusBidder);
        const bidder = {
            active: true,
            bidder: appNexus.key,
            params: [...appNexus.params],
            deprecated: appNexus.deprecated,
            uuid: uuidv4(),
        };

        commit('ADD_PREBID_ID_GROUP_BIDDER', { groupUuid, bidder });
        commit('SYNC_VERSION_NETWORKS');
        commit('SYNC_PREBID_BID_ADJUSTMENTS');
        commit('SYNC_PREBID_FIRST_LOOK_BIDDERS');
        commit('SYNC_TRAFFIC_SHAPING');

        versionValidationHelpers.validateVersionErrors(state.version, 'warning');
    },
    removePrebidIDGroupBidder({ commit, state }, bidderOptions) {
        commit('REMOVE_PREBID_ID_GROUP_BIDDER', bidderOptions);
        commit('SYNC_VERSION_NETWORKS');
        commit('SYNC_PREBID_BID_ADJUSTMENTS');
        commit('SYNC_PREBID_FIRST_LOOK_BIDDERS');
        commit('SYNC_TRAFFIC_SHAPING');
        commit('SYNC_SECONDARY_BIDDERS');
        commit('SYNC_FLEDGE_FOR_GPT_BIDDERS');

        versionValidationHelpers.validateVersionErrors(state.version, 'warning');
    },
    setPrebidIDGroupBidderStatus({ commit, state }, bidderOptions) {
        commit('SET_PREBID_ID_GROUP_BIDDER_STATUS', bidderOptions);
        commit('SYNC_PREBID_BID_ADJUSTMENTS');
        commit('SYNC_PREBID_FIRST_LOOK_BIDDERS');
        commit('SYNC_TRAFFIC_SHAPING');
        commit('SYNC_SECONDARY_BIDDERS');
        commit('SYNC_FLEDGE_FOR_GPT_BIDDERS');

        versionValidationHelpers.validateVersionErrors(state.version, 'warning');
    },
    updatePrebidIDGroupBidderParam({ commit }, paramOptions) {
        commit('UPDATE_PREBID_ID_GROUP_BIDDER_PARAM', paramOptions);
    },

    updateAmazonConfigurationField({ commit }, amazonOptions) {
        commit('UPDATE_AMAZON_CONFIGURATION_FIELD', amazonOptions);
    },

    // PREBID FLOORS
    updatePrebidFloorsFloorReportingFields({ commit }, params) {
        commit('UPDATE_PREBID_FLOORS_FLOORREPORTING_FIELDS', params);
    },

    updatePrebidFloorsSkipRateFields({ commit }, params) {
        commit('UPDATE_PREBID_FLOORS_SKIPRATE_FIELDS', params);
    },

    // fields refer to the floor fields like `device` `bidder` `size` `country`, etc.
    addPrebidFloorsField({commit}) {
        commit('ADD_PREBID_FLOORS_FIELD', '');
    },
    setPrebidFloorsFields({commit}, fields) {
        commit('SET_PREBID_FLOORS_FIELDS', fields);
    },

    copyPrebidFloorsRule({commit}, uuid) {
        commit('COPY_PREBID_FLOORS_RULE', uuid);
    },
    deletePrebidFloorsRule({commit}, uuid) {
        commit('DELETE_PREBID_FLOORS_RULE', uuid);
    },
    addPrebidFloorsRule({commit}) {
        const rule = {
            cpm: 1.00,
            values: {},
        }
        commit('ADD_PREBID_FLOORS_RULE', rule);
    },
    updatePrebidFloorsRule({commit}, options) {
        commit('UPDATE_PREBID_FLOORS_RULE', options);
    },

    updatePrebidFloorsCustom({ commit }, value) {
        commit('UPDATE_PREBID_FLOORS_CUSTOM', value);
    },

    // PREBID SERVER
    updatePrebidServerField({ commit }, options) {
        commit('UPDATE_PREBID_SERVER_FIELD', options);
    },
    updatePrebidServerDefaultVendorField({ commit }, options) {
        commit('UPDATE_PREBID_SERVER_DEFAULT_VENDOR_FIELD', options);
    },
    setPrebidServerBidder({ commit, state }, bidderKey) {
        let biddersList = helpers.deepCopy(
            state.version.config.prebidServer.bidders
        );
        const isInList = biddersList.includes(bidderKey);

        if (isInList) {
            biddersList = biddersList.filter(b => b !== bidderKey);
        } else {
            biddersList.push(bidderKey);
        }

        commit('SET_PREBID_SERVER_BIDDER', biddersList);
    },

    // PREBID BID ADJUSTMENT
    updatePrebidBidAdjustment({ commit }, adjustmentConfig) {
        commit('UPDATE_PREBID_BID_ADJUSTMENT', adjustmentConfig);
    },

    // PREBID FIRST LOOK
    updatePrebidFirstLook({ commit }, options) {
        commit('UPDATE_PREBID_FIRST_LOOK', options);
    },

    // BIDDER_ THROTTLING
    updateBidderThrottling({ commit }, options) {
        commit('UPDATE_BIDDER_THROTTLING', options);
    },

    // TRAFFIC SHAPING
    updateTrafficShaping({ commit }, options) {
        commit('UPDATE_TRAFFIC_SHAPING', options);
    },

    updateTrafficShapingRule({ commit }, options) {
        commit('UPDATE_TRAFFIC_SHAPING_RULE', options);
    },

    copyTrafficShapingRule({ commit }, uuid) {
        commit('COPY_TRAFFIC_SHAPING_RULE', uuid);
    },

    deleteTrafficShapingRule({ commit }, uuid) {
        commit('DELETE_TRAFFIC_SHAPING_RULE', uuid);
    },

    addTrafficShapingRule({commit}, bidder) {
        const rule = {
            bidder,
            operator: 'ALLOW',
            conditions: {},
        }
        commit('ADD_TRAFFIC_SHAPING_RULE', rule);
    },

};

import { authorizedAxios } from '@config/axios';

export const fetchAlerts = async () =>
    authorizedAxios.get('/api/v1/alerts');

export const fetchAlertByType = async type =>
    authorizedAxios.get(`/api/v1/alerts/${type}`);

export const saveAlertConfig = async (params) =>
    authorizedAxios.patch(`/api/v1/alerts`, params);

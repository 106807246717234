const mutations = {
    UPDATE_VERSION_ADAGIO_ANALYTICS(state, adagioAnalyticsOptions) {
        const { key, value } = adagioAnalyticsOptions;
        state.version.config.adagioAnalytics[key] = value;
    },
};

const actions = {
    updateVersionAdagioAnalytics({ commit }, adagioAnalyticsOptions) {
        commit('UPDATE_VERSION_ADAGIO_ANALYTICS', adagioAnalyticsOptions);
    },
};

const getters = {
    adagioAnalytics: ({ version }) => version.config.adagioAnalytics || {},
};

export default {
    mutations,
    actions,
    getters,
};

import regex from '@util/regex';

const getOtherUsers = (users, allUsers) => {
    const usersIds = users.map(user => user.user_id);
    return allUsers.filter(user => !usersIds.includes(user.user_id));
};

const isAccountNameValid = account => {
    return account.name && regex.alpha_num_dash_slash_space.test(account.name);
};

const hasHtmlText = html => {
    let dummyHtml = document.createElement('html');
    dummyHtml.innerHTML = html;
    return dummyHtml.textContent !== '';
};

const noSqlInHtml = html => {
    let dummyHtml = document.createElement('html');
    dummyHtml.innerHTML = html;
    return dummyHtml.textContent !== 'rf' && dummyHtml.textContent !== 'rm';
};

const areAccountNotesValid = account => {
    let valid = false;
    if (account.config && account.config.notes) {
        valid = !account.config.notes.some(
            note =>
                !note.type ||
        !note.title.trim() ||
        !note.content.trim() ||
        (note.type && !['text', 'url'].includes(note.type)) ||
        !note.title.trim() ||
        (note.title && !regex.alpha_num_dash_slash_space.test(note.title)) ||
        (note.type === 'url' &&
          note.content &&
          !regex.url.test(note.content)) ||
        (note.type === 'text' &&
          note.content &&
          (!hasHtmlText(note.content) || !noSqlInHtml(note.content)))
        );
    }
    return valid;
};

const isCustomBrandingImageValid = customImage => {
    const validFormats = ['image/jpeg', 'image/png', 'image/svg+xml'];
    return (
        validFormats.includes(customImage.type) &&
    customImage.size <= 1024 * 1024 * 5
    );
};

export default {
    getOtherUsers,
    isAccountNameValid,
    areAccountNotesValid,
    isCustomBrandingImageValid,
};

export default {
    SET_LINE_ITEM_JOBS(state, lineItemJobs) {
        state.lineItemJobs = lineItemJobs;
    },
    SET_TARGETING_JOBS(state, targetingHistoryJobs) {
        state.targetingHistoryJobs = targetingHistoryJobs;
    },
    SET_DEFAULT_BIDDERS(state, bidders) {
        state.bidders = bidders;
    },
    SET_SELECTED_BIDDER(state, bidder) {
        if (bidder.bidderIndex === -1) {
            state.bidders = state.bidders.map(b => {
                b.selected = false;
                return b;
            });
            return;
        }
        state.bidders[bidder.bidderIndex].selected = true;
    },
    SET_JOB_SITES(state, jobSites) {
        state.jobSites = jobSites;
    },
    SET_GAM_CONFIG(state, configOptions) {
        const { key, value } = configOptions;
        state.config[key] = value;
    },
    SET_ARE_PRICE_BUCKETS_VALID(state, isValid) {
        state.arePriceBucketsValid = isValid;
    },
    SET_SELECTED_SITE_ID(state, siteId) {
        state.selectedSiteId = siteId;
    },
    SET_SELECTED_BIDDERS(state, updatedBidderList) {
        state.bidders = updatedBidderList;
    },
    RESET_GAM_CONFIG(state) {
        const config = {
            creativeType: null,
            granularity: null,
            customGranularity: null,
            advertiserId: null,
            autoApprove: false,
            enableSendAllBids: true,
            sizes: [],
            placements: [],
            orderName: null,
            currency: 'USD',
            addTargeting: true,
            key: '',
            operator: 'IS',
            values: '',
            duration: 30,
            vastUrl: '',
            mediaType: 'banner',
        };

        state.selectedSiteId = null;
        state.version = null;
        state.config = config;
    },
    SET_SELECTED_JOB(state, job) {
        state.selectedJob = job;
    },
    SET_FETCH_INTERVAL(state, intervalOptions) {
        const { fn, delay } = intervalOptions;

        clearInterval(state.fetchInterval);
        state.fetchInterval = setInterval(() => {
            fn();
        }, delay);
    },
    RESET_FETCH_INTERVAL(state) {
        clearInterval(state.fetchInterval);
        state.fetchInterval = null;
    },
    SET_VERSION(state, version) {
        state.version = version;
    },
};

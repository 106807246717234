import regex from '@util/regex';
import {
    isValidCssSelector,
    isValidJavascript,
    isValidCss,
    isValidHtml,
} from '@util/validation/utils';
import vh from '@util/versionHelpers';
import store from '@store/index';

/**
 * Checks if the Layout's refresh counter value is invalid.
 *
 * @param {Object} layout - The layout configuration to validate.
 * @returns {boolean} Returns true if the refresh counter value is invalid, false otherwise.
 */
export function isLayoutRefreshCounterValueInvalid(layout) {
    if (!layout.refreshCounter) {
        return false;
    }
    // Check if refreshCounter is truthy, refreshCounterValue has length, and refreshCounterValue does not match the regex pattern.
    const hasRefreshCounterValue =
        layout.refreshCounterValue.length > 0 &&
        regex.alpha_num_dash.test(layout.refreshCounterValue);

    return !hasRefreshCounterValue;
}

/**
 * Checks if a slot's name is valid.
 *
 * @param {string} name - The slot's name to validate.
 * @param {string} uuid - The slot's UUID.
 * @returns {boolean} Returns true if the slot's name is invalid, false otherwise.
 */
function isSlotNameInvalid(name, uuid) {
    const slotNames = Object.entries(store.getters['sites/slotNames'])
        .filter(([key]) => key !== uuid)
        .map(([, value]) => value);
    return !regex.alpha_dash_comma.test(name) || slotNames.includes(name);
}

function isSlotUnitInvalid(unit, hasImperativeApi) {
    return !hasImperativeApi && (!regex.gam_ad_unit.test(unit));
}

function isSlotAttributesOptionsInvalid(slots) {
    let hasInvalidOptions = false;
    const options = ['refresh', 'eager'];

    slots.forEach(slot => {
        options.forEach(option => {
            if (option == 'refresh' && slot.options.refresh) {
                const invalidRefreshSecs =  !regex.numeric.test(slot.options.refreshSecs);
                const refreshSecsInvalidRange = parseInt(slot.options.refreshSecs, 10) < 15 || parseInt(slot.options.refreshSecs, 10) > 240;
                if (invalidRefreshSecs || refreshSecsInvalidRange) {
                    hasInvalidOptions = true;
                }
                if (!slot.options.maxRefreshes || !regex.numeric.test(slot.options.maxRefreshes)) {
                    hasInvalidOptions = true;
                }
            }
            if (option == 'eager' && !slot.options.eager) {
                if (!slot.options.lazyPixels || !regex.numeric.test(slot.options.lazyPixels)) {
                    hasInvalidOptions = true;
                }
            }
        });
    });
    return hasInvalidOptions;
}

/**
 * Checks if a slot's selector is valid.
 *
 * @param {string} selector - The slot's selector to validate.
 * @param {string} layoutKey - The layout's key.
 * @param {boolean} hasImperativeApi - Whether the imperative API is enabled.
 * @returns {boolean} Returns true if the selector is invalid, false otherwise.
 */
function isSlotSelectorInvalid(selector, layoutKey, hasImperativeApi) {
    return (
        layoutKey !== 'universal' &&
        selector &&
        !hasImperativeApi &&
        !isValidCssSelector(selector)
    );
}

/**
 * Checks if a slot's type is 'callback' and the callback is valid.
 * @param {Object} slot - The slot to validate.
 * @returns {boolean} Returns true if the callback is invalid, false otherwise.
 */
function isSlotCallbackInvalid(slot) {
    return (
        slot.type === 'callback' &&
        slot.callback &&
        !isValidJavascript(slot.callback)
    );
}

const hasDeviceWarnings = (slot, config) => {
    const isImperativeApi = config.options.imperativeApi;
    let hasMissingUnit = false;
    let hasMissingRefreshInterval = false;
    let hasMissingRefreshSecs = false;
    let hasMissingLazyPixels = false;

    for (const sizeMapping of slot.sizeMappings) {
        const { options } = sizeMapping;

        if (options.refresh && !options.refreshSecs) {
            hasMissingRefreshSecs = true;
        }

        if (options.refresh && !options.maxRefreshes) {
            hasMissingRefreshInterval = true;
        }

        if (!isImperativeApi && sizeMapping.deviceSettingsOverride && !sizeMapping.unit) {
            hasMissingUnit = true;
        }

        if (!options.eager && !options.lazyPixels) {
            hasMissingLazyPixels = true;
        }
    }

    return hasMissingUnit || hasMissingRefreshInterval || hasMissingRefreshSecs || hasMissingLazyPixels;
}

/**
 * Checks if a slot has device errors.
 *
 * @param {*} slot - The slot to validate.
 * @param {*} config - The version configuration.
 * @returns {boolean} Returns true if the slot has device errors, false otherwise.
 */
const hasDeviceErrors = (slot, config) => {
    const isImperativeApi = config.options.imperativeApi;
    let hasDuplicateDevices = false;
    let hasDefaultDevice = false;
    let hasUnitError = false;
    const devices = [];

    for (const sizeMapping of slot.sizeMappings) {
        if (devices.includes(sizeMapping.device)) {
            hasDuplicateDevices = true;
            break;
        }

        if (sizeMapping.device === config.devices.find(device => device.name === vh.defaultViewportName).uuid) {
            hasDefaultDevice = true;
        }

        if (!isImperativeApi && sizeMapping.deviceSettingsOverride && !regex.gam_ad_unit.test(sizeMapping.unit)) {
            hasUnitError = true;
        }

        devices.push(sizeMapping.device);
    }

    return hasDuplicateDevices || (hasDefaultDevice && slot.sizeMappings.length > 1) || hasUnitError;
}

/**
 * Checks if a slot is invalid.
 * @param {Object} slot - The slot to validate.
 * @param {Object} layout - The layout configuration.
 * @param {Object} config - The overall configuration.
 * @returns {boolean} Returns true if the slot is invalid, false otherwise.
 */
export function isSlotInvalid(slot, layout, config) {
    if (!config.options.hasLayouts) {
        return false;
    }
    const layoutKey = layout.key;
    const hasImperativeApi = config.options.imperativeApi;
    const deviceErrors = hasDeviceErrors(slot, config);

    return (
        isSlotNameInvalid(slot.name, slot.uuid) ||
        isSlotSelectorInvalid(slot.selector, layoutKey, hasImperativeApi) ||
        isSlotCallbackInvalid(slot) ||
        isSlotUnitInvalid(slot.unit, hasImperativeApi) ||
        isSlotAttributesOptionsInvalid(layout.slots) ||
        deviceErrors
    );
}

/**
 * Checks if a native style is invalid.
 *
 * @param {Object} style - The native style to validate.
 * @param {Object} config - The configuration.
 * @returns {boolean} Returns true if the style is invalid, false otherwise.
 */
export function isNativeStyleInvalid(style, config) {
    return [
        !style.name || isNativeStyleNameDuplicate(style, config) || !regex.alpha_num_dash.test(style.name),
        !style.titleMaxLength || !regex.numeric.test(style.titleMaxLength),
        !style.descriptionMaxLength || !regex.numeric.test(style.descriptionMaxLength),
        !style.imgMaxWidth || !regex.numeric.test(style.imgMaxWidth),
        !style.imgMaxHeight || !regex.numeric.test(style.imgMaxHeight),
        !style.css || !isValidCss(style.css),
        !style.html || !isValidHtml(style.html)
    ].some(condition => condition);
}

/**
 * Checks if a native style name is duplicated in the configuration.
 *
 * @param {Object} style - The native style to validate.
 * @param {Object} config - The configuration.
 * @returns {boolean} Returns true if the style name is duplicated, false otherwise.
 */
export function isNativeStyleNameDuplicate(style, config) {
    const styles = config.styles.styles;
    return styles.filter(s => s.name === style.name).length > 1;
}

/**
 * Checks if any of the native styles in the configuration are invalid.
 *
 * @param {Object} config - The configuration.
 * @returns {boolean} Returns true if any native style is invalid, false otherwise.
 */
export function isNativeStylesInvalid(config) {
    const styles = config.styles.styles;
    return styles.some(style => isNativeStyleInvalid(style, config));
}

/**
 * Checks if any of the device configs in any slot configuration are invalid.
 * @param {Array} layouts - The array of layouts to validate.
 * @returns {boolean} Returns true if any of the device configs are invalid, false otherwise.
 */
export function hasInvalidSizeMappings(slot, isImperativeApi) {
    let hasInvalidSizeMappings = false;
    let hasWarningSizeMappings = false;
    const devices = [];
    for (const sizeMapping of slot.sizeMappings) {
        const {
            unit,
            deviceSettingsOverride,
            sizes,
            device,
        } = sizeMapping;

        const {
            refresh,
            maxRefreshes,
            refreshSecs,
            lazyPixels,
            eager,
        } = sizeMapping.options;

        if (devices.includes(device)) {
            hasInvalidSizeMappings = true;
            break;
        }

        devices.push(device);

        if (sizes.some(size => !regex.sizes.test(size.size))) {
            hasInvalidSizeMappings = true;
            break;
        }

        if (!deviceSettingsOverride) {
            continue;
        }

        if (!isImperativeApi && !unit) {
            hasWarningSizeMappings = true;
        }

        if (!isImperativeApi && (!regex.gam_ad_unit.test(unit))) {
            hasInvalidSizeMappings = true;
        }

        if (refresh && maxRefreshes && !regex.numeric.test(maxRefreshes)) {
            hasInvalidSizeMappings = true;
        }

        if (refresh) {
            if (!refreshSecs) {
                hasWarningSizeMappings = true;
            } else {
                const isRefreshNumeric = refreshSecs && regex.numeric.test(refreshSecs);
                const refreshInValidRange = parseInt(refreshSecs, 10) >= 15 && parseInt(refreshSecs, 10) <= 240;
                if (!isRefreshNumeric || !refreshInValidRange) {
                    hasInvalidSizeMappings = true;
                }
            }
        }

        if (!eager && lazyPixels) {
            const invalidLazyPixels = lazyPixels && !regex.numeric.test(lazyPixels);
            const lazyPixelsInValidRange = parseInt(lazyPixels, 10) >= 0 && parseInt(lazyPixels, 10) <= 1000;
            if (invalidLazyPixels || !lazyPixelsInValidRange) {
                hasInvalidSizeMappings = true;
            }
        }
    }

    if (hasInvalidSizeMappings) {
        store.dispatch('sites/addErrorSlot', slot.uuid);
    }

    if (hasWarningSizeMappings) {
        store.dispatch('sites/addWarningSlot', slot.uuid);
    }

    return { hasInvalidSizeMappings, hasWarningSizeMappings };
}

export function isInvalidLayout(layout, validateSlots = true) {
    const isDefined = (key) => {
        return typeof key !== 'undefined' && key;
    }

    let isPollValid = true;
    switch (layout.poll.type) {
    case 'tick':
        const isValidInterval = isDefined(layout.poll.interval) && regex.numeric.test(layout.poll.interval); //eslint-disable-line
        if (!isValidInterval || parseInt(layout.poll.interval) < 500) {
            isPollValid = false;
        }

        break;
    case 'selector':
        if (!isDefined(layout.poll.selector) || !isValidCssSelector(layout.poll.selector)) {
            isPollValid = false;
        }

        break;
    case 'callback':
        if (!isDefined(layout.poll.callback) || !isValidJavascript(layout.poll.callback)) {
            isPollValid = false;
        }

        break;
    }

    return (
        !isPollValid ||
        isLayoutRefreshCounterValueInvalid(layout) ||
        (validateSlots && layout.slots.some(slot => isSlotInvalid(slot, layout)))
    );
}

/**
 * Checks if classes and templates are valid.
 *
 * @param {Array} layouts - The array of layouts to validate.
 * @param {Object} config - The configuration.
 * @returns {boolean} Returns true if all classes and templates are valid, false otherwise.
 */
export function isClassesAndTemplatesValid(layouts, config) {
    if (!config.options.hasLayouts) {
        return true;
    }
    const hasDuplicateLayoutKeys = layouts.some(layout =>
        layouts.filter(l => l.key === layout.key).length > 1
    );

    const hasInvalidLayout = layouts.some(isInvalidLayout);

    return !hasDuplicateLayoutKeys && !hasInvalidLayout;
}

/**
 * Checks if devices and viewports are valid.
 *
 * @param {Array} devices - The array of devices to validate.
 * @returns {boolean} Returns true if all devices and viewports are valid, false otherwise.
 */
export function isDevicesAndViewportsValid(devices) {
    const hasDuplicateDeviceNames = devices.some(device =>
        devices.filter(d => d.name === device.name).length > 1
    );

    const isInvalidDevice = device => {
        return (
            (device.name && device.name !== "All Viewports" &&
                (!regex.alpha_num_dash.test(device.name) ||
                    (device.minViewport &&
                        !regex.size.test(device.minViewport)) ||
                    (device.minViewport &&
                        parseInt(device.minViewport.split('x')[0], 10) >= 3000))
            )
        );
    };

    const hasInvalidDevice = devices.some(isInvalidDevice);

    return !hasInvalidDevice && !hasDuplicateDeviceNames;
}

/**
 * Checks if any of the devices have missing properties.
 *
 * @param {Array} devices - The devices to validate.
 * @returns {boolean} Returns true if any of the devices have missing properties, false otherwise.
 */
export function hasDevicesViewportsWarnings(devices) {
    const invalidDevices = devices.filter(
        device => !device.name || !device.minViewport
    );
    return invalidDevices.length > 0;
}

/**
 * Checks if any of the layouts have warnings.
 *
 * @param {Object} layouts - The layouts to validate.
 * @param {Object} config - The main configuration object.
 * @returns {boolean} Returns true if any of the layouts have missing required properties, false otherwise.
 */
export function hasClassesAndTemplatesWarnings(layouts) {
    const invalidLayouts = layouts.filter(
        layout =>
            // layout validation
            !layout.key ||
            (layout.poll.type && !layout.poll.interval) ||
            (layout.poll.type === 'selector' && !layout.poll.selector) ||
            (layout.poll.type === 'callback' && !layout.poll.callback)
    );

    return invalidLayouts.length > 0;
}

/**
 * Checks for ad slot native style warnings.
 *
 * @param {Object} slot - The slot to validate.
 * @param {Object} config - The configuration object.
 * @returns {boolean} Returns true if the slot has warnings, false otherwise.
 */
export function hasSlotNativeStyleWarnings(slot, config) {
    if (!config.options.hasPrebid || !config.options.hasNativeStyles) {
        return false;
    }

    let hasInvalidMappings = false;
    const nativeBidders = ['pbrtb_native'];

    slot.sizeMappings.forEach(mapping => {
        // if we already found an invalid mapping then we can stop checking;
        if (!('nativeStyle' in mapping) || hasInvalidMappings) {
            return;
        }

        const nativeStyle = config.styles.styles.find(style => style.uuid === mapping.nativeStyle);

        if (nativeStyle) {
            const allMappingGroups = mapping.groups.reduce((fullGroups, mappingGroup) => {
                const group = config.prebid.groups.find(configGroup => configGroup.uuid === mappingGroup.uuid);

                if (group) {
                    fullGroups.push(group);
                }

                return fullGroups;
            }, []);

            const nativeGroups = allMappingGroups.filter(mappingGroup => {
                const nativeBidder = mappingGroup.bidders.find(bidder => {
                    return nativeBidders.includes(bidder.bidder);
                });

                return typeof nativeBidder !== 'undefined';
            });

            if (nativeGroups.length === 0) {
                hasInvalidMappings = true;
            }
        }
    });

    return hasInvalidMappings;
}

/**
 * Checks if layout has missing refresh counter values
 *
 * @param {Object} layout - The layout object to validate.
 * @returns {boolean} - Returns true if the layout has warnings, false otherwise.
 */
export function hasLayoutRefreshCounterValueWarnings(layout) {
    const hasInvalidRefreshCounterValue = !(layout.refreshCounterValue || layout.refreshCounter === false)
    return hasInvalidRefreshCounterValue;
}

/**
 * Checks if slot state should raise warnings.
 *
 * @param {Object} slot - The slot to validate.
 * @param {Object} layout - The layout configuration.
 * @param {Object} config - The main configuration object.
 * @returns {boolean} - Returns true if the slot has warnings, false otherwise.
 */
export function hasSlotWarnings(slot, layout, config) {
    const hasInvalidCallback = layout.key !== 'universal' &&
        slot.type === 'callback' && !slot.callback;
    const hasInvalidUniversalLayout = !config.options.imperativeApi && !slot.unit;
    const hasInvalidSizeMappings = !!(slot.sizeMappings.length === 0 ||
        slot.sizeMappings.find(sizeMapping => !sizeMapping.device));
    const opts = slot.options;
    const hasInvalidRefresh = (opts.refresh && !opts.refreshType) ||
        (opts.optimeraSmartRefresh &&
            (!opts.optimeraMinTimeInView || !opts.optimeraMinTimeOnPage));
    const hasInvalidUnit = isSlotUnitInvalid(slot.unit, config.options.imperativeApi);
    const hasInvalidAttributesOptions = isSlotAttributesOptionsInvalid([slot]);
    const invalidSlotName = isSlotNameInvalid(slot.name, slot.uuid);
    const deviceWarnings = hasDeviceWarnings(slot, config);

    return (
        !slot.name ||
        (layout.key !== 'universal' && !slot.where) ||
        hasInvalidCallback ||
        hasInvalidUniversalLayout ||
        slot.targeting.find(targeting => !targeting.key) ||
        hasInvalidSizeMappings ||
        hasInvalidRefresh ||
        hasInvalidUnit ||
        hasInvalidAttributesOptions ||
        invalidSlotName ||
        deviceWarnings
    );
}

/**
 * Checks if individual native style has missing properties.
 *
 * @param {Object} style - The style object to validate.
 * @returns {boolean} Returns true if the style has missing properties, false otherwise.
 */
export function hasNativeStyleWarnings(style) {
    return (
        !style.name ||
        !style.titleMaxLength ||
        !style.descriptionMaxLength ||
        !style.imgMaxWidth ||
        !style.imgMaxHeight ||
        !style.css ||
        !style.html
    );
}

/**
 * Checks if any of the native styles have missing properties.
 *
 * @param {Object} config - The configuration object.
 * @returns {boolean} Returns true if the configuration has native style warnings, false otherwise.
 */
export function hasNativeStylesWarnings(config) {
    let stylesWithWarnings = [];
    const styles = config.styles.styles;
    stylesWithWarnings = styles
        .map(style => hasNativeStyleWarnings(style))
        .filter(item => item === true);
    return stylesWithWarnings.length > 0 || styles.length === 0;
}

/* eslint-disable */

const mutations = {
    UPDATE_VERSION_GROWTH_CODE(state, growthCodeOptions) {
        const { key, value } = growthCodeOptions;
        state.version.config.growthCode[key] = value;
    },
};

const actions = {
    updateVersionGrowthCode({ commit }, growthCodeOptions) {
        commit('UPDATE_VERSION_GROWTH_CODE', growthCodeOptions);
    },
};

const getters = {
    growthCode: ({ version }) => version.config.growthCode || {},
};

export default {
    mutations,
    actions,
    getters,
};

import axios from 'axios';
import { resourceUrl } from '../vars';

import {
    authorizationInterceptorRequestSuccess,
    authorizationInterceptorResponseError,
    accountInterceptorRequestSuccess,
    requestLoadingInterceptor,
    responseSuccessLoadingInterceptor,
    responseErrorLoadingInterceptor,
} from './httpInterceptor';

export const mainAxios = axios.create({
    baseURL: resourceUrl,
});

export const authorizedAxios = axios.create({
    baseURL: resourceUrl,
});

//Request interceptors
// authorization handling
authorizedAxios.interceptors.request.use(
    authorizationInterceptorRequestSuccess,
    err => err
);

// loading handling
authorizedAxios.interceptors.request.use(requestLoadingInterceptor, err => err);

// account handling
authorizedAxios.interceptors.request.use(
    accountInterceptorRequestSuccess,
    err => err
);

//Response interceptors
authorizedAxios.interceptors.response.use(
    responseSuccessLoadingInterceptor,
    responseErrorLoadingInterceptor
);

authorizedAxios.interceptors.response.use(
    res => res.data,
    authorizationInterceptorResponseError
);

import { authorizedAxios } from '@config/axios';

export const getAccounts = params =>
    authorizedAxios.get('/api/v1/accounts', { params });
export const getAccountById = accountId =>
    authorizedAxios.get(`/api/v1/accounts/${accountId}`);
export const createAccount = params =>
    authorizedAxios.post('/api/v1/accounts/', params);
export const updateAccount = (accountId, params) =>
    authorizedAxios.put(`/api/v1/accounts/${accountId}`, params);
export const deleteAccountById = accountId =>
    authorizedAxios.delete(`/api/v1/accounts/${accountId}`);
export const reactivateAccountById = accountId =>
    authorizedAxios.put(`/api/v1/accounts/${accountId}/reactivate`);
export const moveAccountById = (accountId, customerId) =>
    authorizedAxios.put(`/api/v1/accounts/${accountId}/move`, { customerId });
export const moveSiteToAccount = (accountId, siteId) =>
    authorizedAxios.put(`/api/v1/accounts/${accountId}/move-site`, { siteId });
export const getEmailSettingsForUserAccount = (userId, params) =>
    authorizedAxios.get(`/api/v1/accounts/users/${userId}/all-accounts`, { params });
export const updateEmailSettingsForUserAccount = (params) =>
    authorizedAxios.patch(`/api/v1/accounts/system-usage-emails`, params);
export const  updateEmailSettingsAsAdmin = (userId, params) =>
    authorizedAxios.patch(`/api/v1/accounts/users/${userId}/system-usage-emails`, params);
export const fetchEmailSettingsForAccountUsers = (accountId, params) =>
    authorizedAxios.get(`/api/v1/accounts/${accountId}/users`, { params });
export const updateEmailSettingsForAccountUsers = (accountId, params) =>
    authorizedAxios.patch(`/api/v1/accounts/${accountId}/system-usage-emails`, params);

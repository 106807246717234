const mutations = {
    UPDATE_VERSION_SHARED_ID(state, { key, value }) {
        state.version.config.sharedId[key] = value;
    },
};

const actions = {
    updateVersionSharedId({ commit }, options) {
        commit('UPDATE_VERSION_SHARED_ID', options);
    },
};

const getters = {
    sharedId: ({ version }) => version.config.sharedId || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_ONE_COUNT(state, oneCountOptions) {
        const { key, value } = oneCountOptions;
        state.version.config.oneCount[key] = value;
    },
};

const actions = {
    updateVersionOneCount({ commit }, oneCountOptions) {
        commit('UPDATE_VERSION_ONE_COUNT', oneCountOptions);
    },
};

const getters = {
    oneCount: ({ version }) => version.config.oneCount || {},
};

export default {
    mutations,
    actions,
    getters,
};

<template>
  <div id="app">
    <!-- <build-info-display v-if="shouldDisplayBuildInfo" /> -->
    <router-view />
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';

import '../public/style.css';
// import BuildInfoDisplay from './components/BuildInfoDisplay.vue';

export default {
  name: 'App',
  //   components: { BuildInfoDisplay },
  computed: {
    shouldDisplayBuildInfo() {
      //   if (!process || !process.env) {
      //     return false;
      //   }

      //   const env = process.env.NODE_ENV.toLowerCase();
      //   const isProductionLike = ['production', 'stage', 'prod'].includes(env);
      //   const isDev = ['development'].includes(env);

      //   if (isProductionLike || !isDev) {
      //     return false;
      //   }

      //   return true;

      //   Disabling while we get the dev envs working correctly.
      return false;
    },
  },
};
</script>

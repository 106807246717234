/* eslint-disable */

const mutations = {
    UPDATE_VERSION_MEDIA_FILTER(state, mediaFilterOptions) {
        const { key, value } = mediaFilterOptions;
        state.version.config.mediaFilter[key] = value;
    },
};

const actions = {
    updateVersionMediaFilter({ commit }, mediaFilterOptions) {
        commit('UPDATE_VERSION_MEDIA_FILTER', mediaFilterOptions);
    },
};

const getters = {
    mediaFilter: ({ version }) => version.config.mediaFilter || {},
};

export default {
    mutations,
    actions,
    getters,
};

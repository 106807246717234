const mutations = {
    UPDATE_VERSION_DIGITAL_GARAGE(state, options) {
        const { key, value } = options;
        state.version.config.digitalGarage[key] = value;
    },
};

const actions = {
    updateVersionDigitalGarage({ commit }, options) {
        commit('UPDATE_VERSION_DIGITAL_GARAGE', options);
    },
};

const getters = {
    digitalGarage: ({ version }) => version.config.digitalGarage || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    fetchUsers,
    createUser,
    deleteUser,
    getUserById,
    updateUser,
    inviteUser,
    reactivateUser,
    resendInvite,
    cancelInvite,
    updateUserRole,
    activateUser,
} from '@api/users';

const state = {
    users: [],
    user: {},
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    REMOVE_ACCOUNT_FROM_USER(state, accountId) {
        state.user.accounts = state.user.accounts.filter(
            a => a.account_id !== accountId
        );
    },
};

const actions = {
    async getUsers({ commit, rootGetters }, params) {
        try {
            const { data, metadata } = await fetchUsers({
                ...rootGetters.queryParams,
                ...params,
            });
            commit('SET_USERS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },
    async getUserById({ commit }, userId) {
        try {
            const { user } = await getUserById(userId);
            commit('SET_USER', user);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async createUser(_, user) {
        const params = {
            name: user.name,
            email: user.email,
            password: user.password,
        };

        try {
            await createUser(params);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async updateUser({ dispatch, rootState }, user) {
        try {
            await updateUser(user.user_id, user);
            const currentUserId = rootState.authentication.userInformation.id;
            if (user.user_id === currentUserId) {
                dispatch('getUserInformation', '', { root: true });
            }
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async deleteUser(_, userId) {
        try {
            await deleteUser(userId);
        } catch (e) {
            console.log(e);
        }
    },
    async reactivateUser(_, userId) {
        try {
            await reactivateUser(userId);
        } catch (e) {
            console.log(e);
        }
    },
    async removeAccountFromUser({ commit }, accountId) {
        commit('REMOVE_ACCOUNT_FROM_USER', accountId);
    },
    async inviteUser(_, user) {
        try {
            await inviteUser(user);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async resendInvite({ dispatch }, userId) {
        try {
            await resendInvite(userId);
            dispatch('setAlertMessage', { message: 'Invite sent.' }, { root: true });
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async activateUser({ dispatch }, {userId, password}) {
        console.log('activateUser', userId, password)
        try {
            await activateUser(userId, password);
            dispatch(
                'setAlertMessage', { message: 'User activated.' }, { root: true }
            );
            dispatch('getUsers', { status: 'pending' });
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage', { type: 'error', message: e.error }, { root: true }
            );
        }
    },
    async cancelInvite({ dispatch }, userId) {
        try {
            await cancelInvite(userId);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: 'Invitation canceled.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
    async updateUserRole({ dispatch }, options) {
        try {
            const { userId, role } = options;
            await updateUserRole(userId, role);
            dispatch(
                'setAlertMessage',
                { message: 'Successfully updated user role.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            dispatch(
                'setAlertMessage',
                { type: 'error', message: e.error },
                { root: true }
            );
        }
    },
};

const getters = {
    users: ({ users }) => users,
    user: ({ user }) => user,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

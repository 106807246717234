import { getSiteVersions, getDeployments, updateSiteDomain } from '@api/sites';
import { copyVersion } from '@api/versions';

const state = {
    versions: [],
    deployments: [],
    fetchInterval: null,
};

const mutations = {
    SET_VERSIONS(state, siteVersions) {
        state.versions = siteVersions;
    },
    SET_DEPLOYMENTS(state, deployments) {
        state.deployments = deployments;
    },
    SET_FETCH_INTERVAL(state, intervalOptions) {
        const { fn, delay } = intervalOptions;

        clearInterval(state.fetchInterval);
        state.fetchInterval = setInterval(() => {
            fn();
        }, delay);
    },
    RESET_FETCH_INTERVAL(state) {
        clearInterval(state.fetchInterval);
        state.fetchInterval = null;
    },
    CLEAR_SITE_DATA(state) {
        state.versions = [];
        state.deployments = [];
    },
};

const actions = {
    async getSiteVersions({ commit, rootGetters }, siteId) {
        try {
            const { data, metadata } = await getSiteVersions(
                siteId,
                rootGetters.queryParams
            );
            commit('SET_VERSIONS', data);
            return metadata;
        } catch (e) {
            console.log('error ', e);
        }
    },

    async getDeployments({ commit, rootGetters }, siteId) {
        try {
            const { data, metadata } = await getDeployments(
                siteId,
                rootGetters.queryParams
            );
            commit('SET_DEPLOYMENTS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log('error ', e);
        }
    },

    async copyVersion(_, versionId) {
        try {
            await copyVersion(versionId);
        } catch (e) {
            console.log('error ', e);
        }
    },

    async updateSiteDomain({ dispatch }, site) {
        try {
            await updateSiteDomain(site.site_id, {
                domain: site.domain,
                systemDomain: site.system_domain,
            });
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { message: e.error, type: 'error' },
                { root: true }
            );
        }
    },

    setFetchInterval({ commit }, intervalOptions) {
        commit('SET_FETCH_INTERVAL', intervalOptions);
    },

    resetFetchInterval({ commit }) {
        commit('RESET_FETCH_INTERVAL');
    },

    clearSiteData({ commit }) {
        commit('CLEAR_SITE_DATA');
    },
};

const getters = {
    versions: ({ versions }) => versions,
    deployments: ({ deployments }) => deployments,
};

export default {
    state,
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_FABRICK_ID(state, fabrickIdOptions) {
        const { key, value } = fabrickIdOptions;
        state.version.config.fabrickId[key] = value;
    },
};

const actions = {
    updateVersionFabrickId({ commit }, fabrickIdOptions) {
        commit('UPDATE_VERSION_FABRICK_ID', fabrickIdOptions);
    },
};

const getters = {
    fabrickId: ({ version }) => version.config.fabrickId || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_33ACROSS_ID(state, { key, value }) {
        state.version.config.ttAcrossId[key] = value;
    },
};

const actions = {
    updateVersion33AcrossId({ commit }, options) {
        commit('UPDATE_VERSION_33ACROSS_ID', options);
    },
};

const getters = {
    ttAcrossId: ({ version }) => version.config.ttAcrossId || {},
};

export default {
    mutations,
    actions,
    getters,
};

import regex from '@util/regex';

/**
 * Checks if an Amazon configuration is valid.
 *
 * @param {Object} amazon - The Amazon configuration object to validate.
 * @returns {boolean} Returns true if the configuration has a valid pubId, false otherwise.
 */
export function isAmazonValid(amazon) {
    if (amazon.pubId && !regex.alpha_num_dash.test(amazon.pubId)) {
        return false;
    }

    return true;
}

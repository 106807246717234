import regex from '@util/regex';
import store from '../../store';

/**
 * Checks if a SupplyChain object (schain) is valid.
 *
 * @param {Object} schain - The SupplyChain object to validate.
 * @param {Object} config - Configuration object (for checking duplicate schain names).
 * @returns {boolean} Returns true if the schain is valid, false otherwise.
 */
export function isSchainValid(schain, config) {
    const { schainName, sellerId, sellerDomain, publisherDomain } = schain;

    const hasInvalidSchain =
        (schainName && !regex.alpha_num_dash_underscore_space.test(schainName)) ||
        isSchainNameDuplicate(schain, config) ||
        (sellerId && !regex.alpha_num_dash.test(sellerId)) ||
        (sellerDomain && !regex.domain.test(sellerDomain)) ||
        (publisherDomain && !regex.domain.test(publisherDomain));

    return !hasInvalidSchain;
}

/**
 * Checks if the schainName in the SupplyChain object (schain) is a duplicate.
 *
 * @param {Object} schain - The SupplyChain object to check for a duplicate schainName.
 * @param {Object} config - Configuration object containing schains for comparison.
 * @returns {boolean} Returns true if the schainName is a duplicate, false otherwise.
 */
export function isSchainNameDuplicate(schain, config) {
    if (!config || !config.schains) {
        return false;
    }

    const duplicateCount = config.schains.filter(s => s.schainName === schain.schainName).length;
    return duplicateCount > 1;
}

/**
 * Checks if any of the schains in the configuration are invalid.
 *
 * @param {Object} config - Configuration object containing schains for validation.
 * @returns {boolean} Returns true if there are invalid schains, false otherwise.
 */
export function isSchainsInvalid(config) {
    if (!config || !config.schains) {
        return false;
    }

    return config.schains.some(schain => !isSchainValid(schain, config));
}

/**
 * Checks if authorized domains are valid.
 *
 * @param {Array} authorizedDomains - The list of authorized domains to validate.
 * @returns {boolean} Returns true if all domains are valid and there are no duplicates, false otherwise.
 */
export function areAuthorizedDomainsValid(authorizedDomains) {
    const allDomainsValid = authorizedDomains.every(
        domain => regex.domain.test(domain) || regex.url.test(domain)
    );

    const noDuplicateDomains = new Set(authorizedDomains).size === authorizedDomains.length;

    return allDomainsValid && noDuplicateDomains;
}

/**
 * Checks if the individual schain has missing required properties.
 *
 * @param {Object} schain - The schain object to validate.
 * @returns {boolean} Returns true if the schain has missing properties, false otherwise.
 */
export function hasSchainWarnings(schain) {
    return (
        !schain.schainName ||
        !schain.sellerId ||
        !schain.sellerDomain ||
        !schain.publisherDomain
    );
}

/**
 * Checks if any of the schains have missing required properties.
 *
 * @param {Object} config - The main configuration object.
 * @returns {boolean} Returns true if any of the schains have missing properties, false otherwise.
 */
export function hasSchainsWarnings(config) {
    let schainsWithWarnings = [];
    const schains = config.schains;
    if (schains.length === 0) return true;
    schainsWithWarnings = schains
        .map(schain => hasSchainWarnings(schain))
        .filter(item => item === true);
    return schainsWithWarnings.length > 0;
}

/**
 * Checks if there are networks without owners.
 *
 * @param {Object} config - The version's configuration object.
 * @param {Object} config.options - The version's configuration options.
 */
export function validateAccountOwnership(config) {
    let hasAmazonWarnings = false;
    let hasPrebidWarnings = false;
    let hasNetworksWithoutOwner = false;

    if (config.options.hasAmazon && config.options.hasAmazonSchain) {
        const amazonNetwork = config.networks.find(n => n.network === 'amazon');
        hasAmazonWarnings = !amazonNetwork.owner;
    }

    if (config.options.hasPrebid && config.options.hasPrebidSchain) {
        const nonAmazonAdxNetworksWithEmptyOwners = config.networks.filter(
            n => n.network !== 'amazon' && n.network !== 'adX' && !n.owner
        );
        hasPrebidWarnings = nonAmazonAdxNetworksWithEmptyOwners.length > 0;

        const schainIds = config.schains.map(schain => schain.uuid);

        hasNetworksWithoutOwner = config.networks.filter(network => network.owner !== 'Owned & Operated' && network.network !== 'adX' && network.network !== 'amazon' && !schainIds.includes(network.owner)).length > 0;
    }

    if (hasAmazonWarnings || hasNetworksWithoutOwner) {
        store.dispatch('sites/addWarningSubtab', 'account-ownership');
    }

    if (hasPrebidWarnings || hasNetworksWithoutOwner) {
        store.dispatch('sites/addWarningSubtab', 'account-ownership');
    }

    // if (hasNetworksWithoutOwner) store.dispatch('sites/addWarningSubtab', 'account-ownership');

    if (!hasAmazonWarnings && !hasPrebidWarnings && !hasNetworksWithoutOwner) {
        store.dispatch('sites/removeWarningSubtab', 'account-ownership');
    }
}

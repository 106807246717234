const mutations = {
    UPDATE_VERSION_OUTBRAIN(state, outbrainOptions) {
        const { key, value } = outbrainOptions;
        state.version.config.outbrain[key] = value;
    },
};

const actions = {
    updateVersionOutbrain({ commit }, outbrainOptions) {
        commit('UPDATE_VERSION_OUTBRAIN', outbrainOptions);
    },
};

const getters = {
    outbrain: ({ version }) => version.config.outbrain || {},
};

export default {
    mutations,
    actions,
    getters,
};

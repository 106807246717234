import helpers from '@util/helpers';

const mutations = {
    UPDATE_VERSION_FLEDGE_FOR_GPT(state, options) {
        const { key, value } = options;
        state.version.config.fledgeForGpt[key] = value;
    },
    SYNC_FLEDGE_FOR_GPT_BIDDERS(state) {
        const allBidders = state.version.config.prebid.groups.reduce(
            (acc, group) => {
                const bidders = group.bidders
                    .filter(b => b.active)
                    .map(b => b.bidder);
                return [...acc, ...bidders];
            },
            []
        );
        const fledgeForGptBidders = state.version.config.fledgeForGpt.bidders.filter(b => allBidders.includes(b));
        state.version.config.fledgeForGpt.bidders = fledgeForGptBidders;
    }
};

const actions = {
    updateVersionFledgeForGpt({ commit }, options) {
        commit('UPDATE_VERSION_FLEDGE_FOR_GPT', options);
    },
    setFledgeForGptBidder({ commit, state }, bidderKey) {
        let biddersList = helpers.deepCopy(
            state.version.config.fledgeForGpt.bidders
        );
        const isInList = biddersList.includes(bidderKey);

        if (isInList) {
            biddersList = biddersList.filter(b => b !== bidderKey);
        } else {
            biddersList.push(bidderKey);
        }

        commit('UPDATE_VERSION_FLEDGE_FOR_GPT', {
            key: 'bidders',
            value: biddersList,
        });
    },
};

const getters = {
    fledgeForGpt: ({ version }) => version.config.fledgeForGpt || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_UNIFIEDID2(state, unifiedId2Options) {
        const { key, value } = unifiedId2Options;
        state.version.config.unifiedId2[key] = value;
    },
};

const actions = {
    updateVersionUnifiedId2({ commit }, unifiedId2Options) {
        commit('UPDATE_VERSION_UNIFIEDID2', unifiedId2Options);
    },
};

const getters = {
    unifiedId2: ({ version }) => version.config.unifiedId2 || {},
};

export default {
    mutations,
    actions,
    getters,
};

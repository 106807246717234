const mutations = {
    UPDATE_VERSION_PARRABLE(state, parrableOptions) {
        const { key, value } = parrableOptions;
        state.version.config.parrable[key] = value;
    },
};

const actions = {
    updateVersionParrable({ commit }, parrableOptions) {
        commit('UPDATE_VERSION_PARRABLE', parrableOptions);
    },
};

const getters = {
    parrable: ({ version }) => version.config.parrable || {},
};

export default {
    mutations,
    actions,
    getters,
};

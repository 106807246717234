import {
    getAccounts,
    getAccountById,
    createAccount,
    updateAccount,
    deleteAccountById,
    reactivateAccountById,
    getEmailSettingsForUserAccount,
    updateEmailSettingsForUserAccount,
    updateEmailSettingsAsAdmin,
    fetchEmailSettingsForAccountUsers,
    updateEmailSettingsForAccountUsers,
    moveSiteToAccount,
} from '@api/accounts';
import { fetchUsers } from '@api/users';
import helpers from '@util/accountsHelpers';
import Role from '@enums/role';

const state = {
    accounts: [],
    account: {},
    emailSettings: [],
    accountUsersEmailSettings: [],
};

const mutations = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
    },
    SET_ACCOUNT(state, account) {
        state.account = account;
    },
    ADD_USER_TO_ACCOUNT(state, user) {
        const userIndex = state.account.otherUsers.findIndex(
            u => u.user_id === user.user_id
        );
        if (userIndex !== -1) {
            state.account.users.push(user);
            state.account.otherUsers.splice(userIndex, 1);
        }
    },
    REMOVE_USER_FROM_ACCOUNT(state, user) {
        const userIndex = state.account.users.findIndex(
            u => u.user_id === user.user_id
        );
        if (userIndex !== -1) {
            state.account.users.splice(userIndex, 1);
            state.account.otherUsers.push(user);
        }
    },
    ADD_NOTE(state, note) {
        state.account.config.notes.push(note);
    },
    REMOVE_NOTE(state, note) {
        const updatedNotesList = state.account.config.notes.filter(
            n => n.uuid !== note.uuid
        );
        state.account.config.notes = updatedNotesList;
    },
    UPLOAD_CUSTOM_BRANDING_IMAGE(state, image) {
        state.account.custom_branding_image = image;
    },
    SET_EMAIL_SETTINGS_FOR_ACCOUNTS(state, emailSettings) {
        state.emailSettings = emailSettings.map(settings => ({
            account_id: settings.account_id,
            name: settings.account.name,
            user_id: settings.user_id,
            system_usage_email_set: settings.system_usage_email_set,
        }));
    },
    UPDATE_EMAIL_SETTINGS_FOR_ACCOUNTS(state, account_id) {
        state.emailSettings = state.emailSettings.map(settings => {
            if (settings.account_id === account_id) {
                return ({...settings, system_usage_email_set: !settings.system_usage_email_set});
            }
            return settings;
        });
    },

    SET_EMAIL_SETTINGS_FOR_ACCOUNT_USERS(state, accountUsersEmailSettings) {
        state.accountUsersEmailSettings = accountUsersEmailSettings.map(settings => ({
            account_id: settings.account_id,
            name: settings.user.name,
            user_id: settings.user_id,
            email: settings.user.email,
            system_usage_email_set: settings.system_usage_email_set,
        }));
    },
    UPDATE_EMAIL_SETTINGS_FOR_ACCOUNT_USER(state, {userId, accountId}) {
        state.accountUsersEmailSettings = state.accountUsersEmailSettings.map(settings => {
            if (settings.user_id === userId && settings.account_id === accountId) {
                return ({...settings, system_usage_email_set: !settings.system_usage_email_set});
            }
            return settings;
        });
    },

};

const actions = {
    async getAccounts({ commit, rootGetters }, params) {
        try {
            const { data, metadata } = await getAccounts({
                ...rootGetters.queryParams,
                ...params,
            });
            commit('SET_ACCOUNTS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async getAllAccounts({ commit }) {
        try {
            const { data } = await getAccounts();
            commit('SET_ACCOUNTS', data);
        } catch (e) {
            console.log(e);
        }
    },

    async getAccountById({ commit, rootGetters }, accountId) {
        try {
            const { account } = await getAccountById(accountId);
            const userRole = rootGetters.userInformation.role;
            if (userRole === Role.SYSTEM_ADMIN) {
                const { data } = await fetchUsers();
                account.otherUsers = helpers.getOtherUsers(account.users, data);
            }
            commit('SET_ACCOUNT', account);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },

    async createAccount({ commit }, params) {
        try {
            const account = await createAccount(params);
            commit('SET_ACCOUNT', account);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },

    async updateAccount({ dispatch }, account) {
        try {
            let formData = new FormData();
            formData.append('name', account.name || '');
            account.users.forEach(user => {
                formData.append('users[]', user);
            });
            formData.append('config', JSON.stringify(account.config));
            formData.append(
                'custom_branding_name',
                account.custom_branding_name || ''
            );
            formData.append(
                'custom_branding_image',
                account.custom_branding_image || ''
            );

            await updateAccount(account.account_id, formData);
            dispatch('getUserInformation', '', { root: true });
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.', limitTo: 'users' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
            throw e;
        }
    },

    async deleteAccountById({ dispatch, rootState }, accountId) {
        try {
            await deleteAccountById(accountId);
            const currentUserAccountIds =
        rootState.authentication.userInformation.accounts.map(
            account => account.account_id
        );
            if (currentUserAccountIds.includes(accountId)) {
                dispatch('getUserInformation', '', { root: true });
            }
        } catch (e) {
            console.log(e);
        }
    },

    async reactivateAccountById({ dispatch, rootState }, accountId) {
        try {
            await reactivateAccountById(accountId);
            const currentUserAccountIds =
        rootState.authentication.userInformation.accounts.map(
            account => account.account_id
        );
            if (currentUserAccountIds.includes(accountId)) {
                dispatch('getUserInformation', '', { root: true });
            }
        } catch (e) {
            console.log(e);
        }
    },

    async addUserToAccount({ commit }, user) {
        try {
            commit('ADD_USER_TO_ACCOUNT', user);
        } catch (e) {
            console.log(e);
        }
    },

    async removeUserFromAccount({ commit }, user) {
        try {
            commit('REMOVE_USER_FROM_ACCOUNT', user);
        } catch (e) {
            console.log(e);
        }
    },

    async addNote({ commit }, note) {
        try {
            commit('ADD_NOTE', note);
        } catch (e) {
            console.log(e);
        }
    },

    async removeNote({ commit }, note) {
        try {
            commit('REMOVE_NOTE', note);
        } catch (e) {
            console.log(e);
        }
    },

    uploadCustomBrandingImage({ commit }, image) {
        try {
            commit('UPLOAD_CUSTOM_BRANDING_IMAGE', image);
        } catch (e) {
            console.log(e);
        }
    },
    async getEmailSettings({ commit, rootGetters }, { userId }) {
        try {
            const {accounts}  = await getEmailSettingsForUserAccount(userId, {
                ...rootGetters.queryParams,
            });
            commit('SET_EMAIL_SETTINGS_FOR_ACCOUNTS', accounts);
        } catch (e) {
            console.log(e);
        }
    },
    async updateEmailSettings({ commit }, account_id) {
        commit('UPDATE_EMAIL_SETTINGS_FOR_ACCOUNTS', account_id);
    },
    async saveEmailSettings({ state, dispatch }, { userId, isAdmin }) {
        try {
            const payload = {emailSettings: state.emailSettings.map(settings => ({
                accountId: settings.account_id,
                getEmails: settings.system_usage_email_set,
            }))};
            if (isAdmin) {
                await updateEmailSettingsAsAdmin(userId, payload);
            }  else {
                await updateEmailSettingsForUserAccount(payload);
            }
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.', limitTo: 'notifications' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
        }
    },

    async getEmailSettingsForAccountUsers({ commit, rootGetters }, { accountId }) {
        try {
            const { users }  = await fetchEmailSettingsForAccountUsers( accountId,{
                ...rootGetters.queryParams,
            });
            commit('SET_EMAIL_SETTINGS_FOR_ACCOUNT_USERS', users);
        } catch (e) {
            console.log(e);
        }
    },

    async updateEmailSettingsForAccountUser({ commit }, { userId, accountId} ) {
        commit('UPDATE_EMAIL_SETTINGS_FOR_ACCOUNT_USER', { userId, accountId });
    },

    async saveEmailSettingsForAccountUsers({ state, dispatch }, { accountId }) {
        try {
            const payload = {emailSettings: state.accountUsersEmailSettings.map(settings => ({
                userId: settings.user_id,
                getEmails: settings.system_usage_email_set,
            }))};
            await updateEmailSettingsForAccountUsers(accountId, payload);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.', limitTo: 'account-notifications' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
        }
    },

    async moveSiteAccount({ dispatch }, { accountId, siteId }) {
        try {
            await moveSiteToAccount(accountId, siteId);
            dispatch(
                'setAlertMessage',
                { message: 'Site has been moved to the account.' },
                { root: true }
            );
        } catch (e) {
            console.log(e);
        }
    },

};

const getters = {
    accounts: ({ accounts }) => accounts,
    account: ({ account }) => account,
    emailSettings: ({ emailSettings }) => emailSettings,
    accountUsersEmailSettings: ({ accountUsersEmailSettings }) => accountUsersEmailSettings,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

const mutations = {
    UPDATE_VERSION_HADRON(state, { key, value }) {
        state.version.config.hadron[key] = value;
    },
};

const actions = {
    updateVersionHadron({ commit }, options) {
        commit('UPDATE_VERSION_HADRON', options);
    },
};

const getters = {
    hadron: ({ version }) => version.config.hadron || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    fetchPrebidIds,
    newIdUpload,
    fetchPrebidIdUpload,
    rescanIdUpload,
    confirmUpload,
    removeIdUpload,
} from '@api/idUploads';

function checkEligibility(data) {
    let eligible = [];
    let ineligible = data.ineligibleSites;
    data.eligibleSites.forEach((site) => {
        if (site.latestVersion.config.options.isTestMode) {
            ineligible.push(site);
        } else {
            eligible.push(site);
        }
    });
    return { ...data, eligibleSites: eligible, ineligibleSites: ineligible };
}

function checkIfFileEmpty(data) {
    if (data.eligibleSites.length === 0 && data.ineligibleSites.length === 0) {
        return true;
    }
    return false;
}

export default {
    async loadUploadedPrebidIds({ commit, rootGetters }) {
        try {
            const { data, metadata } = await fetchPrebidIds(rootGetters.queryParams);
            commit('SET_UPLOADED_PREBID_IDS', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async addNewIdUpload({ commit }, { body, accountId }) {
        try {
            const data = await newIdUpload(body, accountId);
            const isFileEmpty = checkIfFileEmpty(data);
            if (!isFileEmpty) {
                const updatedData = checkEligibility(data);
                commit('SET_ERROR', null);
                commit('SET_UPLOADED_FILE', updatedData);
                commit('SET_CURRENT_STEP', 1);
            } else {
                commit(
                    'SET_ERROR',
                    'The file format is valid but the content isn’t (either invalid or empty). Xlsx file does not contain valid data. Please review the content and upload the file again.'
                );
            }
        } catch (e) {
            console.log(e);
        }
    },

    async getPrebidIdUpload({ commit }, id) {
        try {
            const data = await fetchPrebidIdUpload(id);
            commit('SET_SELECTED_PREBID_ID_UPLOAD', data);
        } catch (e) {
            console.log(e);
        }
    },

    async rescanFile({ commit }, { accountId, uploadId }) {
        try {
            const data = await rescanIdUpload(accountId, uploadId);
            commit('SET_UPLOADED_FILE', data);
        } catch (e) {
            console.log(e);
        }
    },

    async uploadConfirm({ commit }, { body, accountId, uploadId }) {
        try {
            const res = await confirmUpload(body, accountId, uploadId);
            commit('SET_UPLOADED_FILE', null);
            commit('SET_CURRENT_STEP', 0);
            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: res.message ? res.message : 'Successfully uploaded',
                    type: 'success',
                },
                { root: true }
            );
            setTimeout(() => {
                commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
            }, 5000);
        } catch (e) {
            console.log(e);
            commit(
                'SET_ALERT_MESSAGE',
                {
                    message: e.message ? e.message : 'Error uploading',
                    type: 'error',
                },
                { root: true }
            );
            setTimeout(() => {
                commit('CLEAR_ALERT_MESSAGE', {}, { root: true });
            }, 5000);
        }
    },

    setCurrentStep({ commit }, currentStep) {
        commit('SET_CURRENT_STEP', currentStep);
    },
    setUploadedFile({ commit }, uploadedFile) {
        commit('SET_UPLOADED_FILE', uploadedFile);
    },
    setError({ commit }, error) {
        commit('SET_ERROR', error);
    },

    async removeUploadId({ commit }, uploadId) {
        try {
            await removeIdUpload(uploadId);
            commit('SET_REMOVE_UPLOAD', uploadId);
            commit('SET_UPLOADED_FILE', null);
            commit('SET_CURRENT_STEP', 0);
        } catch (error) {
            console.error(error);
        }
    },
};

import regex from '@util/regex';

const deepCopy = obj => JSON.parse(JSON.stringify(obj));

const searchList = (list, searchValue, searchBy = null) => {
    const search = searchValue.toLowerCase().trim();

    if (!search) return list;

    if (searchBy) {
        return list.filter(li => li[searchBy].toLowerCase().indexOf(search) > -1);
    }
    return list.filter(li => li.toLowerCase().indexOf(search) > -1);
};

const dotClass = status => {
    switch (status) {
    case 'RUNNING':
        return 'dot dot-warning';

    case 'SUCCESS':
        return 'dot dot-success';

    case 'FAILED':
    case 'CANCELED':
        return 'dot dot-danger';

    default:
        return 'dot dot-gray';
    }
};

const dynamicSort = key => {
    let sortOrder = 1;
    if (key[0] === '-') {
        sortOrder = -1;
        key = key.substr(1);
    }
    return function (a, b) {
        const val1 = a[key] ? a[key].toLowerCase() : '';
        const val2 = b[key] ? b[key].toLowerCase() : '';
        const result = val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
        return result * sortOrder;
    };
};

const compareVersion = (v1, v2) => {
    // v1 < v2 => result -1
    // v1 === v2 => result 0
    // v1 >== v2 => result 1
    // v1, v2 format invalid => result false
    if (typeof v1 !== 'string') return false;
    if (typeof v2 !== 'string') return false;
    if (!regex.version_number.test(v1) || !regex.version_number.test(v2))
        return false;
    v1 = v1.split('.');
    v2 = v2.split('.');
    const k = Math.min(v1.length, v2.length);
    for (let i = 0; i < k; ++i) {
        v1[i] = parseInt(v1[i], 10);
        v2[i] = parseInt(v2[i], 10);
        if (v1[i] > v2[i]) return 1;
        if (v1[i] < v2[i]) return -1;
    }
    return v1.length == v2.length ? 0 : v1.length < v2.length ? -1 : 1;
};

const asyncForEach = async (array, callback) => {
    for (let i = 0; i < array.length; i += 1) {
        await callback(array[i], i, array);
    }
};

const capitalizeFirstLetter = (val) => {
    return val.split(' ').map(item => item.charAt(0).toUpperCase() + item.substring(1)).join(' ');
};

const toCamelCaseID = (val) => {
    return val.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

const removeDuplicateObjectsByKey = (array, uniqueKey) => {
    return [...new Map(array.map(item => [item[uniqueKey], item])).values()];
};

const containsDuplicates = (arr) => {
    return arr.some((value, index) => arr.indexOf(value) !== index);
}

const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
}

const formatTime = (date) => {
    const d = new Date(date);
    const hour = d.getHours();
    const minute = d.getMinutes();
    const second = d.getSeconds();
    return `${hour}:${minute}:${second}`;
}

const formatBuildDataForDownload = (data) => {
    const formattedData = data.map(item => {
        return {
            'Priority': item.priority === 1 ? 'High' : item.priority === null ? 'N/A' : 'Low',
            'Account': item.account,
            'Domain': item.domain,
            'Version': item.version,
            'Note': item.note,
            'Last modified by': item.last_modified_by,
            'Status': item.status,
            'Updated at date': formatDate(item.updated_at),
            'Updated at time': formatTime(item.updated_at),
            'Runs': item.job?.executions ? item.job.executions : 0,
        }
    });

    return formattedData;
}

const tableDataToCSV = (data) => {
    const formattedData = formatBuildDataForDownload(data);
    const csvRows = [];
    const headers = Object.keys(formattedData[0]);
    csvRows.push(headers.join(','));
    for (const row of formattedData) {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '""');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
}

export default {
    deepCopy,
    searchList,
    dotClass,
    dynamicSort,
    compareVersion,
    asyncForEach,
    capitalizeFirstLetter,
    removeDuplicateObjectsByKey,
    containsDuplicates,
    toCamelCaseID,
    tableDataToCSV
}

import regex from '@util/regex';

/**
 * Checks if AB testing allocations are valid for all test versions.
 *
 * @param {Object} abTesting - The AB testing configuration to validate.
 * @returns {boolean} Returns true if all allocations are valid, false otherwise.
 */
export function isAbTestingValid(abTesting) {
    return abTesting.testVersions.every(
        testVersion => regex.numeric.test(testVersion.allocation)
    );
}

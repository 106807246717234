const mutations = {
    UPDATE_VERSION_LOTAME_ID(state, lotameIdOptions) {
        const { key, value } = lotameIdOptions;
        state.version.config.lotameId[key] = value;
    },
};

const actions = {
    updateVersionLotameId({ commit }, LotameIdOptions) {
        commit('UPDATE_VERSION_LOTAME_ID', LotameIdOptions);
    },
};

const getters = {
    lotameId: ({ version }) => version.config.lotameId || {},
};

export default {
    mutations,
    actions,
    getters,
};

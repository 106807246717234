const mutations = {
    UPDATE_VERSION_OPTIMERA(state, optimeraOptions) {
        const { key, value } = optimeraOptions;
        state.version.config.optimera[key] = value;
    },
};

const actions = {
    updateVersionOptimera({ commit }, optimeraOptions) {
        commit('UPDATE_VERSION_OPTIMERA', optimeraOptions);
    },
};

const getters = {
    optimera: ({ version }) => version.config.optimera || {},
};

export default {
    mutations,
    actions,
    getters,
};

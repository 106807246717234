import { authorizedAxios } from '@config/axios';

/* GET */
export const fetchSites = params =>
    authorizedAxios.get('/api/v1/sites', { params });
export const fetchJobSites = params =>
    authorizedAxios.get('/api/v1/sites/job-sites', { params });
export const getSiteById = siteId =>
    authorizedAxios.get(`/api/v1/sites/${siteId}`);
export const getSiteVersions = (siteId, params) =>
    authorizedAxios.get(`/api/v1/sites/${siteId}/versions`, { params });
export const getDeployments = (siteId, params) =>
    authorizedAxios.get(`/api/v1/sites/${siteId}/deployments`, { params });
export const fetchSiteVersion = (versionId) =>
    authorizedAxios.get(`/api/v1/versions/${versionId}`);
export const fetchSiteTestVersions = (siteId, status) =>
    authorizedAxios.get(`/api/v1/sites/${siteId}/test-versions?status=${status}`);
export const fetchAdsTxt = siteId =>
    authorizedAxios.get(`/api/v1/sites/${siteId}/ads-txt`);
export const fetchAdsTxtHistory = (siteId, params) =>
    authorizedAxios.get(`/api/v1/sites/${siteId}/ads-txt-history`, { params });
export const fetchAdsTxtEntry = (siteId, adsTxtId) =>
    authorizedAxios.get(`/api/v1/sites/${siteId}/ads-txt/${adsTxtId}`);

/* POST */
export const createSite = params =>
    authorizedAxios.post('/api/v1/sites', params);
export const saveAndBuildSiteVersion = (siteId, body) =>
    authorizedAxios.post(`/api/v1/sites/${siteId}/versions`, body);
export const copySite = (siteId, body) =>
    authorizedAxios.post(`/api/v1/sites/${siteId}/copy`, body);
export const saveAdsTxt = (siteId, body) => {
    return authorizedAxios.post(`/api/v1/sites/${siteId}/ads-txt`, body);
}

/* PATCH */
export const updateSite = (siteId, body) =>
    authorizedAxios.patch(`/api/v1/sites/${siteId}`, body);
export const updateSiteDomain = (siteId, body) =>
    authorizedAxios.patch(`/api/v1/sites/${siteId}/domain`, body);
export const activateAdsTxtEntry = (siteId, adsTxtId) =>
    authorizedAxios.patch(`/api/v1/sites/${siteId}/ads-txt/${adsTxtId}/activate`);

import { getBidders } from '@api/bidders';

export default {
    SET_ERROR: (context, errorMsg) => {
        context.commit('POST_ERROR', errorMsg);
    },
    setSearchValue({ commit, dispatch }, searchValue) {
        commit('SET_SEARCH_VALUE', searchValue);
        dispatch('resetPagination');
        dispatch('resetSortParams');
    },
    setNotFound: (context, payload) => {
        context.commit('NOT_FOUND', payload);
    },
    setIsRefreshingToken: (context, payload) => {
        context.commit('SET_IS_REFRESHING_TOKEN', payload);
    },
    addFailedRequestToQueue: (context, payload) => {
        context.commit('ADD_FAILED_REQUEST', payload);
    },
    resetFailedReqQueue: context => {
        context.commit('RESET_FAILED_REQ_QUEUE');
    },
    toggleSearchExpanded({ commit }) {
        commit('TOGGLE_SEARCH_EXPANDED');
    },
    closeSearchExpanded({ commit }) {
        commit('CLOSE_SEARCH_EXPANDED');
    },
    resetPagination({ commit }) {
        const pagination = {
            currentPage: 1,
            pageSize: 25,
            pages: 0,
            totalItems: 0,
        };
        commit('SET_PAGINATION', pagination);
    },
    setPaginationCurrentPage({ commit }, page) {
        commit('SET_PAGINATION_CURRENT_PAGE', page);
    },
    setPaginationPageSize({ commit }, pageSize) {
        commit('SET_PAGINATION_PAGE_SIZE', pageSize);
        commit('SET_PAGINATION_CURRENT_PAGE', 1);
    },
    setSortParams({ commit }, sort) {
        commit('SET_SORT_PARAMS', sort);
    },
    resetSortParams({ commit }) {
        const sort = {
            order: '',
            orderBy: '',
        };
        commit('SET_SORT_PARAMS', sort);
    },
    resetAllQueryParams({ commit, dispatch }) {
        dispatch('resetPagination');
        dispatch('resetSortParams');
        commit('SET_SEARCH_VALUE', '');
        commit('SET_FILTER', {});
    },
    showLoadingSpinner({ commit }, bool) {
        commit('SHOW_LOADING_SPINNER', bool);
    },
    setFilter({ commit }, filter) {
        commit('SET_FILTER', filter);
    },
    async getBidders({ commit }) {
        try {
            const bidders = await getBidders();
            commit('SET_BIDDERS', bidders);
        } catch (e) {
            console.log(e);
        }
    },
};

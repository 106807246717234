const mutations = {
    UPDATE_VERSION_ZEOTAP_ID_PLUS(state, zeotapIdPlusOptions) {
        const { key, value } = zeotapIdPlusOptions;
        state.version.config.zeotapIdPlus[key] = value;
    },
};

const actions = {
    updateVersionZeotapIdPlus({ commit }, zeotapIdPlusOptions) {
        commit('UPDATE_VERSION_ZEOTAP_ID_PLUS', zeotapIdPlusOptions);
    },
};

const getters = {
    zeotapIdPlus: ({ version }) => version.config.zeotapIdPlus || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_MERKLE(state, merkleOptions) {
        const { key, value } = merkleOptions;
        state.version.config.merkle[key] = value;
    },
};

const actions = {
    updateVersionMerkle({ commit }, merkleOptions) {
        commit('UPDATE_VERSION_MERKLE', merkleOptions);
    },
};

const getters = {
    merkle: ({ version }) => version.config.merkle || {},
};

export default {
    mutations,
    actions,
    getters,
};

import validationHelpers from '@util/versionValidationHelpers';
import moduleValidationHelpers from '@util/moduleValidationHelpers';
import vh from '@util/versionHelpers';
import helpers from '@util/helpers';
import versionValidationHelpers from '@util/versionValidationHelpers';
import { isTrafficShapingValid } from '@util/validation/prebid';

const mutations = {
    SET_MODULE_ENABLED(state, moduleOptions) {
        const { key, value } = moduleOptions;
        state.version.config[key].enabled = value;
    },
    SET_MODULE_AVAILABILITY(state, moduleOptions) {
        const { key, value } = moduleOptions;
        state.version.config.options[key] = value;
    },
    UPDATE_VERSION_PASSBACK: (state, passbackOptions) => {
        const { index, key, value } = passbackOptions;
        state.version.config.passbacks[index][key] = value;
    },
    ADD_VERSION_PASSBACK(state) {
        state.version.config.passbacks.push({
            key: '',
            callback: '',
        });
    },
    REMOVE_VERSION_PASSBACK(state, passbackIndex) {
        state.version.config.passbacks.splice(passbackIndex, 1);
    },
    ADD_PASSBACK_ERROR(state, passbackIndex) {
        if (!state.invalidPassbacks.includes(passbackIndex))
            state.invalidPassbacks.push(passbackIndex);
    },
    REMOVE_PASSBACK_ERROR(state, passbackIndex) {
        state.invalidPassbacks = state.invalidPassbacks.filter(
            invalidPassbackInd => {
                return invalidPassbackInd !== passbackIndex;
            }
        );
    },
    UPDATE_AD_LOADING_OVERRIDE_DEVICE_DELAY_TIME(
        state,
        adLoadingOverrideDeviceOptions
    ) {
        const { deviceUuid, delayTime } = adLoadingOverrideDeviceOptions;
        const selectedDevice = state.version.config.adLoadingOverride.devices.find(
            d => d.deviceUuid === deviceUuid
        );
        selectedDevice.delayTime = delayTime;
    },
    SYNC_AD_LOADING_OVERRIDE_DEVICES(state) {
        state.version.config.adLoadingOverride.devices =
            state.version.config.devices.map(device => {
                const existingDevice =
                    state.version.config.adLoadingOverride.devices.find(
                        d => d.deviceUuid === device.uuid
                    );
                const deviceDelayTime = existingDevice ? existingDevice.delayTime : '';
                return {
                    deviceUuid: device.uuid,
                    device: device.name,
                    delayTime: deviceDelayTime,
                };
            });

        if (state.version.config.adLoadingOverride.devices.length > 1) {
            state.version.config.adLoadingOverride.devices =
                state.version.config.adLoadingOverride.devices.filter(
                    d => d.device !== vh.defaultViewportName
                );
        }

        if (state.version.config.options.hasAdLoadingOverride) {
            validationHelpers.validateAdLoadingOverride(
                state.version.config.adLoadingOverride
            );
        }
    },

    UPDATE_GAM_PRIVACY_FLAGS(state, { key, value }) {
        state.version.config.privacyFlags.gam[key] = value;
    },

    UPDATE_PREBID_PRIVACY_FLAGS(state, { key, value }) {
        state.version.config.privacyFlags.prebid[key] = value;
    },
};

const actions = {
    setModuleAvailability({ commit, state }, moduleOptions) {
        const {
            key,
            value,
            required,
            warningTabKey,
            minPrebidVersion,
            isVendor
        } = moduleOptions;

        if (key === 'hasLayouts' && value) {
            versionValidationHelpers.validateSlots(state.version, 'warning');
        } else if (key === 'hasLayouts' && !value) {
            commit('REMOVE_WARNING_SUBTAB', 'classes-and-templates');
            commit('REMOVE_ERROR_SUBTAB', 'classes-and-templates');
        }

        if (key === 'liveRamp' && value === false) {
            commit('UPDATE_VERSION_LIVE_RAMP', { key: 'hasAts', value: false });
        }

        // CHECK IF the module is valid - WHEN the user wants to disable a module TO prevent disabling of invalid modules
        const isModuleValid = moduleValidationHelpers.isModuleValid(
            key,
            state.version.config
        );
        if (!value && !isModuleValid) return;

        if (!isVendor) {
            commit('SET_MODULE_AVAILABILITY', moduleOptions);
        } else {
            commit('SET_MODULE_ENABLED', moduleOptions);
        }

        commit('SYNC_VERSION_NETWORKS');

        let prebidVersion = 'latest'; // version is latest
        if (state.version.config.prebid.versionType === 'default')
            prebidVersion = vh.defaultPrebidVersion;
        else if (state.version.config.prebid.versionType === 'exact')
            prebidVersion = state.version.config.prebid.version;

        if (minPrebidVersion) {
            if (value && prebidVersion !== 'latest') {
                // check if prebid version is supported if:
                // user is enabling an Identity module and the configured prebid version is not the 'latest'
                const comparison = helpers.compareVersion(
                    prebidVersion,
                    minPrebidVersion
                );
                if (comparison === -1 || comparison === false) {
                    // invalid -> prebidVersion is smaller then identity version
                    // supported when Prebid version is the same or higher than what the module needs)
                    if (!isVendor) {
                        commit('ADD_INCOMPATIBLE_IDENTITY_MODULE', warningTabKey);
                    } else {
                        commit('ADD_INCOMPATIBLE_IDENTITY_MODULE', key);
                    }
                }
            } else if (!value) {
                if (!isVendor) {
                    commit('REMOVE_INCOMPATIBLE_IDENTITY_MODULE', warningTabKey);
                } else {
                    commit('REMOVE_INCOMPATIBLE_IDENTITY_MODULE', key);
                }
            }
        }

        if (required?.length && !isVendor) {
            if (value && !validationHelpers.checkSetupOptionRequired(state.version.config, required)) {
                commit('ADD_WARNING_SUBTAB', warningTabKey);
            } else {
                commit('REMOVE_WARNING_SUBTAB', warningTabKey);
            }
        }

        if (required?.length && isVendor) {
            if (value && !validationHelpers.checkSetupOptionRequired(state.version.config, required)) {
                commit('ADD_ERROR_VENDOR', key);
            }
        }

        if (key === 'isTestMode') {
            commit('UPDATE_VERSION', { key: 'is_abtest_parent', value });
        }

        //special settings for imperative API
        if (key === 'imperativeApi') {
            let configCopy = helpers.deepCopy(state.version.config);
            const { htlbidGlobal } = state.version.config;

            configCopy.layouts.layouts.forEach(layout => {
                layout.poll.type = null;
                if (layout.key === 'universal') {
                    layout.slots.forEach(slot => {
                        slot.selector = value
                            ? `#${slot.name ? slot.name : 'slot_name'}`
                            : `.${htlbidGlobal}ad-${slot.name ? slot.name : 'slot_name'}`;
                    });
                }
            });
            state.version.config = configCopy;
        }

        if (key === 'hasTrafficShaping') {
            if (!value) {
                commit('REMOVE_WARNING_SUBTAB', 'traffic-shaping');
                commit('REMOVE_ERROR_SUBTAB', 'traffic-shaping');
            } else {
                const trafficShapingValid = isTrafficShapingValid(state.version.config);
                if (!trafficShapingValid) {
                    commit('ADD_WARNING_SUBTAB', 'traffic-shaping');
                }
            }
        }

        if (key === 'hasPrebid' && value) {
            const trafficShapingValid = isTrafficShapingValid(state.version.config);
            if (!trafficShapingValid) {
                commit('ADD_WARNING_SUBTAB', 'traffic-shaping');
            } else {
                commit('REMOVE_WARNING_SUBTAB', 'traffic-shaping');
                commit('REMOVE_ERROR_SUBTAB', 'traffic-shaping');
            }
        }

        versionValidationHelpers.validateVersionErrors(state.version, 'warning');
    },
    updateVersionPassback({ commit }, passbackOptions) {
        commit('UPDATE_VERSION_PASSBACK', passbackOptions);
    },
    addVersionPassback({ commit }) {
        commit('ADD_VERSION_PASSBACK');
    },
    removeVersionPassback({ commit }, passbackIndex) {
        commit('REMOVE_VERSION_PASSBACK', passbackIndex);
    },
    addPassbackError({ commit }, passbackIndex) {
        commit('ADD_PASSBACK_ERROR', passbackIndex);
    },
    removePassbackError({ commit }, passbackIndex) {
        commit('REMOVE_PASSBACK_ERROR', passbackIndex);
    },
    updateAdLoadingOverrideDeviceDelayTime(
        { commit },
        adLoadingOverrideDeviceOptions
    ) {
        commit(
            'UPDATE_AD_LOADING_OVERRIDE_DEVICE_DELAY_TIME',
            adLoadingOverrideDeviceOptions
        );
    },
    updateGAMPrivacyFlags({ commit }, privacyFlags) {
        commit('UPDATE_GAM_PRIVACY_FLAGS', privacyFlags);
    },

    updatePrebidPrivacyFlags({ commit }, privacyFlags) {
        commit('UPDATE_PREBID_PRIVACY_FLAGS', privacyFlags);
    },
};

const getters = {
    options: ({ version }) => version.config.options || {},
    invalidPassbacks: ({ invalidPassbacks }) => invalidPassbacks,
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_STAQ_ANALYTICS(state, staqAnalyticsOptions) {
        const { key, value } = staqAnalyticsOptions;
        state.version.config.staqAnalytics[key] = value;
    },
};

const actions = {
    updateVersionStaqAnalytics({ commit }, staqAnalyticsOptions) {
        commit('UPDATE_VERSION_STAQ_ANALYTICS', staqAnalyticsOptions);
    },
};

const getters = {
    staqAnalytics: ({ version }) => version.config.staqAnalytics || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    login,
    logout,
    getUserInformation,
    requestResetPassword,
} from '@api/authentication';
import { getAccounts } from '@api/accounts';
import router from '@router/router';
import storage from '@config/localStorage';

const state = {
    userInformation: {
        id: '',
        name: '',
        role: '',
        accounts: '',
        accountsBySite: [],
        selectedAccount: {},
    },
};

const mutations = {
    SET_USER_INFORMATION(state, userInformation) {
        state.userInformation = userInformation;
    },
    SET_USER_SELECTED_ACCOUNT(state, selectedAccount) {
        state.userInformation.selectedAccount = selectedAccount;
    },
    RESET_USER_INFORMATION(state) {
        state.userInformation = {
            id: '',
            name: '',
            role: '',
            accounts: '',
            accountsBySite: [],
            selectedAccount: {},
        };
    },
    SET_USER_ACCOUNTS_BY_SITE(state, accountsBySite) {
        state.userInformation.accountsBySite = accountsBySite;
    },
};

const actions = {
    async login({ dispatch }, credentials) {
        try {
            const { token, refreshToken, userInformation } = await login(credentials);
            storage.set('token', token);
            storage.set('refresh-token', refreshToken);
            dispatch('setUserInformation', userInformation);
            dispatch('resetAllQueryParams');
            router.push({ name: 'mainView' });
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async getUserInformation({ dispatch }) {
        try {
            const userInformation = await getUserInformation();
            dispatch('setUserInformation', userInformation);
        } catch (e) {
            console.log('error ', e);
        }
    },
    setUserInformation({ commit }, userInformation) {
        let selectedAccount = JSON.parse(localStorage.getItem(userInformation.id));

        if (
            !selectedAccount ||
      (userInformation.accounts.length > 0 &&
        !userInformation.accounts.some(
            a => a.account_id === selectedAccount.value
        ))
        ) {
            let firstAccount =
        userInformation.accounts.length > 0 ? userInformation.accounts[0] : {};
            selectedAccount = {
                value: firstAccount.account_id,
                text: firstAccount.name,
            };
        } else if (userInformation.accounts.length === 0) {
            selectedAccount = {};
        }
        userInformation.selectedAccount = selectedAccount;
        userInformation.accountsBySite = [];
        commit('SET_USER_INFORMATION', userInformation);
        storage.set(
            userInformation.id,
            JSON.stringify(userInformation.selectedAccount)
        );
    },
    setUserSelectedAccount({ state, commit }, selectedAccount) {
        commit('SET_USER_SELECTED_ACCOUNT', selectedAccount);
        storage.set(state.userInformation.id, JSON.stringify(selectedAccount));
    },
    async logout({ commit }, clearServerSession = false) {
        if (clearServerSession) await logout();
        commit('RESET_USER_INFORMATION');
        storage.remove('token');
        storage.remove('refresh-token');
        router.push({ name: 'login' });
    },
    async requestResetPassword(_, email) {
        try {
            await requestResetPassword({ email });
        } catch (e) {
            console.log('error ', e);
            throw e.error;
        }
    },
    async getUserAccountsBySite({ state, commit }, siteDomain) {
        try {
            const { accounts } = await getAccounts({
                userId: state.userInformation.id,
                siteDomain,
            });
            commit('SET_USER_ACCOUNTS_BY_SITE', accounts);
        } catch (e) {
            console.log('error ', e);
        }
    },
};

const getters = {
    userInformation: ({ userInformation }) => userInformation,
    isUserAuthenticated: ({ userInformation }) => userInformation.id !== '',
};

export default {
    state,
    mutations,
    actions,
    getters,
};

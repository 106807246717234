const mutations = {
    UPDATE_VERSION_SOVRN_ANALYTICS(state, sovrnAnalyticsOptions) {
        const { key, value } = sovrnAnalyticsOptions;
        state.version.config.sovrnAnalytics[key] = value;
    },
};

const actions = {
    updateVersionSovrnAnalytics({ commit }, sovrnAnalyticsOptions) {
        commit('UPDATE_VERSION_SOVRN_ANALYTICS', sovrnAnalyticsOptions);
    },
};

const getters = {
    sovrnAnalytics: ({ version }) => version.config.sovrnAnalytics || {},
};

export default {
    mutations,
    actions,
    getters,
};

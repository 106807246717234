export default {
    currentUserRole: state => state.currentUserRole,
    userId: state => state.userId,
    userName: state => state.userName,
    userAccounts: state => state.userAccounts,
    selectedAccount: state => state.selectedAccount,
    notFound: state => state.notFound,
    error: state => state.error,
    isRefreshingToken: state => state.isRefreshingToken,
    failedReqQueue: state => state.failedReqQueue,
    searchValue: state => state.searchValue,
    searchExpanded: state => state.searchExpanded,
    pagination: state => state.pagination,
    queryParams: state => {
        return {
            limit: state.pagination.pageSize,
            page: state.pagination.currentPage,
            sort: state.sort.orderBy
                ? state.sort.orderBy + ':' + state.sort.order
                : '',
            search: state.searchValue,
            filter: state.filter,
        };
    },
    sortParams: state => state.sort,
    bidders: ({ bidders }) => bidders,
    showLoadingSpinner: ({ showLoadingSpinner }) => showLoadingSpinner,
};

const mutations = {
    UPDATE_VERSION_LIVE_INTENT(state, liveIntentOptions) {
        const { key, value } = liveIntentOptions;
        state.version.config.liveIntent[key] = value;
    },
};

const actions = {
    updateVersionLiveIntent({ commit }, liveIntentOptions) {
        commit('UPDATE_VERSION_LIVE_INTENT', liveIntentOptions);
    },
};

const getters = {
    liveIntent: ({ version }) => version.config.liveIntent || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    fetchAlerts,
    fetchAlertByType,
    saveAlertConfig
} from '@api/alerts';

const state = {
    alerts: [],
};

const mutations = {
    SET_ALERTS(state, alerts) {
        state.alerts = alerts;
    },
    SET_ALERT_CONFIG(state, alertConfig) {
        state.alerts = {
            ...state.alerts,
            [alertConfig.type]: {
                ...state.alerts[alertConfig.type],
                ...alertConfig,
            },
        }
    },
    SET_ALERT_ACTIVE(state, { type, active }) {
        state.alerts = {
            ...state.alerts,
            [type]: {
                ...state.alerts[type],
                is_active: active,
            },
        }
    },
    SET_ALERT_THRESHOLDS(state, payload) {
        state.alerts = {
            ...state.alerts,
            [payload.type]: {
                ...state.alerts[payload.type],
                config: {
                    ...state.alerts[payload.type].config,
                    thresholds: {
                        ...state.alerts[payload.type].config.thresholds,
                        ...{[payload.key]: payload.value}
                    },
                }
            },
        }
    },
};

const actions = {
    async getAlerts({ commit, rootGetters }, params) {
        try {
            const alerts = await fetchAlerts({ ...rootGetters.queryParams, ...params });
            commit('SET_ALERTS', alerts);
        } catch (e) {
            console.log(e);
        }
    },
    async getAlertByType({ commit }, type) {
        try {
            const { data } = await fetchAlertByType(type);
            commit('SET_ALERT_CONFIG', data);
        } catch (e) {
            console.log(e);
        }
    },
    async saveAlertConfig({ commit }) {
        const payload = state.alerts;
        try {
            const alerts = await saveAlertConfig(payload);
            commit('SET_ALERT_CONFIG', alerts);
        } catch (e) {
            console.log(e);
        }
    },
    setAlertActive({ commit }, payload) {
        commit('SET_ALERT_ACTIVE', payload);
    },
    setAlertThresholds({ commit }, payload) {
        commit('SET_ALERT_THRESHOLDS', payload);
    }
};

const getters = {
    allAlerts: state => state.alerts,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};

const mutations = {
    UPDATE_VERSION_ASSERTIVE_YIELD(state, options) {
        const { key, value } = options;
        state.version.config.assertiveYield[key] = value;
    },
};

const actions = {
    updateVersionAssertiveYield({ commit }, options) {
        commit('UPDATE_VERSION_ASSERTIVE_YIELD', options);
    },
};

const getters = {
    assertiveYield: ({ version }) => version.config.assertiveYield || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_AD_LIGHTNING(state, adLightningOptions) {
        const { key, value } = adLightningOptions;
        state.version.config.adLightning[key] = value;
    },
};

const actions = {
    updateVersionAdLightning({ commit }, adLightningOptions) {
        commit('UPDATE_VERSION_AD_LIGHTNING', adLightningOptions);
    },
};

const getters = {
    adLightning: ({ version }) => version.config.adLightning || {},
};

export default {
    mutations,
    actions,
    getters,
};

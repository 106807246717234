/* eslint-disable */

const mutations = {
    UPDATE_VERSION_BRITEPOOL(state, britepoolOptions) {
        const { key, value } = britepoolOptions;
        state.version.config.britepool[key] = value;
    },
};

const actions = {
    updateVersionBritepool({ commit }, britepoolOptions) {
        commit('UPDATE_VERSION_BRITEPOOL', britepoolOptions);
    },
};

const getters = {
    britepool: ({ version }) => version.config.britepool || {},
};

export default {
    mutations,
    actions,
    getters,
};

import vh from '@util/versionHelpers';

export default {
    lineItemJobs: [],
    targetingHistoryJobs: [],
    jobSites: [],
    selectedSiteId: null,
    bidders: [],
    config: {
        creativeType: null,
        granularity: null,
        customGranularity: null,
        advertiserId: null,
        autoApprove: false,
        enableSendAllBids: true,
        sizes: [],
        placements: [],
        orderName: null,
        currency: 'USD',
        // additional targeting params
        addTargeting: true,
        key: '',
        operator: 'IS',
        values: '',
        duration: 30,
        vastUrl: '',
        mediaType: 'banner',
    },
    arePriceBucketsValid: true,
    granularities: vh.granularityTypes,
    prebidCreatives: [
        { value: 'PREBID_SAFEFRAME', text: 'Safeframe (default)' },
        { value: 'PREBID_UNSAFE', text: 'Unsafe / Friendly Iframe' },
        { value: 'PREBID_UNIVERSAL', text: 'Universal / AMP' },
        { value: 'PREBID_NATIVE', text: 'Native' },
        { value: 'FACEBOOK_NATIVE', text: 'Facebook Native' },
    ],
    // We support currencies as listed in https://docs.prebid.org/dev-docs/modules/currency.html .
    currencies: [
        'AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CNY', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HRK', 'HUF', 'IDR', 'ILS', 'INR',
        'ISK', 'JPY', 'KRW', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'RON', 'RUB', 'SEK', 'SGD', 'THB', 'TRY', 'USD',
        'ZAR',
    ],
    mediaTypes: [
        {
            value: 'banner',
            text: 'Banner',
        },
        {
            value: 'video',
            text: 'Video',
        },
    ],
    selectedJob: {},
    fetchInterval: null,
    version: null,
};

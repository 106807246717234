import helpers from '@util/helpers';
import vh from '@util/versionHelpers';

const priorityBidders = [
    'amazon',
    'appnexus',
    'conversant',
    'districtm',
    'ix',
    'kargo',
    'openx',
    'sovrn',
    'rubicon',
    'teads',
    'triplelift',
];

const defaultAppnexusBidder = {
    name: 'AppNexus',
    text: 'AppNexus',
    key: 'appnexus',
    hb_pb: 'hb_pb_appnexus',
    hb_adid: 'hb_adid_appnexus',
    hb_size: 'hb_size_appnexus',
    is_prebid: true,
    is_safeframe: true,
    params: [
        {
            text: 'placementId',
            key: 'placementId',
            rules: ['required', 'numeric'],
        },
        {
            text: 'Notes',
            key: 'htlsize',
        },
    ],
};

const getNormalizedBidders = (config, allBidders, account, bidderOptions) => {
    const normalizedBidders = allBidders.map(bidder => {
        const normalizedBidder = helpers.deepCopy(bidder);

        if (normalizedBidder.key === 'ix') {
            normalizedBidder.params = getIXParamsByVersion(
                config,
                normalizedBidder.params
            );
        } else if (
            ['pbrtb_banner', 'pbrtb_native'].includes(normalizedBidder.key) &&
            bidderOptions
        ) {
            normalizedBidder.params = getPbrtbConfigParams(
                config,
                normalizedBidder.params,
                account,
                bidderOptions
            );
        }

        return normalizedBidder;
    });

    return normalizedBidders;
};

const getIXParamsByVersion = (config, defaultParams) => {
    let params = helpers.deepCopy(defaultParams);

    const isExactVersionType =
    config.prebid.versionType.toLowerCase() === 'exact';
    const isValidVersion =
    helpers.compareVersion(config.prebid.version, '4.43.0') !== -1;
    if (isExactVersionType && !isValidVersion) {
        const sizeParam = params.find(param => param.key === 'size');
        sizeParam.rules.push('required');
    }

    return params;
};

const getSlotById = (config, slotId) => {
    if (
        !config ||
    !slotId ||
    !config.layouts ||
    !config.layouts.layouts ||
    !Array.isArray(config.layouts.layouts)
    ) {
        throw new Error('getSlotById - invalid parameters.');
    }

    let foundSlot = null;
    config.layouts.layouts.forEach(layout => {
        if (!Array.isArray(layout.slots)) {
            throw new Error('getSlotById - invalid slots in layout.');
        }

        layout.slots.forEach(slot => {
            if (!foundSlot && slot.slotId && slot.slotId === slotId) {
                foundSlot = slot;
            }
        });
    });

    return foundSlot;
};
const getSlotFromPrebidGroupName = (config, prebidGroupName) => {
    if (
        !config ||
    !prebidGroupName ||
    !config.layouts ||
    !config.layouts.layouts ||
    !Array.isArray(config.layouts.layouts)
    ) {
        throw new Error('getSlotFromPrebidGroupName - invalid parameters.');
    }

    let foundSlot = null;
    config.layouts.layouts.forEach(layout => {
        if (!Array.isArray(layout.slots)) {
            throw new Error('getSlotFromPrebidGroupName - invalid slots in layout.');
        }

        const lastIndexOfDash = prebidGroupName.lastIndexOf('-');

        let slotName = '';

        if (lastIndexOfDash !== -1) {
            slotName = prebidGroupName.substring(0, lastIndexOfDash);
        } else {
            slotName = prebidGroupName;
        }

        layout.slots.forEach(slot => {
            if (
                !foundSlot &&
        slot.name &&
        slot.name.toLowerCase() === slotName.toLowerCase()
            ) {
                foundSlot = slot;
            }
        });
    });

    return foundSlot;
};

const getDeviceFromPrebidGroupName = (config, prebidGroupName) => {
    if (
        !config ||
    !prebidGroupName ||
    !config.devices ||
    !Array.isArray(config.devices)
    ) {
        throw new Error('getDeviceFromPrebidGroupName - invalid parameters.');
    }

    const lastIndexOfDash = prebidGroupName.lastIndexOf('-');

    if (lastIndexOfDash === -1) {
        return null;
    }

    const deviceName = prebidGroupName.substr(lastIndexOfDash + 1);
    let foundDevice = null;
    config.devices.forEach(device => {
    // foundDevice should be the first matching name, so we should not overwrite if it was already found.
        if (foundDevice || !device.name) {
            return null;
        }
        // make it case insensitive by lowering everything.
        let deviceNameToCompare = device.name.toLowerCase();
        // aAll_viewports' is mapped to "all" for prebidGroupNames in Ad Configuration and Id Load Sheets.
        if (deviceNameToCompare === vh.defaultViewportName.toLowerCase()) {
            deviceNameToCompare = 'all';
        }

        if (deviceNameToCompare === deviceName.toLowerCase()) {
            foundDevice = device;
        }
    });

    return foundDevice;
};

const getSizesForSlotDevice = (slot, device, validSizesOverride) => {
    if (
        !slot ||
    !device ||
    !slot.sizeMappings ||
    !Array.isArray(slot.sizeMappings) ||
    !device.uuid
    ) {
        throw new Error('getSizesForSlotDevice - invalid parameters.');
    }

    const sizeMapping = slot.sizeMappings.find(sm => sm.device === device.uuid);
    const invalidSizeMapping =
    !sizeMapping || !sizeMapping.sizes || !Array.isArray(sizeMapping.sizes);
    const invalidSizeOverride =
    validSizesOverride && !Array.isArray(validSizesOverride);

    if (invalidSizeMapping || invalidSizeOverride) {
        return '';
    }

    let validSizes = [
        '300x250',
        '320x50',
        '300x50',
        '160x600',
        '300x600',
        '728x90',
        '970x90',
        '970x250',
    ];
    if (typeof validSizesOverride !== 'undefined') {
        validSizes = validSizesOverride;
    }

    return sizeMapping.sizes
        .filter(s => s.size)
        .map(s => s.size.toString().trim())
        .filter(size => validSizes.includes(size))
        .join(',');
};

const getPbrtbConfigParams = (
    config,
    defaultParams,
    account,
    bidderOptions,
    slotId
) => {
    let siteId = config.siteId;

    if (
        !account ||
        !account.name ||
        !bidderOptions ||
        !bidderOptions.groupUuid ||
        !siteId
    ) {
        return defaultParams;
    }

    if (!siteId && account && account.siteId) {
        siteId = account.siteId;
    }

    if (!config || !config.prebid || !config.prebid.groups || !defaultParams) {
        throw new Error('getPbrtbConfigParams - invalid parameters.');
    }

    const prebidGroup = config.prebid.groups.find(
        group => group.uuid === bidderOptions.groupUuid
    );

    if (!prebidGroup || !prebidGroup.name) {
        return defaultParams;
    }

    const params = helpers.deepCopy(defaultParams);
    const tagIdParam = params.find(p => p.text === 'tagId');
    const siteIdParam = params.find(p => p.text === 'siteId');
    const publisherIdParam = params.find(p => p.text === 'publisherId');
    const publisherNameParam = params.find(p => p.text === 'publisherName');
    const sizeParam = params.find(p => p.text === 'size');
    let slot = null;

    if (slotId) {
        slot = getSlotById(config, slotId);
    } else {
        slot = getSlotFromPrebidGroupName(config, prebidGroup.name);
    }
    const device = getDeviceFromPrebidGroupName(config, prebidGroup.name);

    if (siteId && slot && Number.isInteger(slot.slotId)) {
        tagIdParam.value = `${siteId}-${slot.slotId}`;
    } else {
        tagIdParam.value = '';
    }

    siteIdParam.value = config.publisherDomain ? config.publisherDomain : '';
    publisherIdParam.value = config.sellerId ? config.sellerId.toString() : '';
    publisherNameParam.value = account.name
        ? account.name.toLowerCase().replace(/\s/g, '-')
        : '';

    if (
        typeof sizeParam !== 'undefined' &&
        bidderOptions.value === 'pbrtb_banner' &&
        slot &&
        device
    ) {
        sizeParam.value = exportFunctions.getSizesForSlotDevice(slot, device);
    }

    return params;
};

/*
 * Get pbrtb params using the slotId. does not get size because size requires the device
 */
const getPbrtbConfigParamsWithSlotId = (
    config,
    defaultParams,
    account,
    slotId
) => {
    if (!config || !config.prebid || !config.prebid.groups || !defaultParams) {
        throw new Error('getPbrtbConfigParams - invalid parameters.');
    }

    let siteId = config.siteId;

    if (!siteId && account && account.siteId) {
        siteId = account.siteId;
    }

    if (!account || !account.name || !slotId || !siteId) {
        return defaultParams;
    }

    const params = helpers.deepCopy(defaultParams);
    const tagIdParam = params.find(p => p.text === 'tagId');
    const siteIdParam = params.find(p => p.text === 'siteId');
    const publisherIdParam = params.find(p => p.text === 'publisherId');
    const publisherNameParam = params.find(p => p.text === 'publisherName');

    if (siteId && Number.isInteger(slotId)) {
        tagIdParam.value = `${siteId}-${slotId}`;
    } else {
        tagIdParam.value = '';
    }

    siteIdParam.value = config.publisherDomain ? config.publisherDomain : '';
    publisherIdParam.value = config.sellerId ? config.sellerId.toString() : '';
    publisherNameParam.value = account.name
        ? account.name.toLowerCase().replace(/\s/g, '-')
        : '';

    return params;
};

// In order to use jest mocks (the jest function) this need to be exported in this way,
// and the local function used needs to be called from the exportObject and not directly.
const exportFunctions = {
    defaultAppnexusBidder,
    getDeviceFromPrebidGroupName,
    getNormalizedBidders,
    getPbrtbConfigParams,
    getPbrtbConfigParamsWithSlotId,
    getSizesForSlotDevice,
    getSlotFromPrebidGroupName,
    priorityBidders,
};

export default exportFunctions;

const mutations = {
    UPDATE_VERSION_GRAPESHOT(state, grapeshotOptions) {
        const { key, value } = grapeshotOptions;
        state.version.config.grapeshot[key] = value;
    },
};

const actions = {
    updateVersionGrapeshot({ commit }, grapeshotOptions) {
        commit('UPDATE_VERSION_GRAPESHOT', grapeshotOptions);
    },
};

const getters = {
    grapeshot: ({ version }) => version.config.grapeshot || {},
};

export default {
    mutations,
    actions,
    getters,
};

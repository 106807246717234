const mutations = {
    UPDATE_VERSION_CONNECT_ID(state, connectIdOptions) {
        const { key, value } = connectIdOptions;
        state.version.config.connectId[key] = value;
    },
};

const actions = {
    updateVersionConnectId({ commit }, connectIdOptions) {
        commit('UPDATE_VERSION_CONNECT_ID', connectIdOptions);
    },
};

const getters = {
    connectId: ({ version }) => version.config.connectId || {},
};

export default {
    mutations,
    actions,
    getters,
};

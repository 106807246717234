import vh from '@util/versionHelpers';
import validationHelpers from '@util/versionValidationHelpers';
import helpers from '@util/helpers';
import uuidv4 from 'uuid/v4';

const mutations = {
    UPDATE_VERSION_NETWORK(state, networkOptions) {
        const { index, key, value } = networkOptions;
        state.version.config.networks[index][key] = value;
    },
    SYNC_VERSION_NETWORKS(state) {
        state.version.config.networks = vh.loadVersionNetworks(
            state.version.config
        );
        state.version.config.networks = vh.syncVersionNetworks(
            state.version.config
        );
        validationHelpers.validateAccountOwnership(state.version.config);
    },
    UPDATE_AUTHORIZED_DOMAINS(state, authorizedDomains) {
        state.version.config.authorizedDomains = authorizedDomains;
    },
    UPDATE_PRODUCTION_DOMAIN(state, productionDomain) {
        state.version.config.productionDomain = productionDomain;
    },
    UPDATE_DOMAIN_TO_OVERRIDE(state, domainToOverride) {
        state.version.config.domainToOverride = domainToOverride;
    },
    // Schain
    ADD_NEW_SCHAIN_CONFIG(state, schain) {
        schain.sellerId = state.version.config.sellerId;
        schain.publisherDomain = state.version.config.publisherDomain;
        state.version.config.schains = [...state.version.config.schains, schain];
    },

    COPY_SCHAIN_CONFIG(state, schain) {
        state.version.config.schains = [
            ...state.version.config.schains,
            schain,
        ];
    },

    REMOVE_SCHAIN_CONFIG(state, uuid) {
        state.version.config.schains = state.version.config.schains.filter(item => item.uuid !== uuid);
    },

    UPDATE_SCHAIN_CONFIG(state, schain) {
        const { uuid, key, value } = schain;
        const selectedSchain = state.version.config.schains.find(
            item => item.uuid === uuid
        );
        selectedSchain[key] = value;
    },
};

const actions = {
    updateVersionNetwork({ commit }, networkOptions) {
        commit('UPDATE_VERSION_NETWORK', networkOptions);
        if (networkOptions.key === 'owner') commit('SYNC_VERSION_NETWORKS');
    },
    updateAuthorizedDomains({ commit }, authorizedDomains) {
        commit('UPDATE_AUTHORIZED_DOMAINS', authorizedDomains);
    },
    updateProductionDomain({ commit }, productionDomain) {
        commit('UPDATE_PRODUCTION_DOMAIN', productionDomain);
    },
    updateDomainToOverride({ commit }, domainToOverride) {
        commit('UPDATE_DOMAIN_TO_OVERRIDE', domainToOverride);
    },
    // Schain
    addNewSchainConfig({commit}) {
        const schain = {
            uuid: uuidv4(),
            schainName: null,
            sellerId: null,
            sellerDomain: null,
            publisherDomain: null

        }
        commit('ADD_NEW_SCHAIN_CONFIG', schain);
    },
    updateSchainConfig({commit}, schain) {
        commit('UPDATE_SCHAIN_CONFIG', schain)
    },
    removeSchainConfig({commit}, uuid) {
        commit('REMOVE_SCHAIN_CONFIG', uuid)
    },
    copySchainConfig({ commit, state }, uuid) {
        const selectedSchain = helpers.deepCopy(
            state.version.config.schains.find(item => item.uuid === uuid)
        );
        selectedSchain.uuid = uuidv4();
        const updatedName = 'copy_of_' + selectedSchain.schainName;
        selectedSchain.schainName = updatedName;
        commit('COPY_SCHAIN_CONFIG', selectedSchain);
    },
};

const getters = {
    networks: ({ version }) => version.config.networks || [],
    authorizedDomains: ({ version }) => version.config.authorizedDomains || [],
    productionDomain: ({ version }) => version.config.productionDomain?.toString() || '',
    domainToOverride: ({ version }) => version.config.domainToOverride?.toString() || '',
    schains: ({ version }) => version.config.schains || []
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_BIGRICHMEDIA(state, bigRichMediaOptions) {
        const { key, value } = bigRichMediaOptions;
        state.version.config.bigRichMedia[key] = value;
    },
};

const actions = {
    updateVersionBigRichMedia({ commit }, bigRichMediaOptions) {
        commit('UPDATE_VERSION_BIGRICHMEDIA', bigRichMediaOptions);
    }
};

const getters = {
    bigRichMedia: ({ version }) => version.config.bigRichMedia || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_TNCID(state, options) {
        const { key, value } = options;
        state.version.config.tncId[key] = value;
    },
};

const actions = {
    updateVersionTncId({ commit }, options) {
        commit('UPDATE_VERSION_TNCID', options);
    },
};

const getters = {
    tncId: ({ version }) => version.config.tncId || {},
};

export default {
    mutations,
    actions,
    getters,
};

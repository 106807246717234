/* eslint-disable no-useless-escape */
export default {
    numeric: /^([0-9]+)$/,
    two_numbers_separated_by_comma: /^\d+(,\d+)?$/,
    email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    alpha_num_dash: /^[0-9a-zA-Z-_]+$/, // alphabetical || numerical || _ || -
    alpha_dash: /^[0-9a-zA-Z-_]+$/, // to be consistent with vee-validator: alphabetical || numerical || _ || -
    alpha_dash_comma: /^[0-9a-zA-Z-_,]+$/, // to be consistent with vee-validator: alphabetical || numerical || _ || -
    sizes: /^([1-9]\d*)x([1-9]\d*)$|^([1-9]\d*)X([1-9]\d*)$|^fluid$/, // 1x1 320x50 300x250 300x600 (min = 1)
    videoSizes: /^([1-9]\d*)x([1-9]\d*)(v)?$|^([1-9]\d*)X([1-9]\d*)(v)?$|^fluid$/i, // 1x1v 320x50v 300x250v 300x600v (min = 1)
    domain: /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]+)+$/,
    lower_case_only: /^[a-z]+$/,
    uuid: /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b$/,
    version_number: /(\d+)\.(\d+)\.\d+$/,
    version_x_number: /(\d+)\.(\d+|x)(\.(\d+|x))?$/, /* 1.x or 1.x.x or 1.1.1 */
    version_x_number_permissive: /^master|((\d+)\.(\d+|x)(\.(\d+|x))?(-[-|\w]+)?)$/, /* 1.x or 1.x.x or 1.1.1 or 1.1.1-some-tag or master */
    numeric_dot: /^([0-9.]+)$/,
    two_decimal: /^[0-9]*\.[0-9]{2}$/,
    decimal: /^[0-9]*\.[0-9]*$/,
    url: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
    http_url:
    /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    alpha_num_dash_slash_space: /^[0-9a-zA-Z-_\/ ]+$/,
    alpha_num_dash_slash: /^[0-9a-zA-Z-_\/]+$/,
    alpha_num_dash_dot: /^[0-9a-zA-Z-_\.]+$/,
    alpha_num_dash_slash_dot: /^[0-9a-zA-Z-_\/\.]+$/,
    size: /^\d+(\x|\X)\d+$/,
    alpha_num: /^[0-9A-Z]*$/i,
    alpha_num_underscore: /^[0-9a-zA-Z_]*$/i,
    alpha_num_underscore_dash: /^[0-9a-zA-Z_-]*$/i,
    gam_ad_unit: /^[a-z0-9-_.*\/\\!<:()]+$/i,
    alpha_num_dash_space: /^[0-9a-zA-Z- ]+$/,
    strong_password:
    /(?=^.{8,}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    alpha_num_dash_underscore_space: /^[0-9a-zA-Z-_ ]+$/,
    single_value: /^\S*$/,
    numeric_or_double: /^[0-9]+(\.[0-9]+){0,1}$/,
    alpha_num_dot_underscore_slash_dash_space: /^[0-9a-zA-Z\._\/ -]+$/,
    alpha_num_dot_underscore_slash_dash: /^[0-9a-zA-Z\._\/-]+$/,
    alpha_num_dot_underscore_slash_dash_at: /^[@0-9a-zA-Z\._\/-]+$/,
    alpha_num_space: /^[0-9a-zA-Z ]+$/, // alphabetical || numerical || space,
    alpha: /^([a-zA-Z]+)$/,
    cookie_name: /^[!#$%&'*+\-.^_`|~0-9a-zA-Z]+$/, // alphanumeric, minus some special chars
    numeric_allow_negative: /^([-+]{0,1}[0-9]+)$/,
    address: /^[\w\d\s,\.\/\\#_\-]+$/
};

const mutations = {
    UPDATE_VERSION_COOKIE_DEPRECATION_MEASUREMENT(state, options) {
        const { key, value } = options;
        state.version.config.cookieDeprecationMeasurement[key] = value;
    },
};

const actions = {
    updateVersionCookieDeprecationMeasurement({ commit }, options) {
        commit('UPDATE_VERSION_COOKIE_DEPRECATION_MEASUREMENT', options);
    },
};

const getters = {
    cookieDeprecationMeasurement: ({ version }) => version.config.cookieDeprecationMeasurement || {},
};

export default {
    mutations,
    actions,
    getters,
};

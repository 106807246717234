/* eslint-disable */

const mutations = {
    UPDATE_VERSION_ID5(state, id5Options) {
        const { key, value } = id5Options;
        state.version.config.id5[key] = value;
    },

    UPDATE_VERSION_ID5_ANALYTICS(state, id5Options) {
        const { key, value } = id5Options;
        state.version.config.id5Analytics[key] = value;
    },
};

const actions = {
    updateVersionId5({ commit }, id5Options) {
        commit('UPDATE_VERSION_ID5', id5Options);
    },
    updateVersionId5Analytics({ commit }, options) {
        commit('UPDATE_VERSION_ID5_ANALYTICS', options);
    }
};

const getters = {
    id5: ({ version }) => version.config.id5 || {},
    id5Analytics: ({ version }) => version.config.id5Analytics || {},
};

export default {
    mutations,
    actions,
    getters,
};

const mutations = {
    UPDATE_VERSION_PUB_PROVIDED_ID(state, options) {
        const { key, value } = options;
        state.version.config.pubProvidedId[key] = value;
    },
};

const actions = {
    updateVersionPubProvidedId({ commit }, options) {
        commit('UPDATE_VERSION_PUB_PROVIDED_ID', options);
    },
};

const getters = {
    pubProvidedId: ({ version }) => version.config.pubProvidedId || {},
};

export default {
    mutations,
    actions,
    getters,
};

import { authorizedAxios } from '@config/axios';

/* GET */
export const fetchPrebidIds = (params) =>
    authorizedAxios.get('/api/v1/id-uploads', { params });
export const fetchPrebidIdUpload = (id) =>
    authorizedAxios.get(`/api/v1/id-uploads/${id}`);

/* POST */
export const newIdUpload = (body, accountId) =>
    authorizedAxios.post('/api/v1/id-uploads', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Account-Id': accountId,
        },
    });

export const rescanIdUpload = (accountId, uploadId) =>
    authorizedAxios.get(`/api/v1/id-uploads/rescan/${uploadId}`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Account-Id': accountId,
        },
    });

export const confirmUpload = (body, accountId, uploadId) => {
    const params = new URLSearchParams();
    params.append('targetSites', JSON.stringify(body.targetSites));
    const req = authorizedAxios.post(`/api/v1/id-uploads/${uploadId}/confirm`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Account-Id': accountId,
        },
    });
    return req;
};

/* DELETE */
export const removeIdUpload = (uploadId) =>
    authorizedAxios.delete(`/api/v1/id-uploads/${uploadId}`);

import validationHelpers from '@util/versionValidationHelpers';
import uuidv4 from 'uuid/v4';
import helpers from '@util/helpers';

export default {
    SET_PREBID_CONFIGURATION_FIELD(state, prebidOptions) {
        const { key, value } = prebidOptions;
        state.version.config.prebid[key] = value;

        if (key === 'hasCustomPageUrl' && !value) {
            state.version.config.prebid.pageUrl = null;
        }

        if (
            key === 'hasAdvertiserBlockList' &&
            !state.version.config.prebid.advertiserBlockList
        ) {
            state.version.config.prebid.advertiserBlockList = [];
        }
    },
    SET_PREBID_USER_SYNC_FIELD(state, userSyncOptions) {
        const { key, value } = userSyncOptions;
        state.version.config.prebidUserSync[key] = value;
        if (key === 'syncType' && value === 'disabled' && state.version.config.prebidUserSync.aliasSyncEnabled) {
            state.version.config.prebidUserSync.aliasSyncEnabled = false;
        }
        if (key === 'syncType') {
            if (value === 'default' || value === 'disabled') {
                state.version.config.prebidUserSync.syncsPerBidder = 5;
                state.version.config.prebidUserSync.filterSettings = {
                    image: {
                        bidders: 'all',
                        filter: 'include',
                    },
                    iframe: {
                        bidders: 'all',
                        filter: 'exclude',
                    },
                };

            }
        }
    },

    UPDATE_USER_SYNC_USER_ID_REPORTING(state, { key, value }) {
        state.version.config.prebidUserSync.userIdReporting[key] = value;
    },

    UPDATE_USER_SYNC_FILTER(state, options) {
        const { key, bidders } = options;
        state.version.config.prebidUserSync.filterSettings[key].bidders = bidders;
    },

    UPDATE_USER_SYNC_TARGETING(state, options) {
        const { key, filter } = options;
        state.version.config.prebidUserSync.filterSettings[key].filter = filter;
    },

    SET_PREBID_CURRENCY_FIELD(state, prebidCurrencyOptions) {
        const { key, value } = prebidCurrencyOptions;
        state.version.config.prebidCurrency[key] = value;
    },

    SYNC_SECONDARY_BIDDERS(state) {
        const prebid = state.version.config.prebid;
        if (!prebid?.secondaryBidders?.length) {
            return;
        }

        const activeBidders = Array.from(new Set(prebid.groups
            .flatMap(({ bidders }) => bidders)
            .filter(({ active }) => active)
            .map(({ bidder }) => bidder)));

        prebid.secondaryBidders = prebid.secondaryBidders.filter(b => activeBidders.includes(b));
    },

    // PREBID ID GROUPS
    ADD_NEW_PREBID_ID_GROUP(state, group) {
        state.version.config.prebid.groups.push(group);
    },
    UPDATE_PREBID_ID_GROUP_NAME(state, groupOptions) {
        const { groupUuid, value } = groupOptions;
        const selectedGroup = state.version.config.prebid.groups.find(
            g => g.uuid === groupUuid
        );
        selectedGroup.name = value;
    },
    REMOVE_PREBID_ID_GROUP(state, groupUuid) {
        const updatedList = state.version.config.prebid.groups.filter(
            g => g.uuid !== groupUuid
        );
        state.version.config.prebid.groups = updatedList;
    },
    COPY_PREBID_ID_GROUP(state, group) {
        state.version.config.prebid.groups.push(group);
    },
    SET_PREBID_ID_GROUP_BIDDER(state, bidderConfig) {
        const { groupUuid, bidderUuid } = bidderConfig.bidderOptions;
        const { bidder } = bidderConfig;

        const selectedGroup = state.version.config.prebid.groups.find(
            g => g.uuid === groupUuid
        );
        const selectedBidder = selectedGroup.bidders.find(
            b => b.uuid === bidderUuid
        );
        selectedBidder.params = [...bidder.params];
        selectedBidder.bidder = bidder.bidder;
        selectedBidder.active = bidder.active;
        selectedBidder.deprecated = bidder.deprecated;
    },
    ADD_PREBID_ID_GROUP_BIDDER(state, bidderOptions) {
        const { groupUuid, bidder } = bidderOptions;
        const selectedGroup = state.version.config.prebid.groups.find(
            g => g.uuid === groupUuid
        );
        selectedGroup.bidders.push(bidder);
    },
    REMOVE_PREBID_ID_GROUP_BIDDER(state, bidderOptions) {
        const { groupUuid, bidderUuid } = bidderOptions;
        const selectedGroup = state.version.config.prebid.groups.find(
            g => g.uuid === groupUuid
        );
        const updatedList = selectedGroup.bidders.filter(
            b => b.uuid !== bidderUuid
        );
        selectedGroup.bidders = updatedList;
    },

    SET_PREBID_ID_GROUP_BIDDER_STATUS(state, bidderOptions) {
        const { groupUuid, bidderUuid, value } = bidderOptions;
        const selectedGroup = state.version.config.prebid.groups.find(
            g => g.uuid === groupUuid
        );
        const selectedBidder = selectedGroup.bidders.find(
            b => b.uuid === bidderUuid
        );
        selectedBidder.active = value;
    },
    UPDATE_PREBID_ID_GROUP_BIDDER_PARAM(state, paramOptions) {
        const { groupUuid, bidderUuid, paramIndex, value } = paramOptions;

        const selectedGroup = state.version.config.prebid.groups.find(
            g => g.uuid === groupUuid
        );
        const selectedBidder = selectedGroup.bidders.find(
            b => b.uuid === bidderUuid
        );
        selectedBidder.params[paramIndex].value = value;
    },

    UPDATE_AMAZON_CONFIGURATION_FIELD(state, { key, value }) {
        state.version.config.amazon[key] = value;
    },

    // PREBID SERVER
    UPDATE_PREBID_SERVER_FIELD(state, options) {
        const { key, value } = options;
        state.version.config.prebidServer[key] = value;
    },
    UPDATE_PREBID_SERVER_DEFAULT_VENDOR_FIELD(state, options) {
        const { key, value } = options;
        state.version.config.prebidServer.defaultVendor[key] = value;
    },
    SET_PREBID_SERVER_BIDDER(state, biddersList) {
        state.version.config.prebidServer.bidders = biddersList;
    },

    // PREBID BID ADJUSTMENT
    UPDATE_PREBID_BID_ADJUSTMENT(state, adjustmentConfig) {
        const { bidder, adjustment } = adjustmentConfig;
        const existingBidder = state.version.config.prebidBidAdjustments.find(
            b => b.bidder === bidder
        );

        if (existingBidder) {
            existingBidder.adjustment = adjustment;
        } else {
            state.version.config.prebidBidAdjustments.push({ bidder, adjustment });
        }
    },
    SYNC_PREBID_BID_ADJUSTMENTS(state) {
        const availableBidders = state.version.config.prebid.groups
            .map(group => group.bidders)
            .flat();
        let activeBidders = availableBidders
            .filter(bidder => bidder.active)
            .map(bidder => bidder.bidder);
        activeBidders = activeBidders.filter(bidder => bidder !== 'optimera');
        const biddersSet = [...new Set(activeBidders)];

        state.version.config.prebidBidAdjustments =
      state.version.config.prebidBidAdjustments.filter(
          adjustment =>
              biddersSet.includes(adjustment.bidder) &&
          adjustment.adjustment &&
          adjustment.adjustment.trim() !== ''
      );
        validationHelpers.validatePrebidBidAdjustments(state.version.config);
    },

    // PREBID FLOORS
    UPDATE_PREBID_FLOORS_FLOORREPORTING_FIELDS(state, { key, value }) {
        state.version.config.prebidFloors.floorReporting[key] = value;
    },

    UPDATE_PREBID_FLOORS_SKIPRATE_FIELDS(state, { key, value }) {
        state.version.config.prebidFloors.skipRate[key] = value;
    },

    ADD_PREBID_FLOORS_FIELD(state, field) {
        state.version.config.prebidFloors.fields.push(field);
    },
    SET_PREBID_FLOORS_FIELDS(state, fields) {
        state.version.config.prebidFloors.fields = fields;
    },

    COPY_PREBID_FLOORS_RULE(state, uuid) {
        const existingRule = state.version.config.prebidFloors.rules
            .find(r => r.uuid === uuid);
        const copy = helpers.deepCopy(existingRule);
        copy.uuid = uuidv4();
        state.version.config.prebidFloors.rules.push(copy);
    },
    DELETE_PREBID_FLOORS_RULE(state, uuid) {
        state.version.config.prebidFloors.rules = state.version.config.prebidFloors.rules
            .filter(r => r.uuid !== uuid);
    },
    ADD_PREBID_FLOORS_RULE(state, rule) {
        rule.uuid = uuidv4();
        state.version.config.prebidFloors.rules.push(rule);
    },
    UPDATE_PREBID_FLOORS_RULE(state, config) {
        const { uuid, key, value } = config;
        const existingRule = state.version.config.prebidFloors.rules
            .find(r => r.uuid === uuid);

        if (key === 'cpm') {
            existingRule.cpm = value;
        } else {
            existingRule.values[key] = value;
        }
    },

    UPDATE_PREBID_FLOORS_CUSTOM(state, value) {
        state.version.config.prebidFloors.custom = value;
    },

    // PREBID FIRST LOOK
    UPDATE_PREBID_FIRST_LOOK(state, options) {
        const { key, value } = options;
        state.version.config.prebidFirstLook[key] = value;
    },
    SYNC_PREBID_FIRST_LOOK_BIDDERS(state) {
        const availableBidders = state.version.config.prebid.groups
            .map(group => group.bidders)
            .flat();
        const activeBidders = availableBidders
            .filter(bidder => bidder.active)
            .map(bidder => bidder.bidder);
        const biddersSet = [...new Set(activeBidders)];
        state.version.config.prebidFirstLook.bidders =
      state.version.config.prebidFirstLook.bidders.filter(b =>
          biddersSet.includes(b)
      );
    },

    // BIDDER THROTTLING
    UPDATE_BIDDER_THROTTLING(state, options) {
        const { key, value } = options;
        state.version.config.bidderThrottling[key] = value;
    },

    // TRAFFIC SHAPING
    UPDATE_TRAFFIC_SHAPING(state, config) {
        const { key, value } = config;
        state.version.config.trafficShaping[key] = value;
    },

    ADD_TRAFFIC_SHAPING_RULE(state, rule) {
        rule.uuid = uuidv4();
        state.version.config.trafficShaping.rules.push(rule);
    },
    COPY_TRAFFIC_SHAPING_RULE(state, uuid) {
        const existingRule = state.version.config.trafficShaping.rules
            .find(r => r.uuid === uuid);
        const copy = helpers.deepCopy(existingRule);
        copy.uuid = uuidv4();
        state.version.config.trafficShaping.rules.push(copy);
    },
    DELETE_TRAFFIC_SHAPING_RULE(state, uuid) {
        state.version.config.trafficShaping.rules = state.version.config.trafficShaping.rules
            .filter(r => r.uuid !== uuid);
    },
    UPDATE_TRAFFIC_SHAPING_RULE(state, config) {
        const { uuid, key, value } = config;
        const existingRule = state.version.config.trafficShaping.rules
            .find(r => r.uuid === uuid);

        existingRule[key] = value;
    },
    SYNC_TRAFFIC_SHAPING(state) {
        const activeBidders = new Set(state.version.config.prebid.groups
            .flatMap(({ bidders }) => bidders)
            .filter(({ active }) => active)
            .map(({ bidder }) => bidder));

        const {trafficShaping} = state.version.config;

        // if any bidder is unknown, remove that row because
        // the bidder was deleted
        const keptRules = trafficShaping.rules.filter(rule => {
            return activeBidders.has(rule.bidder);
        });

        state.version.config.trafficShaping.rules = keptRules;
    },
};

const mutations = {
    UPDATE_AB_TEST_VERSION: (state, testVersionOptions) => {
        const { index, key, value } = testVersionOptions;
        state.version.config.abTesting.testVersions[index][key] = value;
    },
    ADD_TEST_VERSION(state) {
        state.version.config.abTesting.testVersions.push({
            allocation: 0,
            versionId: '',
        });
    },
    REMOVE_TEST_VERSION(state, testVersionIndex) {
        state.version.config.abTesting.testVersions.splice(testVersionIndex, 1);
    },
};

const actions = {
    updateAbTestVersion({ commit }, testVersionOptions) {
        commit('UPDATE_AB_TEST_VERSION', testVersionOptions);
    },
    addTestVersion({ commit }) {
        commit('ADD_TEST_VERSION');
    },
    removeTestVersion({ commit }, testVersionIndex) {
        commit('REMOVE_TEST_VERSION', testVersionIndex);
    },
};

const getters = {
    abTesting: ({ version }) => version.config.abTesting || {},
    testableVersions: ({ testableVersions }) => testableVersions || [],
};

export default {
    mutations,
    actions,
    getters,
};

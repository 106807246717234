const mutations = {
    UPDATE_VERSION_INTENT_IQ(state, intentIqOptions) {
        const { key, value } = intentIqOptions;
        state.version.config.intentIq[key] = value;
    },
};

const actions = {
    updateVersionIntentIq({ commit }, intentIqOptions) {
        commit('UPDATE_VERSION_INTENT_IQ', intentIqOptions);
    },
};

const getters = {
    intentIq: ({ version }) => version.config.intentIq || {},
};

export default {
    mutations,
    actions,
    getters,
};

import {
    getVersion,
    updateVersion,
    updateVersionNote,
    createVersionDeployment,
} from '@api/versions';
import { saveAndBuildSiteVersion, fetchSiteTestVersions } from '@api/sites';
import { fetchGitTags } from '@api/gitTags';
import {
    mapVersion,
    formatForBuild,
    formatForSave,
    mapTestVersions,
} from '@util/sites';
import router from '@router/router';

/* SUB MODULES */
// The sub modules imported share the same state as version

import setupOptions from './setupOptions';
import accountInformation from './accountInformation';
import analytics from './analytics';
import identity from './identity';
import consentMgmt from './consentMgmt';
import headerBidding from './headerBidding';
import abTesting from './abTesting';
import adConfiguration from './adConfiguration';
import validation from './validation';
import validationHelpers from '@util/versionValidationHelpers';
import adsTxt from './adsTxt';
import vendors from '@components/sites/vendors';
/* ---------------------- */

const state = {
    adsTxt: {},
    adsTxtEntry: {},
    adsTxtHistory: [],
    version: {},
    isVersionEditable: false,
    arePriceBucketsValid: false,
    testableVersions: [],
    gitTags: [],
    prebidGroups: [],
    ...validation.state,
    invalidPassbacks: [],
    saveAndBuildClicked: false,
};

const mutations = {
    SET_SITE_VERSION(state, siteVersion) {
        if (!siteVersion.config.styles) {
            siteVersion.config.styles = {
                styles: [],
            };
        }
        if (!siteVersion.config.schains) {
            siteVersion.config.schains = [];
        }
        state.version = siteVersion;
    },
    UPDATE_VERSION(state, versionOptions) {
        const { key, value } = versionOptions;
        state.version[key] = value;
    },
    UPDATE_VERSION_NOTE(state, versionOptions) {
        state.version.note = versionOptions.note;
    },
    UPDATE_VERSION_CONFIG(state, versionOptions) {
        const { key, value } = versionOptions;
        state.version.config[key] = value;
    },
    UPDATE_PUBLISHER_API(state, options) {
        state.version.config.api[options.key] = Boolean(options.value);
    },
    SET_IS_VERSION_EDITABLE(state, isEditable) {
        state.isVersionEditable = isEditable;
    },
    SET_ARE_PRICE_BUCKETS_VALID(state, bool) {
        state.arePriceBucketsValid = bool;
    },
    CLEAR_VERSION_STATE(state) {
        state.version = {};
    },
    SET_TESTABLE_VERSIONS: (state, testableVersions) => {
        state.testableVersions = testableVersions;
    },
    SET_GIT_TAGS: (state, gitTags) => {
        state.gitTags = gitTags;
    },
    SET_SAVE_AND_BUILD_CLICKED: (state, bool) => {
        state.saveAndBuildClicked = bool;
    },

    /* SUB MODULES */
    ...setupOptions.mutations,
    ...accountInformation.mutations,
    ...analytics.mutations,
    ...identity.mutations,
    ...consentMgmt.mutations,
    ...headerBidding.mutations,
    ...abTesting.mutations,
    ...adConfiguration.mutations,
    ...validation.mutations,
    ...adsTxt.mutations,
    ...vendors.mutations,
};

const actions = {
    async getSiteVersion({ commit, dispatch, rootState }, { siteId, versionId }) {
        try {
            const { site, version } = await getVersion(versionId);
            const mappedVersion = mapVersion(
                version,
                site,
                rootState.general.bidders
            );

            if (site.site_id.toString() !== siteId.toString()) {
                throw { code: 'NOT_MATCHING_SITE' };
            }

            commit('SET_SITE', site);
            commit('SET_SITE_VERSION', mappedVersion);
            const isVersionEditable = site.version <= version.version;
            commit('SET_IS_VERSION_EDITABLE', isVersionEditable);
            if (!isVersionEditable) {
                dispatch(
                    'setAlertMessage',
                    {
                        message: `READ ONLY`,
                        description: `This revision is not editable, only the latest revision can be modified. To edit this revision, create a copy in the version control screen.`,
                        keepMessage: true,
                        type: 'alert',
                    },
                    { root: true }
                );
            } else {
                validationHelpers.validateVersionWarnings(version); // validate only latest versions
                validationHelpers.checkIdentityVersions(version.config);
                validationHelpers.validateAccountOwnership(version.config);
            }
        } catch (e) {
            console.log('e', e);
            throw e;
        }
    },
    async getTestableVersions({ commit, getters }) {
        const { testableVersions } = await fetchSiteTestVersions(
            getters.site.site_id,
            'success'
        );
        const mappedtestVersions = mapTestVersions(testableVersions);
        commit('SET_TESTABLE_VERSIONS', mappedtestVersions);
    },
    async getGitTags({ commit }) {
        const { tags } = await fetchGitTags();
        commit('SET_GIT_TAGS', tags);
    },
    async updateSiteVersionConfig(_, { siteId, versionId, body }) {
        try {
            body.config = formatForSave(body.config);
            await updateVersion(versionId, body);
            router.push({
                name: 'site-overview',
                params: { siteId },
                query: { tab: 'revisions' },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    async saveNewSiteVersionConfig(_, { site, wip, version }) {
        const formattedVersion = formatForBuild(site, version);

        const body = {
            isProd: formattedVersion.is_prod,
            isStage: formattedVersion.is_stage,
            config: formattedVersion.config,
            note: formattedVersion.note,
            testParent: formattedVersion.is_abtest_parent,
            testAllowed: formattedVersion.is_abtest_allowed,
            wip,
        };

        if (!wip) {
            body.versionId = version.version_id;
        }

        try {
            await saveAndBuildSiteVersion(site.site_id, body);
            router.push({
                name: 'site-overview',
                params: { siteId: site.site_id },
                query: { tab: 'revisions' },
            });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    updateVersionConfig({ commit }, versionOptions) {
        commit('UPDATE_VERSION_CONFIG', versionOptions);
        if (versionOptions.key === 'htlbidGlobal') {
            commit('REFRESH_UNIVERSAL_LAYOUT_SLOTS');
        }
    },
    updatePublisherApi({ commit }, options) {
        commit('UPDATE_PUBLISHER_API', options);
    },
    setArePriceBucketsValid({ commit }, bool) {
        commit('SET_ARE_PRICE_BUCKETS_VALID', bool);
    },
    updateVersion({ commit }, versionOptions) {
        commit('UPDATE_VERSION', versionOptions);
    },

    async updateVersionNote(_, versionOptions) {
        try {
            const { versionId, note, status } = versionOptions;
            await updateVersionNote(versionId, note, status)
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    async deployVersion(_, { versionId, body }) {
        try {
            await createVersionDeployment(versionId, body);
        } catch (e) {
            console.log('e', e);
        }
    },

    clearVersionState({ commit }) {
        commit('CLEAR_VERSION_STATE');
    },

    setSaveAndBuildClicked({ commit }, bool) {
        commit('SET_SAVE_AND_BUILD_CLICKED', bool);
    },

    /* SUB MODULES */
    ...setupOptions.actions,
    ...accountInformation.actions,
    ...analytics.actions,
    ...identity.actions,
    ...consentMgmt.actions,
    ...headerBidding.actions,
    ...abTesting.actions,
    ...adConfiguration.actions,
    ...validation.actions,
    ...adsTxt.actions,
    ...vendors.actions,
};

const getters = {
    version: ({ version }) => version,
    config: ({ version }) => version.config || {},
    arePriceBucketsValid: ({ arePriceBucketsValid }) => arePriceBucketsValid,
    isVersionEditable: ({ isVersionEditable }) => isVersionEditable,
    gitTags: ({ gitTags }) => gitTags,
    saveAndBuildClicked: ({ saveAndBuildClicked }) => saveAndBuildClicked,

    /* SUB MODULES */
    ...setupOptions.getters,
    ...accountInformation.getters,
    ...analytics.getters,
    ...identity.getters,
    ...consentMgmt.getters,
    ...headerBidding.getters,
    ...abTesting.getters,
    ...adConfiguration.getters,
    ...validation.getters,
    ...adsTxt.getters,
    ...vendors.getters,
};

export default {
    state,
    mutations,
    actions,
    getters,
};

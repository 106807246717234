const mutations = {
    UPDATE_VERSION_BONBON(state, options) {
        const { key, value } = options;
        state.version.config.bonBon[key] = value;
    },
};

const actions = {
    updateVersionBonBon({ commit }, options) {
        commit('UPDATE_VERSION_BONBON', options);
    },
};

const getters = {
    bonBon: ({ version }) => version.config.bonBon || {},
};

export default {
    mutations,
    actions,
    getters,
};
